import React from 'react'
import PageContainer from '../../components/layout/PagecContainer'
import "../../styles/subjects.css"
import HeaderPrograms from '../../components/Programs/HeaderPrograms'
import { useSelector } from 'react-redux'
import Chat from '../../components-old/chat'
const ProgramsPage = () => {
    const packages = useSelector((state) => state.packages);
    const {lang,dir} = useSelector(state=>state?.language)
  
    return (
        <div dir={dir} className='font'>
            <PageContainer>
            <Chat/>
                <main id="Subjects" style={{ marginTop: "100px" }}>
                    <HeaderPrograms packages={packages} />
                </main>
            </PageContainer>
        </div>
    )
}

export default ProgramsPage
