import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Send_New_Message } from '../../../actions/massenger';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { getUsersByIDS } from '../../../utils/API';
import { MdMessage } from "react-icons/md";

function SendGroupMessage({ acceptorsIDS}) {
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const text = useRef(null);
  const user = useSelector(state => state.loggedUser.user);
  const dispatch = useDispatch();

  const sendMessageToGroup = async () => {
    try {
      setLoading(true);
      const ownerID = user?._id;
      const ownerRole = user?.role;
      const res = await getUsersByIDS({IDS:acceptorsIDS});
      const users = res?.data?.users
      const sendPromise = Promise.all(
        users.map(async u => {
          const contactID = u._id;
          const contactRole = u.role;
          await dispatch(Send_New_Message({ ownerID, ownerRole, contactID, contactRole, message: text.current?.value }));
        })
      );
      await sendPromise;
      // console.log('sent')
      setMessage({ case: true, message: 'تم الارسال بنجاح' });
      setLoading(false);
      // setPopupOpen(false); // Close the popup after sending message
    } catch (err) {
      console.warn(err);
      setMessage({ case: false, message: err.message });
      setLoading(false);
    }
  };

  return (
    <div>
      <p className='contact-btn' onClick={() => setPopupOpen(true)}>
      <MdMessage
        onClick={() => setPopupOpen(true)}
        size={40}
        className='contact-btn-icon'
      />
      </p>
      
      <Modal show={popupOpen} onHide={() => setPopupOpen(false)} className='font' centered>
        <Modal.Header>
          <Modal.Title>ارسال رسالة الى اعضاء الفصل</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="messageTextarea">
            <Form.Label>الرسالة</Form.Label>
            <Form.Control as="textarea" ref={text} style={{resize:"none"}}/>
          </Form.Group>
          {message && (
            <Alert variant={message.case ? 'success' : 'danger'}>
              {message.message}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className='btn special color' onClick={() => setPopupOpen(false)}>اغلاق</button>
          <button className='btn btnGroub' onClick={sendMessageToGroup} disabled={loading}>
            {loading ? 'Sending...' : 'Send Message'}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SendGroupMessage;
