import { CREATE_LEVELE, DELETE_LEVEL, INETIALIZE_LEVELS, EDIT_LEVEL } from "../actions/curriculums/levels";
import { ADD_BRANCH, DELETE_BRANCH, EDIT_BRANCH, INETIALIZE_BRANCHS } from '../actions/curriculums/branchs'

export const levels = (state = [], action) => {
    switch (action.type) {
        case CREATE_LEVELE:
            return state = [...state, action.level]
        case INETIALIZE_LEVELS:
            return state = action.levels
        case DELETE_LEVEL:
            return state = state.filter(l => l._id !== action.id?._id)
        case EDIT_LEVEL:
            return state = state.map(s => {
                if (s._id === action.level._id) {
                    return action.level
                }
                return s
            })
        default: return state
    }
}

export const branchs = (state = [], action) => {
    switch (action.type) {
        case ADD_BRANCH:
            return state = [...state, action.branch]
        case INETIALIZE_BRANCHS:
            return state = action.branchs
        case DELETE_BRANCH:
            console.log(action)
            return state = state.filter(l => l._id !== action.id?._id)
        case EDIT_BRANCH:
            return state = state.map(s => {
                if (s._id === action.branch._id) {
                    return action.branch
                }
                return s
            })
        default: return state
    }
}
