import React, { useContext } from 'react'
import SharedStatic from './modules/SharedStatic'
import PageContainer from '../layout/PagecContainer'
import SharedImg from '../shared/modules/SharedImg'
import about from "../../assets/aboutUs.png"
import { useSelector } from 'react-redux'
import seshatone from "../../assets/seshatone.png"
import seshattwo from "../../assets/seshattwo.png"
import seshatthree from "../../assets/seshatthree.png"
import { ThemeContext } from '../../ThemeProvider';
import { HomePage } from '../../utils/Translation/HomePage'
const WhoSeshat = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const { theme } = useContext(ThemeContext);
    return (
        <div>
            <div className='font'>
                <PageContainer>
                    <main id="contact-us">
                        <SharedImg img={about} />
                        <div className='container' dir={dir}>
                            <div className='infoContact w-100 mx-3'>
                                <div className='' style={{ width: "380px" }}>من هي سيشات</div>
                            </div>
                            <p className={`sharedColor mx-4  ${theme === 'light' ? 'infofordetails' : "text-light"} `}>
                                <p className={` fs-5   ${theme === 'light' ? 'infofordetails' : "text-light"} `}>

                                    {HomePage.seshatAt[lang]}
                                    <br />
                                    {HomePage.ramz[lang]}
                                    <br />
                                    {HomePage.tora[lang]}
                                    <br />
                                    {HomePage.polymr[lang]}
                                    <br />
                                    {HomePage.ses1[lang]}
                                    <br />
                                    {HomePage.ses2[lang]}
                                    <br />
                                    {HomePage.ses3[lang]}
                                    <br />
                                    {HomePage.ses4[lang]}
                                    <br />
                                    {HomePage.ses6[lang]}
                                    <br />
                                    {HomePage.ses7[lang]}
                                    <br />
                                    {HomePage.ses8[lang]}
                                    <br />
                                    {HomePage.ses9[lang]}
                                    <br />

                                    {HomePage.ses10[lang]}
                                    <br />
                                    {HomePage.ses11[lang]}
                                    <br />
                                    {HomePage.ses12[lang]}
                                    <br />
                                    {HomePage.ses13[lang]}
                                    <br />
                                    {HomePage.ses14[lang]}
                                    <br />
                                    <br />

                                    {HomePage.timeLine[lang]}
                                    <br />
                                    {HomePage.contentTimeLine[lang]}
                                    <br />

                                    {HomePage.ses15[lang]}
                                    <br />
                                    {HomePage.ses16[lang]}
                                    <br />
                                    {HomePage.ses17[lang]}
                                    <br />
                                    {HomePage.ses18[lang]}
                                    <br />
                                    {HomePage.ses19[lang]}
                                    <br />
                                    <br />
                                    {HomePage.sec20[lang]}
                                    <br />

                                    <br />

                                    {HomePage.ses22[lang]}
                                    <br />
                                    {HomePage.ses24[lang]}
                                    <br />
                                    {HomePage.ses25[lang]}
                                    <br />
                                    {HomePage.ses26[lang]}
                                    <br />
                                    {HomePage.ses27[lang]}
                                    <br />
                                    {HomePage.ses28[lang]}
                                    <br />
                                    {HomePage.ses29[lang]}
                                    <br />
                                    {HomePage.ses30[lang]}
                                    <br />
                                    {HomePage.sec31[lang]}
                                    <br />
                                    {HomePage.ses32[lang]}
                                    <br />
                                    {HomePage.ses33[lang]}
                                    <br />
                                    {HomePage.ses35[lang]}
                                    <br />
                                    {HomePage.ses34[lang]}
                                    <br />
                                    {HomePage.ses36[lang]}
                                    <br />
                                    {HomePage.ses37[lang]}
                                    <br />
                                    {HomePage.ses38[lang]}
                                    <br />
                                    {HomePage.ses39[lang]}
                                    <br />
                                    {HomePage.ses40[lang]}

                                    {HomePage.ses41[lang]}
                                    <br />


                                    {HomePage.ses42[lang]}
                                    <br />

                                    {HomePage.ses43[lang]}
                                    <br />

                                    {HomePage.ses44[lang]}
                                    <br />

                                    {HomePage.ses45[lang]}
                                    <br />

                                    {HomePage.ses46[lang]}

                                    <br />

                                    {HomePage.ses47[lang]}
                                    <br />
                                    <a href="https://www.ancient.eu/Seshat/">https://www.ancient.eu/Seshat/</a>
                                    <br />
                                    <a href="https://www.britishmuseum.org/">https://www.britishmuseum.org/</a>
                                    <br />
                                    <a href=" https://scholar.google.com/scholar?hl=en&as_sdt=0%2C5&q=seshat&btnG="> https://scholar.google.com/scholar?hl=en&as_sdt=0%2C5&q=seshat&btnG=</a>
                                    <br />
                                    {HomePage.ses48[lang]}

                                    <div><img src={seshatone} alt="data" className='w-25 mt-5' style={{ height: "350px" }} /></div>
                                    <div>  <img src={seshattwo} alt="data" className='w-25 mt-5' style={{ height: "350px" }} /></div>
                                    <div><img src={seshatthree} alt="data" className='w-25 mt-5' style={{ height: "350px" }} /></div>

                                </p>

                            </p>
                        </div>


                        <SharedStatic hint="about" />

                    </main>
                </PageContainer>
            </div>
        </div>
    )
}

export default WhoSeshat
