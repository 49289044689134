import React, { useContext } from 'react'
import { HomePage } from '../../utils/Translation/HomePage'
import { useSelector } from 'react-redux';
import { ThemeContext } from '../../ThemeProvider';

const EductionServices = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const { theme } = useContext(ThemeContext);
    return (
        <div style={{ margin: "120px auto", textAlign: "center", width: "95%" }}>
            <h4 style={{ color: theme === 'light' ? '#2B6CCD' : 'white' }}>{HomePage.eductionService[lang]}</h4>
            <p className="infoHeader" style={{ color: theme === 'light' ? '#2B6CCD' : 'white' }}>{HomePage.eductionServiceContent[lang]}</p>
            <div className="container">
                <div className='row justify-content-around align-items-center m-auto' >
                    <div className='col-lg-3 col-md-4 col-sm-6 col-6'>
                        <div className='cardEductionService'>
                            <p>
                                {HomePage.schoolsAcademy[lang]}
                            </p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-6'>
                        <div className='cardEductionService'>
                            <p>
                                {HomePage.TeacherTraining[lang]}
                            </p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-6'>
                        <div className='cardEductionService'>
                            <p>{HomePage.InternationalEducation[lang]}</p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-6'>
                        <div className='cardEductionService'>
                            <p>{HomePage.GlobalCompanies[lang]}</p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-6'>
                        <div className='cardEductionService'>
                            <p>E learning</p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-6'>
                        <div className='cardEductionService'>
                            <p>Home Learning</p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-6'>
                        <div className='cardEductionService'>
                            <p>Smart Bulding</p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-6'>
                        <div className='cardEductionService'>
                            <p>Interaction Furniture</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EductionServices

// import React, { useContext } from 'react'
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
// import "../../styles/teach.css"
// import { ThemeContext } from '../../ThemeProvider';
// import TeacherCard from '../shared/modules/TeacherCard';
// import { useSelector } from 'react-redux';
// import { HomePage } from '../../utils/Translation/HomePage';
// const EductionServices = () => {
//     const teachers = useSelector(state => state.users.teachers);
//     const { theme } = useContext(ThemeContext);
//     const { lang, dir } = useSelector(({ language }) => {
//         return { lang: language.lang, dir: language.dir };
//     });
//     const settings = {
//         dots: true,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 3,
//         slidesToScroll: 1,
//         responsive: [
//             {
//                 breakpoint: 1024, // العرض بالبيكسل
//                 settings: {
//                     slidesToShow: 2,
//                     slidesToScroll: 1,
//                     infinite: true,
//                     dots: true
//                 }
//             },
//             {
//                 breakpoint: 600,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                     initialSlide: 1,
//                 }
//             },
//             {
//                 breakpoint: 480,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1
//                 }
//             }
//         ]
//     };

//     return (
//         <div style={{ margin: "120px auto", textAlign: "center", width: "90%", padding: "20px" }}>
//               <h4 style={{ color: theme === 'light' ? '#2B6CCD' : 'white' }}>{HomePage.eductionService[lang]}</h4>
// <p className="infoHeader" style={{ color: theme === 'light' ? '#2B6CCD' : 'white' }}>{HomePage.eductionServiceContent[lang]}</p>
//             <div className='container programsCards'>

//                 <Slider {...settings}>


//                     <div className='col-lg-3 col-sm-6 col-md-4'>
//                         <div className='cardEductionService'>
//                             <p>
//                                 {HomePage.schoolsAcademy[lang]}
//                             </p>
//                         </div>
//                     </div>
//                     <div className='col-lg-3 col-sm-6 col-md-4'>
//                         <div className='cardEductionService'>
//                             <p>
//                                 {HomePage.schoolsAcademy[lang]}
//                             </p>
//                         </div>
//                     </div>
//                     <div className='col-lg-3 col-sm-6 col-md-4'>
//                         <div className='cardEductionService'>
//                             <p>
//                                 {HomePage.schoolsAcademy[lang]}
//                             </p>
//                         </div>
//                     </div>
//                     <div className='col-lg-3 col-sm-6 col-md-4'>
//                         <div className='cardEductionService'>
//                             <p>
//                                 {HomePage.schoolsAcademy[lang]}
//                             </p>
//                         </div>
//                     </div>
//                     <div className='col-lg-3 col-sm-6 col-md-4'>
//                         <div className='cardEductionService'>
//                             <p>
//                                 {HomePage.schoolsAcademy[lang]}
//                             </p>
//                         </div>
//                     </div>
//                     <div className='col-lg-3 col-sm-6 col-md-4'>
//                         <div className='cardEductionService'>
//                             <p>
//                                 {HomePage.schoolsAcademy[lang]}
//                             </p>
//                         </div>
//                     </div>
//                     <div className='col-lg-3 col-sm-6 col-md-4'>
//                         <div className='cardEductionService'>
//                             <p>
//                                 {HomePage.schoolsAcademy[lang]}
//                             </p>
//                         </div>
//                     </div>

//                 </Slider>
//             </div>
//         </div>
//     )
// }

// export default EductionServices
