import React from 'react'
import "../../../styles/contact.css"
import image from "../../../assets/images/mobile.png"
const SharedImg = ({ img, title, line }) => {
    return (
        <div>
            <div className='sharedImage bg-primary'>
                <div className='contact'>
                    {/* <img src={""} alt="data" className='responsive-image' /> */}
                </div>
            </div>
        </div>
    )
}

export default SharedImg
