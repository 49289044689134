import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Edit from '../../../components-old/curriculums/packages/edit';
import { Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { FcDeleteDatabase } from "react-icons/fc";
import AddCode from '../../../components-old/curriculums/packages/AddCode';
import GetCodes from '../../../components-old/curriculums/packages/GetCodes';
import { Delete_Package } from '../../../actions/packages';
const ProgramsTeacher = () => {
    const { packages } = useSelector(({ packages }) => ({ packages }));
    const params = useParams();
    const filteredPackages = packages?.filter((item) => item?.teacher?._id === params?.id);
    const dispatch = useDispatch();

    const onDelete = async (id) => {
        try {
            await dispatch(Delete_Package({ id }));
        } catch (err) {
            console.warn(err.message);
        }
    };

    console.log(filteredPackages)
    return (
        <div style={{ height: "100vh" , width:"99%" }} className="container">
            <Table  responsive>
                <thead>
                    <tr>
                        <th>الاسم</th>
                        <th>السعر</th>
                        <th>السعر بعد الخصم</th>
                        <th>تعديل/عرض</th>
                        <th>اضافة الاكواد</th>
                        <th>عرض الاكواد</th>
                        <th>حذف</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredPackages.map((pack) => (
                        <tr key={pack._id}>
                            <td>{pack?.name}</td>
                            <td>{pack?.price}</td>
                            <td>{pack?.discountPrice}</td>
                            <td>
                                <Edit pack={pack} />
                            </td>
                            <td>
                                <DropdownButton id="dropdown-basic-button" title="المواد">
                                    {pack?.materials?.map((material) => (
                                        <Dropdown.Item key={material?.materialCode}>
                                            <AddCode material={material} packages={pack} />
                                        </Dropdown.Item>
                                    ))}
                                </DropdownButton>
                            </td>
                            <td>
                                <DropdownButton id="dropdown-basic-button" title="المواد">
                                    {pack.materials?.map((material) => (
                                        <Dropdown.Item key={material?.materialCode}>
                                            <GetCodes material={material} packages={pack} />
                                        </Dropdown.Item>
                                    ))}
                                </DropdownButton>
                            </td>
                            <td>
                                <FcDeleteDatabase size={35} onClick={() => onDelete(pack._id)} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default ProgramsTeacher
