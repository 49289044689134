import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createCodeSheet } from '../../../utils/codes';
import { getMaterialByCode } from '../../../utils/materials';
import { useSelector } from 'react-redux';
const AddCode = ({ material, packages }) => {
    const [startDate, setStartDate] = useState(null);
    const [show, setShow] = useState(false);
    const [tries, setTries] = useState(3);
    const [materialName, setMaterialName] = useState("");
    const [codesPerLesson, setCodesNumPerLesson] = useState(50);
    const [codePrice, setCodePrice] = useState(null)
    const [loading, setLoading] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => { setShow(true); }
    const { toast } = useSelector(state => state?.notify.toast)

    const fetchAddCode = async () => {
        try {
            if (!codePrice) {
                return toast().error('السعر مطلوب !')
            }
            setLoading(true);
            const res = await createCodeSheet({
                materialCode: material.materialCode,
                packID: packages._id,
                lessonsIDS: material.selectedLessons,
                expir: startDate,
                tries: tries,
                codesPerLesson,
                codePrice
            });

            if (res?.data?.case) {
                toast.success(res?.data?.message, {
                    position: "top-center"
                });
                handleClose();
            }

        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            setStartDate(null);
            setCodePrice(0);
            setTries(3);
            setCodesNumPerLesson(50);
            setShow(false);
        }
    }

    const fetchMaterialName = async () => {
        setLoading(true);
        try {
            const res = await getMaterialByCode({
                code: material.materialCode
            });
            if (res?.data?.case) {
                setMaterialName(res?.data?.material?.name);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);

        }
    }

    useEffect(() => {
        fetchMaterialName();
    }, []);

    return (
        <>
            <p onClick={handleShow}>
                {loading ? "Loading..." : materialName}
            </p>

            <Modal dir='rtl' show={show} onHide={handleClose} centered className='font' style={{color:"black"}}>
                <Modal.Header >
                    <Modal.Title>اضافة كود </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!loading ?
                        <>
                            <div className='mt-4'>
                                <label>اختر عدد الأكواد المطلوبة لكل درس</label>
                                <br /> <span>عدد الاكواد فى حالة عدم ادخال قيمة 50*</span>
                                <input
                                    type="number"
                                    value={codesPerLesson}
                                    className='w-100 form-control mt-2'
                                    onChange={(e) => setCodesNumPerLesson(e.target.value)}
                                />
                            </div>
                            <div className=''>
                                <label>اختر تاريخ انتهاء الكود</label>
                                <br />
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    className="form-control mt-2"
                                />
                            </div>
                            <div className='mt-4'>
                                <label>اختر عدد مرات الاستخدام</label>
                                <input
                                    type="number"
                                    value={tries}
                                    className='w-100 form-control mt-2'
                                    onChange={(e) => setTries(e.target.value)}
                                />
                            </div>
                            <div className='mt-4'>
                                <label>سعر الكود الواحد للطالب</label>
                                <input
                                    type="number"
                                    value={codePrice}
                                    className='w-100 form-control mt-2'
                                    onChange={(e) => setCodePrice(e.target.value)}
                                />
                            </div>
                        </>
                        :
                        <div className="loaderIcon">
                            <span className="loader"></span>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <butto className="btn btnGroub" onClick={handleClose}>
                        تراجع
                    </butto>
                    <button className='btn special color' onClick={fetchAddCode}>
                        ارسال الاكواد
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddCode;
