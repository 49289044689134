import React, { useContext } from 'react'
import { FaFacebookF } from "react-icons/fa";
import google from "../../assets/google.png";
import { ThemeContext } from '../../ThemeProvider';
import { HomePage } from '../../utils/Translation/HomePage';
import { useSelector } from 'react-redux';
import { urlBase } from '../../utils/API';

const AuthGoogle = () => {
    const { theme } = useContext(ThemeContext);

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const handleAuthGoogle = async()=>{
        window.location.href=`${urlBase}/api/auth/google-student`
    }

    return (
        <div>
            <div>
                <div className="links font shadow" style={{cursor:"pointer"}} onClick={handleAuthGoogle}>
                    <img src={google} alt="google" className="me-2" />
                    <span style={{ color: `${theme === "light" ? "black" : "black"}` }}>{HomePage.google[lang]}</span>
                </div>
                <div className="links font shadow" style={{cursor:"pointer"}}>
                    <FaFacebookF color="#3B5999" className="me-2" />
                    <span style={{ color: `${theme === "light" ? "black" : "black"}` }}>{HomePage.facebook[lang]}</span>
                </div>
            </div>
        </div>
    )
}

export default AuthGoogle
