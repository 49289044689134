import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Question from './question';
import { createTest } from '../../../utils/tests';
import { ErrorMsg, success } from '../../../components/shared/modules/Notifications';
import ShowQuestions from './ShowQuestions';
// import Question from '../../../components/Dashboard/studyArea/tests/question';

const ShowTestAi = () => {
    const location = useLocation();
    console.log(location)
    const exam = location.state?.examData
    ;

    console.log(exam)
    const { testFor } = useSelector(({ view }) => {
        return { testFor: view?.testFor };
      });
    const dispatch = useDispatch();
    const [Answers, setAnswers] = useState([]);
    const [message, setMessage] = useState(null);
  const [testName, setTestName] = useState('');
  const [totalDegree, setTotalDegree] = useState(0);
  const [questions, setQuestions] = useState(exam?.questions);
  const [alertVariant, setAlertVariant] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

    console.log(exam);

    const submitTest = async () => {
        setIsLoading(true);
        try {
            const testData = {
            test: {
                name: exam?.name,
                tot_degree: exam?.tot_degree,
                questions: exam?.questions,
            },
            testFor,
            };
            console.log(testData)
            const res = await createTest(testData);
            console.log(res)  
            if(res?.data?.case){
            success(res?.data?.message)
            }else{
                ErrorMsg(res?.data?.message)
            }
            setAlertVariant(res.data.case ? 'success' : 'danger');
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
        };


      
        const handleTestNameChange = (e) => {
          setTestName(e.target.value);
        };
      
        const handleTotalDegreeChange = (e) => {
          setTotalDegree(e.target.value);
        };
      
        const handleQuestionChange = (e, questionIndex) => {
          setQuestions((prevQuestions) => {
            const updatedQuestions = [...prevQuestions];
            updatedQuestions[questionIndex].question = e.target.value;
            return updatedQuestions;
          });
        };
      
        const handleDegreeChange = (e, questionIndex) => {
          setQuestions((prevQuestions) => {
            const updatedQuestions = [...prevQuestions];
            updatedQuestions[questionIndex].degree = e.target.value;
            return updatedQuestions;
          });
        };
      
        const handleChoiceChange = (e, questionIndex, choiceIndex) => {
          setQuestions((prevQuestions) => {
            const updatedQuestions = [...prevQuestions];
            updatedQuestions[questionIndex].choices[choiceIndex].value = e.target.value;
            return updatedQuestions;
          });
        };
      
        const handleRadioChange = (questionIndex, choiceIndex) => {
          setQuestions((prevQuestions) => {
            const updatedQuestions = [...prevQuestions];
            updatedQuestions[questionIndex].choices = updatedQuestions[questionIndex].choices.map(
              (choice, i) => ({ ...choice, correct: i === choiceIndex })
            );
            return updatedQuestions;
          });
        };
      
        const handelAddFile = (questionIndex, file) => {
          const updatedQuestions = questions.map((p, i) => {
            if (i === questionIndex) {
              p.file = file;
              return p;
            }
            return p;
          });
          setQuestions(updatedQuestions);
        };
      
        const updateReason = (questionIndex, reason) => {
          const updatedQuestions = questions.map((p, i) => {
            if (i === questionIndex) {
              p.reason = reason;
              return p;
            }
            return p;
          });
          setQuestions(updatedQuestions);
        };
      
        const handleTrue_FalseAnswer = (questionIndex, ans) => {
          const updatedQuestions = questions.map((p, i) => {
            if (i === questionIndex) {
              p.TrueFalseAnswer = ans;
              return p;
            }
            return p;
          });
          setQuestions(updatedQuestions);
        };
      
        function QuestionByType(t, index) {
          return {
            order: index,
            questionType: { q: t.q, answer: t.answer },
            question: null,
            degree: null,
            file: null,
            reason: null,
            choices: t.answer === 'CHOICE' ? [
              { value: null, correct: false },
              { value: null, correct: false },
              { value: null, correct: false },
              { value: null, correct: false },
            ] : null,
            TrueFalseAnswer: null,
          };
        }
      
        const addQuestion = (t) => {
          setQuestions((prevQuestions) => [
            ...prevQuestions,
            QuestionByType(t, questions.length + 1)
          ]);
        };
      
        const deleteLastQuestion = () => {
          if (questions.length > 1) {
            setQuestions((prevQuestions) => prevQuestions.slice(0, -1));
          }
        };


    return (
        <div style={{background:"white",borderRadius:"5px",width:"95%" , margin:"auto"}} className='shadow p-4'>
            <p className="text-center">عرض الامتحان</p>
            {
                exam?.questions.map((q, index) => {
                    console.log(q);
                    return (
                        // <Question
                        //     key={index} // Use unique key for each question
                        //     question={q}
                        //     handleAnswer={handleAnswer}
                        //     questionIndex={index}
                        // />
                        <ShowQuestions
                        key={index}
                        question={q}
                        questionIndex={index}
                        handleQuestionChange={handleQuestionChange}
                        handleDegreeChange={handleDegreeChange}
                        handleChoiceChange={handleChoiceChange}
                        handleRadioChange={handleRadioChange}
                        handelAddFile={handelAddFile}
                        updateReason={updateReason}
                        handleTrue_FalseAnswer={handleTrue_FalseAnswer}
                        />
                    )
                })
            }
            <div className=' text-center m-auto '>
            <button onClick={submitTest} className='btn' style={{background:"#120C54 " ,color:"white"}} >انشاء الاختبار</button>
            </div>
        </div>
    )
}

export default ShowTestAi