import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

function MenuBar({ setStatus, status, name1, name2 }) {
  return (
    <ButtonGroup style={{ width: '100%', height: '50px' }}>
      <button
        className={status ? 'btn btnGroub' : 'btn special color'}
        onClick={() => setStatus(true)}
        style={{ width: '40%', borderRadius: '15px' }}
      >
        {name1}
      </button>
      <Button
        variant={!status ? 'btn btnGroub' : 'btn special color'}
        onClick={() => setStatus(false)}
        style={{ width: '40%', borderRadius: '15px' }}
      >
        {name2}
      </Button>
    </ButtonGroup>
  );
}

export default MenuBar;
