import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../../../ThemeProvider';
import imgCard from '../../../assets/Image.png'
import BookingModal from '../../modals/BookingModal';
import { Link } from 'react-router-dom';
import { urlBase } from '../../../utils/API';
import SubscribeToTeacher from '../../modals/SubscribeToTeacher';
import { useSelector } from 'react-redux';
import { HomePage } from '../../../utils/Translation/HomePage';
import subscribe from "../../../assets/subscribe.png"
const ProgramCard = ({ item, isSubscribed, targetTeacher }) => {
    const { theme } = useContext(ThemeContext);
    const loggedUser = useSelector((state) => state.loggedUser)

    const [isSub, setIsSub] = useState(isSubscribed)

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });


    useEffect(() => {
        setIsSub(isSubscribed);
    }, [isSubscribed]);

    const initialIsSubscribed = item?.teacher?.subscribers?.some(
        (subscriber) => subscriber?.user === loggedUser?.user?._id
    );


    // console.log(item)


    return (
        <div dir={dir}>
            <div className={`card ${theme}`} style={{ minHeight: "60vh",  overflow: "hidden" }}>
                <div className="text-center m-auto  w-100">
                    {item?.coverImage ? (
                        <img src={item?.coverImage !== null ? `${urlBase}/${item?.coverImage}` : ""} alt="" style={{ width: "100%", height: "250px" }} className='' />
                    ) : (
                        <img src={imgCard} alt="imageData" />
                    )}
                </div>
                <div className="card-content font">
                    <p style={{ color: `${theme === "light" ? "black" : "white"}` , fontWeight:"800" }}>{item.name}</p>

               
                    <div>
                        {item?.description.slice(0,50)}
                    </div>
                    <hr/>
                    <div className=" mt-5">
                        <Link to={`/details/${item.name}/${item._id}`} className="btn buttonOpacity mb-5 w-50" style={{ background: "#2C6DCE", padding: "10px 15px", borderRadius: "20px" ,color:"white"}}>
                            {HomePage.details[lang]}
                        </Link>
                      
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProgramCard
