import React, { useState, useEffect } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import { getLessonsOfSpecificMaterial } from '../../../utils/lessons';
import { Link, useNavigate } from 'react-router-dom';
import { Set_Lesson } from '../../../actions/curriculums/view';
import { useDispatch, useSelector } from 'react-redux';
import AddTestBtn from '../tests/AddTestBtn';
import { MdDeleteForever } from "react-icons/md";
import DeleteLesson from './deleteLesson';
import AiModal from '../../../components/modals/AiModal';
import { HomePage } from '../../../utils/Translation/HomePage';



const ViewLessons = ({ materialCode, materialData }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [lessons, setLessons] = useState([]);
  const [url, setUrl] = useState('');

  useEffect(() => {
    const href = window.location.pathname.split('/');
    href.pop()
    const URL = href.join('/')
    setUrl(URL)
  }, [])
  const dispatch = useDispatch();

  const handleOpenModal = async () => {
    try {
      setLoading(true);
      const lessonsData = await getLessonsOfSpecificMaterial({ code: materialCode });
      setLessons(lessonsData.data.lessons);
      setShowModal(true);
    } catch (error) {
      console.error('Error fetching lessons:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const navigate = useNavigate();

  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });
  
  return (
    <div>
      {/* Button to open the modal */}
      <button className='sharedButton btn' onClick={handleOpenModal}>
      {HomePage.materialLesson[lang]}
      </button>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered className="font">
        <Modal.Header >
          <Modal.Title>{HomePage.materialLesson[lang]}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <p>Loading lessons...</p>
          ) : (
            <Table style={{ textAlign: "center" }}>
              <thead>
                <tr style={{ color: "#120C54", textAlign: "center" }}>
                  <th>Num</th>
                  <th>{HomePage.lessonName[lang]}</th>
                  {/* Add more table headers as needed */}
                  <th>{HomePage.showTest[lang]}</th>
                  <th>{HomePage.addTest[lang]}</th>
                  <th>{HomePage.del[lang]}</th>
                </tr>
              </thead>
              <tbody>
                {lessons.length && lessons.map((lesson, index) => (
                  <tr key={index} style={{ textAlign: "center", color: "#120C54", fontWeight: "500" }}>
                    <td>{index + 1}</td>
                    <td style={{ color: "#120C54" }}>{lesson.name}</td>
                    <td><p style={{ textDecoration: "none", cursor: "pointer" }} onClick={() => {
                      dispatch(Set_Lesson(lesson))

                      navigate(`${url}/view-lesson`, { state: { materialData } })

                    }} >عرض</p></td>

                    <td>
                      <AddTestBtn type={'lesson'} testFor={lesson._id} />
                      <br />
                      <AiModal onOpen={handleCloseModal} type={'lesson'} materialData={materialData} lesson={lesson} />
                    </td>
                    <td> <DeleteLesson lessonId={lesson._id} updateLessons={handleOpenModal} /> </td>
                    {/* Add more table data cells as needed */}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            إغلاق
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewLessons;
