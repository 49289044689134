import { SET_LANG } from "../actions/language";

export const language = (state = { dir: 'rtl', lang: 'AR' }, action) => {
    switch (action.type) {
        case SET_LANG:
            const newLangState = {
                lang: action.lang,
                dir: action.lang === 'AR' ? 'rtl' : 'ltr'
            };
            // حفظ اللغة في localStorage
            localStorage.setItem('appLanguage', JSON.stringify(newLangState));
            return newLangState;
        default:
            // استرجاع اللغة من localStorage إذا كانت موجودة
            const savedLang = JSON.parse(localStorage.getItem('appLanguage'));
            return savedLang ? savedLang : state;
    }
}
