import React, { useContext } from 'react'
import PageContainer from '../layout/PagecContainer'
import SharedImg from '../shared/modules/SharedImg'
import about from "../../assets/aboutUs.png"
import SharedStatic from './modules/SharedStatic'
import { useSelector } from 'react-redux'
import { HomePage } from '../../utils/Translation/HomePage'
import { ThemeContext } from '../../ThemeProvider';
const TechnicalSupport = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const textAlign = lang === 'EN' ? 'left' : 'right';
    const { theme } = useContext(ThemeContext);
    return (
        <div className='font'>
            <PageContainer>
                <main id="contact-us">
                    <SharedImg img={about} />
                    <div className='container' dir={dir}>
                        <div className='infoContact mx-4'>
                            <div className=''>{HomePage.Technical_Support[lang]}</div>
                        </div>
                        <p className={`sharedColor mx-4  ${theme === 'light' ? 'infofordetails' : "text-light"} `}>
                            <div style={{ textAlign }}>
                                <h4> {HomePage.communicateSupport[lang]} </h4>
                                <p>
                                    {HomePage.sendReasons[lang]}
                                </p>
                                <p>{HomePage.address[lang]}</p>
                                <p>
                                    <a href='mailto:info@seshat.world' className={`${theme === 'light' ? 'infofordetails' : "text-light"}`}>info@seshat.world</a>
                                </p>
                            </div>
                            <div style={{ textAlign, marginTop: "50px" }}>
                                <h4>{HomePage.ticketSystem[lang]}</h4>
                                <p>
                                    {HomePage.reach[lang]}
                                </p>
                            </div>
                            <div style={{ textAlign, marginTop: "50px" }}>
                                <h4>{HomePage.phone[lang]}</h4>
                                <p>
                                    {HomePage.provide[lang]}
                                </p>
                                <p>{HomePage.phone[lang]}</p>
                                <a href="tel:+201007476077" className={`${theme === 'light' ? 'infofordetails' : "text-light"}`}

                                >
                                    {dir === "ltr" ? "+20 100 747 6077" : " 6077 747 100 20+"}
                                </a>
                            </div>
                            <div style={{ textAlign, marginTop: "50px" }}>
                                <h4>{HomePage.steps[lang]}</h4>
                                <div>
                                    <h6>{HomePage.selectProplem[lang]}</h6>
                                    <p>{HomePage.selectAllProplems[lang]}</p>
                                </div>
                                <div>
                                    <h6>{HomePage.collectInformation[lang]}</h6>
                                    <p>
                                        {HomePage.infoCollectInfo[lang]}
                                    </p>
                                </div>
                            </div>

                            <div style={{ textAlign, marginTop: "50px" }}>
                                <h4>{HomePage.communicateSupport[lang]}</h4>
                                <p>
                                    {HomePage.tawasl[lang]}
                                </p>
                            </div>
                            <div style={{ textAlign, marginTop: "50px" }}>
                                <h4>{HomePage.followUp[lang]}</h4>
                                <p>
                                    {HomePage.followUpContent[lang]}
                                </p>
                            </div>
                            <div style={{ textAlign, marginTop: "50px" }}>
                                <h4>{HomePage.feedback[lang]}</h4>
                                <p>
                                    {HomePage.feedbackContent[lang]}
                                </p>
                            </div>
                            <div style={{ textAlign, marginTop: "50px" }}>
                                <div>
                                    <h4>{HomePage.tips[lang]}</h4>
                                    <p>
                                        {HomePage.asContent[lang]}
                                    </p>
                                </div>
                                <div className='mt-5'>
                                    <h4>{HomePage.patience[lang]}</h4>
                                    <p>
                                        {HomePage.patienceContent[lang]}
                                    </p>
                                </div>
                                <div className='mt-5'>
                                    <h4> {HomePage.specific[lang]} </h4>
                                    <p>
                                        {HomePage.specificContent[lang]}
                                    </p>
                                </div>
                                <div className='mt-5'>
                                    <h4> {HomePage.resources[lang]} </h4>
                                    <p>
                                        {HomePage.resourcesContent[lang]}
                                    </p>
                                </div>
                                <p className='text-center mt-5'>
                                    {HomePage.additionalHelp[lang]}
                                </p>
                            </div>
                        </p>
                    </div>


                    <SharedStatic hint="support" />

                </main>
            </PageContainer>
        </div>
    )
}

export default TechnicalSupport
