import React, { useContext } from 'react'
import PageContainer from '../layout/PagecContainer'
import SharedImg from '../shared/modules/SharedImg'
import about from "../../assets/aboutUs.png"
import SharedStatic from './modules/SharedStatic'
import { useSelector } from 'react-redux'
import { ThemeContext } from '../../ThemeProvider'
const ContentLicense = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const { theme } = useContext(ThemeContext);
    return (
        <div className='font'>
            <PageContainer>
                <main id="contact-us" dir='rtl'>
                    <SharedImg img={about} />
                    <div className='container' dir={dir}>
                        <div className='infoContact mx-3'>
                            <div className=''>شروط ترخيص المحتوي؟</div>
                        </div>
                        <p className={`sharedColor mx-4 mt-5 ${theme === 'light' ? 'infofordetails' : "text-light"} `}>
                            <p className='fs-5'>
                                شروط ترخيص وحماية المحتوى لمنصة "سيشات"
                            </p>
                            <p className='fs-6'>

                                1. التعريفات
                            </p>

                            <p className='mt-5 fs-6'>
                                - المنصة: تشير إلى منصة "سيشات" للتعليم والتكنولوجيا.
                                <br />
                                - المحتوى: يشمل جميع النصوص، الصور، الفيديوهات، البرمجيات، التصاميم، البيانات، وغيرها من المواد المتاحة عبر منصة "سيشات".
                                <br />
                                - المستخدم: يشير إلى أي شخص يستخدم أو يستفيد من محتوى المنصة بأي شكل من الأشكال.
                                <br />
                                - الترخيص: الإذن الممنوح للمستخدمين لاستخدام محتوى المنصة وفقًا للشروط المحددة في هذه الوثيقة.
                                <br />
                            </p>
                            <p className='mt-5 fs-6'>
                                2. حقوق الملكية الفكرية
                                <br />
                                - جميع حقوق الملكية الفكرية الخاصة بالمحتوى المتاح على منصة "سيشات"، بما في ذلك حقوق النشر والعلامات التجارية وبراءات الاختراع، هي ملك حصري لمنصة "سيشات" أو للجهات المرخصة لها.
                                <br />
                                - لا يجوز للمستخدمين تحميل، نسخ، إعادة إنتاج، توزيع، نشر، تعديل، أو إنشاء أعمال مشتقة من المحتوى إلا بموافقة خطية مسبقة من المؤلف أو صاحب الفكرة.
                                <br />
                                - يُمنع أي استخدام غير مصرح به للمحتوى، وقد يؤدي ذلك إلى اتخاذ إجراءات قانونية لحماية حقوق الملكية الفكرية لمنصة "سيشات".
                            </p>

                            <p className='mt-5 fs-6'>
                                4. الاستخدام المسموح به
                                <br />
                                - يجوز للمستخدمين الوصول إلى المحتوى واستخدامه عبر المنصة فقط، وفقًا للشروط المحددة.
                                <br />
                                - يمكن للمستخدمين مشاركة الروابط المؤدية إلى محتوى "سيشات" مع الآخرين، بشرط عدم تعديل المحتوى أو حذف أي إشعارات حقوق النشر المرفقة به.
                                - يمكن للمستخدمين الاقتباس من المحتوى لأغراض البحث أو الدراسة، بشرط الإشارة الصحيحة إلى مصدر المحتوى ومنصة "سيشات".
                            </p>


                            <p className='mt-5 fs-6'>
                                8. الاتصال والإبلاغ
                                <br />
                                لأي استفسارات أو طلبات للحصول على إذن لاستخدام المحتوى خارج نطاق هذا الترخيص، يمكن للمستخدمين التواصل مع إدارة منصة "سيشات" عبر البريد الإلكتروني التالي:
                            </p>

                        </p>
                    </div>


                    <SharedStatic hint="about" />

                </main>
            </PageContainer>
        </div>
    )
}

export default ContentLicense
