import React, { useEffect } from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
// import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FaCirclePlus } from "react-icons/fa6";

import { useDispatch, useSelector } from "react-redux"

import { Create_Levele } from '../../../actions/curriculums/levels';
import { Update_Branch } from '../../../actions/curriculums/branchs';

const AddLevel = ({ branchID, levelsIDS }) => {
    const [show, setShow] = useState(false);
    const [name, setName] = useState("");
    const [load, setLoad] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const dispatch = useDispatch();

    console.log('levelsIDS', levelsIDS)
    const onSubmit = async () => {
        try {
            setLoad(false)
            const res = await dispatch(Create_Levele({
                name,
            }))
            if (res.data.case) {
                const response = await dispatch(Update_Branch({ _id: branchID, levelsIDS: [...levelsIDS, res.data.level._id] }))
                alert(response.data.message)
            }
            setLoad(true)
            handleClose()
        }
        catch (err) {
            setLoad(false)
            alert(err.message)
            console.warn(err)
        }
    }

    return (

        <div className='mainHome'>
            <button onClick={handleShow} className='addLevelIcon lightDataMore py-2' style={{borderRadius:"10px"}}>
                <FaCirclePlus size='25' color='#0F2A51' className='me-2 mx-2' />
                <span>اضافة مستوي</span>
            </button>

            {/* Modal Has Form  */}
            <Modal show={show} onHide={handleClose} className="font" centered style={{ color: "black" }}>
                <Modal.Header closeButton>
                    <Modal.Title>اضافة مستوى تعليمى</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <label>اسم المستوى : </label>
                        <input className='form-control mt-2' type="text" id="levelname" value={name} onChange={(e) => setName(e.target.value)} />
                    </form>
                    <button className='btn mt-2 bg-primary text-light' onClick={onSubmit}>
                        حفظ
                    </button>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddLevel
