import React, { useState } from 'react';
import ProgramCard from '../shared/modules/ProgramCard';
import Sidebar from './SideBar';
import EmptyComponent from '../Dashboard/Material/EmptyComponent'
import { HomePage } from '../../utils/Translation/HomePage';
import { useSelector } from 'react-redux';
const HeaderPrograms = ({ packages }) => {
    const [selectedLevelId, setSelectedLevelId] = useState(null);
    const loggedUser = useSelector((state) => state.loggedUser)

    const handleLevelSelect = (levelId) => {
        setSelectedLevelId(levelId);
    };

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const filteredPackages = selectedLevelId
        ? packages.filter(pkg => pkg.levelId === selectedLevelId)
        : packages;




    return (
        <div className='font' style={{ marginTop: "150px" }}>
            <div className='sharedBackground'>
                <p>{HomePage.myPrograms[lang]}</p>
            </div>

            {/* Cards */}
            <div style={{ width: "95%", margin: "auto" }} dir={`${dir}`}>
                <div className='row programsData justify-content-between align-items-start'>
                    <div className='col-lg-10 col-sm-6 col-md-6'>
                        <div className='row' dir='rtl'>
                            {filteredPackages.length > 0 ? (
                                filteredPackages.map((item) => (
                                    <div className='col-lg-4 col-md-8 col-sm-12 col-12' key={item._id}>
                                        <ProgramCard item={item} />
                                    </div>
                                ))
                            ) : (
                                <div style={{ margin: "auto", textAlign: "center" }}>
                                    <EmptyComponent showFooter={false} title={"لا يوجد برامج دراسيه"} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='col-lg-2 col-sm-6 col-md-4'>
                        <Sidebar onLevelSelect={handleLevelSelect} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeaderPrograms;
