import "./styles/StdResulte.css";
import "../StdStudyProg/styles/stdStudPro.css";

//import "./styles/stdStudPro.css";

import { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { GetMaterialsByIds, getMaterialByCode } from "../../../../utils/materials";
import { getLessonsByIDS } from "../../../../utils/lessons";
import { useSelector } from "react-redux";

export default function ShowProgramsComponent({ program, setResultsTable }) {
  const [showModal, setShowModal] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState();
  const [branch, setBranch] = useState(null);
  const [level, setLevel] = useState(null);
  const [loading, setLoading] = useState(false);

  const levels = useSelector((state) => state.levels);
  const branchs = useSelector((state) => state.branchs);

  useEffect(() => {
    setBranch(branchs.find((el) => el._id === program?.packData.branchId));
    setLevel(levels.find((el) => el._id === program?.packData.levelId));
  }, []);

  const handleShow = async () => {
    try {

      let materials = program?.packData?.materials;
      let materialsArr = [];

      const materialsPromise = Promise.all(
        materials.map(async m => {
          const response = await getMaterialByCode({
            code: m?.materialCode,
          });
          materialsArr.push(response.data?.material);

        })
      )
      await materialsPromise;
      program = { ...program, materialsArr };
      setSelectedProgram(program);
      setShowModal(true);
    }
    catch (err) {
      console.warn(err)
    }
  };


  const handleShowResultsTable = async () => {
    try {
      const tableDataMaterials = selectedProgram?.packData?.materials;
      let resultsTableArr = [];
      setLoading(true);
      for (let material of tableDataMaterials) {
        console.log('vvvvvvvvvvvvvvvvvvvv')
        const res = await getLessonsByIDS({ lessonsIDS: [...material?.selectedLessons] });
        console.log('ressssssssssssssssss');
        console.log(res?.data?.lessons);
        const [lessons] = res?.data?.lessons;

        resultsTableArr.push(lessons);
      }
      setLoading(false);
      setResultsTable(resultsTableArr);
    }
    catch (err) {
      console.error(err.message)
    }

  }

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        style={{ direction: "rtl" , color:"black" }}
        centered
        className="font"
        
      >
        <Modal.Header closeButton={false}>
          <Modal.Title style={{ color: "#0F2A51" }}> {loading && <Spinner />} إختر المادة :</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ol>
            {selectedProgram?.materialsArr?.map((el, i) => (
              <li key={i}
                onClick={() => handleShowResultsTable()}
                style={{ cursor: "pointer" }}>
                {el?.name}
              </li>
            ))}
          </ol>
          <div className="d-flex justify-content-end">
            <button className='btn lightData' onClick={() => setShowModal(false)}> الغاء </button>
          </div>
        </Modal.Body>
      </Modal>

      <div className="oneBoxStd handleBox d-flex justify-content-between align-items-center mb-3 font  m-auto">

        <div
          className="w-100 "
          style={{ cursor: "pointer" }}
        >
          <div className="stu_rightSideRes text-center">
            <div className="">
              <h3 id="studyProgramsId15011" className="proResult font text-center">
                {program?.packData?.name}
              </h3>
              <div>
                <p id="studyProgramsId2141" className="class mt-3 font text-center">
                  {branch?.name} - {level?.name}
                </p>
              </div>
            </div>
            <div className="m-auto text-center">
              <button className="btn btnGroub" onClick={() => handleShow()}>عرض النتائج</button>
            </div>
          </div>
        
        </div>
      </div>
    </>
  );
}
