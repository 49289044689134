import React, { useContext } from 'react'
import PageContainer from '../layout/PagecContainer'
import SharedImg from '../shared/modules/SharedImg'
import about from "../../assets/aboutUs.png"
import SharedStatic from './modules/SharedStatic'
import { useSelector } from 'react-redux'
import { HomePage } from '../../utils/Translation/HomePage'
import { ThemeContext } from '../../ThemeProvider'
const MainInteractive = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const { theme } = useContext(ThemeContext);
    return (
        <div className='font'>
            <PageContainer>
                <main id="contact-us" dir={dir}>
                    <SharedImg img={about} />
                    <div className='container'>
                        <div className='infoContact mx-3 w-100'>
                            <div className='' style={{ width: "380px" }}>{HomePage.interactiveFurniture[lang]}</div>
                        </div>
                        <p className={`sharedColor mx-4 mt-5 ${theme === 'light' ? 'infofordetails' : "text-light"} `}>
                            <p className='fs-5'>

                                {HomePage.educationalFurniture[lang]}
                            </p>

                        </p>
                        <p className={`sharedColor mx-4 mt-5 ${theme === 'light' ? 'infofordetails' : "text-light"} `}>
                            {HomePage.interactiveSmartFurniture[lang]}
                            <p className="mt-3"></p>
                            <br />
                            {HomePage.smartTablesD[lang]}
                            <p className="mt-3"></p>
                            <br />
                            {HomePage.smartTables[lang]}
                            <p className="mt-3"></p>
                            <br />
                            {HomePage.smartSeatsD[lang]}
                            <p className="mt-3"></p>
                            <br />
                            {HomePage.smartSeats[lang]}
                            <p className="mt-3"></p>
                            <br />

                            {HomePage.integrationWithAdvancedTechnology[lang]}
                            <p className="mt-3"></p>
                            <br />
                            {HomePage.hologramsInClassrooms[lang]}
                            <p className="mt-3"></p>
                            <br />
                            {HomePage.hologramTechnology[lang]}
                            <p className="mt-3"></p>
                            <br />
                            {HomePage.vrAr[lang]}
                            <p className="mt-3"></p>
                            <br />
                            {HomePage.vrArFurniture[lang]}
                            <p className="mt-3"></p>
                            <br />
                            {HomePage.integratedDevices[lang]}
                            <p className="mt-3"></p>
                            <br />
                            {HomePage.integratedDisplayScreensD[lang]}
                            <p className="mt-3"></p>
                            <br />
                            {HomePage.integratedDisplayScreens[lang]}
                            <p className="mt-3"></p>
                            <br />
                            {HomePage.interactionSensorsD[lang]}
                            <p className="mt-3"></p>
                            <br />
                            {HomePage.interactionSensors[lang]}
                            <p className="mt-3"></p>
                            <br />

                            {HomePage.connectedLearningEnvironment[lang]}
                            <p className="mt-3"></p>
                            <br />
                            {HomePage.interactiveSmartBoards[lang]}
                            <p className="mt-3"></p>
                            <br />
                            {HomePage.interactiveSmartBoardsD[lang]}
                            <p className="mt-3"></p>
                            <br />
                            {HomePage.advancedAudioVisualSystems[lang]}
                            <p className="mt-3"></p>
                            <br />
                            {HomePage.advancedAudioVisualSystemsD[lang]}
                            <p className="mt-3"></p>
                            <br />

                            {HomePage.smartControlAndConnectivity[lang]}
                            <p className="mt-3"></p>
                            <br />
                            {HomePage.centralControlPanels[lang]}
                            <p className="mt-3"></p>
                            <br />
                            {HomePage.centralControlPanelsD[lang]}
                            <p className="mt-3"></p>
                            <br />
                            {HomePage.iotConnectivity[lang]}
                            <p className="mt-3"></p>
                            <br />
                            {HomePage.centralControlPanelsD[lang]}
                            <p className="mt-3"></p>
                            <br />

                            {HomePage.innovationsAndGoals[lang]}
                        </p>
                    </div>


                    <SharedStatic hint="about" />

                </main>
            </PageContainer>
        </div>
    )
}

export default MainInteractive
