import React, { useContext } from "react";
import "../../styles/shared.css"
import LogoRhaya from '../../assets/images/logo.png'
import { Link } from "react-router-dom";
import { ThemeContext } from "../../ThemeProvider";
import { useSelector } from "react-redux";
import { HomePage } from "../../utils/Translation/HomePage";
import { loginTrans } from "../../utils/Translation/loginAndRegister";
import { FaFacebookF } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { FaTwitter } from "react-icons/fa6";



const FooterWidgets = () => {

    const { theme } = useContext(ThemeContext);
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const textAlign = dir === "rtl" ? "left" : "right";

    return (
        <div className={` homeHeader ${theme}  `} style={{ backgroundColor: theme === 'light' ? '#4169E2' : '#001C45' }} dir={`${dir}`}>
            <div className="container">
                <div className="row  font mt-5 justify-content-between align-items-center">
                    <div className="col-lg-3 col-md-6 col-sm-12 defaultActing">
                        <div >
                            <img src={LogoRhaya} alt="Logo Rhaya" style={{ width: "40%" }} />
                            <p style={{ color: "white", textAlign: "justify" }}>
                                {HomePage.contentFooter[lang]}
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-6 col-sm-12">
                        <div
                            className="row justify-content-between align-items-center col-12"
                            style={{ flexWrap: "wrap", width: "100%" }}
                        >
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div>
                                    <aside className="widget widget_footer">

                                        <ul className="ps-list--link">
                                            <li className="widget-title" style={{ textAlign: `${dir === "rtl" ? 'right' : 'left'}` }}>{HomePage.services[lang]}</li>
                                            <li style={{ textAlign: `${dir === "rtl" ? 'right' : 'left'}` }}>
                                                <Link to="/contact-us" style={{ textDecoration: "none" }}>
                                                    <span>{HomePage.Contact_us[lang]}</span>
                                                </Link>
                                            </li>
                                            <li style={{ textAlign: `${dir === "rtl" ? 'right' : 'left'}` }}>
                                                <Link to="" style={{ textDecoration: "none" }}>
                                                    <span>{HomePage.Privacy[lang]}</span>
                                                </Link>
                                            </li>
                                            <li style={{ textAlign: `${dir === "rtl" ? 'right' : 'left'}` }}>
                                                <Link to="/faq" style={{ textDecoration: "none" }}>
                                                    <span>{HomePage.Asked[lang]}</span>
                                                </Link>
                                            </li>
                                            <li style={{ textAlign: `${dir === "rtl" ? 'right' : 'left'}` }}>
                                                <Link to="/support" style={{ textDecoration: "none" }}>
                                                    <span>{HomePage.Technical_Support[lang]}</span>
                                                </Link>
                                            </li>
                                            <li style={{ textAlign: `${dir === "rtl" ? 'right' : 'left'}` }}>
                                                <Link to="/about-us" style={{ textDecoration: "none" }}>
                                                    <span>{HomePage.About_Us[lang]}</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </aside>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div>
                                    <aside className="widget widget_footer">

                                        <ul className="ps-list--link" style={{ textAlign: `${dir === "rtl" ? 'right' : 'left'}` }}>
                                            <li className="widget-title" style={{ textAlign: `${dir === "rtl" ? 'right' : 'left'}` }}>{HomePage.platform[lang]}</li>
                                            <li style={{ textAlign: `${dir === "rtl" ? 'right' : 'left'}` }}>
                                                <Link to="/academy" style={{ textDecoration: "none" }}>
                                                    <span>{HomePage.Academy[lang]}</span>
                                                </Link>
                                            </li>
                                            <li style={{ textAlign: `${dir === "rtl" ? 'right' : 'left'}` }}>
                                                <Link to="/interactive" style={{ textDecoration: "none" }}>
                                                    <span>{HomePage.interactivelearning[lang]}</span>
                                                </Link>
                                            </li>
                                            <li style={{ textAlign: `${dir === "rtl" ? 'right' : 'left'}` }}>
                                                <Link to="/system" style={{ textDecoration: "none" }}>
                                                    <span>{HomePage.interactiveStudy[lang]}</span>
                                                </Link>
                                            </li>
                                            <li style={{ textAlign: `${dir === "rtl" ? 'right' : 'left'}` }}>
                                                <Link to="/main-interactive" style={{ textDecoration: "none" }}>
                                                    <span>{HomePage.interactiveFur[lang]}</span>
                                                </Link>
                                            </li>
                                            <li style={{ textAlign: `${dir === "rtl" ? 'right' : 'left'}` }}>
                                                <Link to="/content-license" style={{ textDecoration: "none" }}>
                                                    <span>{HomePage.contentLicensing[lang]}</span>
                                                </Link>
                                            </li>
                                            <li style={{ textAlign: `${dir === "rtl" ? 'right' : 'left'}` }}>
                                                <Link to="/who-seshat" style={{ textDecoration: "none" }}>
                                                    <span>{HomePage.whoSeshat[lang]}</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </aside>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div>
                                    <aside className="widget widget_footer">

                                        <div className="widget_content">

                                            <div className="ps-list--social" style={{ textAlign: `${dir === "rtl" ? 'right' : 'left'}` }}>
                                                <p>{HomePage.sendTels[lang]}</p>
                                                <div>
                                                    <label>{loginTrans.email[lang]}</label>
                                                    <br />
                                                    <div className="d-flex mt-2 w-100">
                                                        <button className="btn  w-50 me-2 mx-2" style={{ height: "50px", color: "white", backgroundColor: theme === "light" ? "#120C54" : "#2B6CCD" }}>{HomePage.subscribeNow[lang]}</button>
                                                        <input type="text" className="form-control w-100" style={{ height: "50px" }} />
                                                    </div>
                                                </div>

                                                <div>
                                                    <a href="*" style={{ padding: "5px", borderRadius: "5px", marginRight: "5px", background: theme === "light" ? "#120C54" : "#2B6CCD" }}>
                                                        <FaFacebookF color="white" size={"20"} />
                                                    </a>
                                                    <a href="*" style={{ padding: "5px", borderRadius: "5px", marginRight: "5px", background: theme === "light" ? "#120C54" : "#2B6CCD" }}>
                                                        <FaTwitter color="white" size={"20"} />
                                                    </a>
                                                    <a href="*" style={{ padding: "5px", borderRadius: "5px", marginRight: "5px", background: theme === "light" ? "#120C54" : "#2B6CCD" }}>
                                                        <FiInstagram color="white" size={"20"} />
                                                    </a>


                                                </div>


                                            </div>
                                        </div>
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr style={{ color: "white" }} />

            <div className="d-flex justify-content-between align-items-center" style={{ color: "white", textAlign }}>
                <p>
                    <Link style={{ color: "white" }}>All Rights Reserved | Terms and Conditions | Privacy Policy</Link>
                </p>
                <p>Copyright © 2024 SESHAT </p>
            </div>
        </div>
    );
};

export default FooterWidgets;
