import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import imageVector from "../../../assets/aboutUsVector.png";
import register_mdi_password from "../../../assets/register_mdi_password.png";
import register_oui_arrow_down from "../../../assets/register_oui_arrow-down.png";
import login_loader_1000 from "../../../assets/login_loader_1000.gif";
// import login_Rectangle from "../../../assets/register_image.png";
// import login_Rectangle from "../../../assets/images/home_image.png";
import login_Rectangle from "../../../assets/images/home_image.png";
import {
  Register_Student_Parent,
  logIn,
} from "../../../actions/login&register";
import { useDispatch, useSelector } from "react-redux";
import "./styles/register.css";
import { cities } from "../../../constants";
import { loginTrans } from "../../../utils/Translation/loginAndRegister";
import PageContainer from "../../layout/PagecContainer";
import { HomePage } from "../../../utils/Translation/HomePage";
import { ThemeContext } from "../../../ThemeProvider";

function RegisterPage(props) {
  const navigate = useNavigate();
  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });

  // Boolean And Data For Student And Parent
  const [loading, setLoading] = useState(false);
  const [isStudentToggle, setIsStudentToggle] = useState(true);
  const [studentData, setStudentData] = useState({});
  const [parentData, setParentData] = useState({});

  // Form Data States Input Field
  const [category, setCategory] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [type, setType] = useState("");
  const [city, setCity] = useState("");

  const [parentName, setParentName] = useState("");
  const [parentJob, setParentJob] = useState("");

  const [motherPhone, setMotherPhone] = useState("");
  const [motherJob, setMotherJob] = useState("");

  const [mobile, setMobile] = useState("");

  const [favourt, setFavourt] = useState("");

  const [message, setMessage] = useState(false);

  // setMessage 
  useEffect(() => {
    setTimeout(() => {
      setMessage(false)
    }, 5000)

  }, [message])

  // handling the form data to submit
  const dispatch = useDispatch();

  const { branchs } = useSelector(({ branchs }) => {
    return { branchs }
  })

  // check data validation 
  const verifyData = (object) => {
    try {
      const keys = Object.keys(object);
      let missedKeys = [];
      for (let k of keys) {
        if (!object[k] || object[k].trim() === '') {
          missedKeys.push(k)
        }
      }
      return !missedKeys.length ? true : missedKeys

    }
    catch (err) {
      console.log(err.message)
    }
  }

  // verify passwords 

  const verifyPass = (pass1, pass2) => {
    if (pass1 === pass2) {
      return true
    }
    return false
  }

  // 
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isStudentToggle) {
      let validEmail = email.trim().toLowerCase();
      let studentFormData = {
        email: validEmail,
        password,
        confirmpassword,
        gender: type == '1' ? "ذكر" : "أنثى",
        role: "student",
        branchId: category,
        name: userName,
        username:userName,
        city,
        phone: mobile,
      };

      let verification = verifyData(studentFormData);
      if (verification !== true) {
        setMessage({ case: false, message: `missed data: ${verification.join(', ')} .` });
        return;
      }
      let checkPAss = verifyPass(password, confirmpassword);
      if (!checkPAss) {
        setMessage({ case: false, message: `Password not matched` });
        return;
      }

      setStudentData(studentFormData);
      setCategory("");
      setEmail("");
      setPassword("");
      setUserName("");
      setConfirmpassword("");
      setType("");
      setParentName("");
      setMobile("");
      setCity("");

      setIsStudentToggle(false);
      setLoading(false);

      // Scroll to top when switching to parent form
      window.scrollTo({
        top: 0,
        behavior: "smooth" // Smooth scroll
      });
    } else {
      let validEmail = email.trim().toLowerCase();

      let parentFormData = {
        email: validEmail,
        password,
        confirmpassword,
        role: "parent",
        phone: mobile,
        city,
        name: parentName,
        username:parentName,
        // motherPhone,
        // motherJob,
        // parentJob,
      };

      let verification = verifyData(parentFormData);
      if (verification !== true) {
        setMessage({ case: false, message: `missed data: ${verification.join(', ')} .` });
        return;
      }
      let checkPAss = verifyPass(password, confirmpassword);
      if (!checkPAss) {
        setMessage({ case: false, message: `Password not matched` });
        return;
      }
      setParentData(parentFormData);

      try {
        setLoading(true);
        let res = await dispatch(
          Register_Student_Parent({
            student: studentData,
            parent: parentFormData,
          })
        );

        setMessage(res.data);
        setLoading(false);
        if (res.data.case) {
          alert(lang === 'AR' ? 'تم التسجيل بنجاح, قم بتسجيل الدخول الآن كطالب او كولى امر' : 'Successfully registered, Login now as a student or as a parent.');
          navigate("/login");
        }

      } catch (error) {
        console.log("ERROR", error.message);
        setMessage({ case: false, message: error.message });
        setLoading(false);
      }
    }
  };

  const inputRef = useRef(null);

  // دالة لعمل focus على حقل الإدخال
  const focusOnInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };


  useEffect(() => {
    if (!isStudentToggle) {
      focusOnInput();
    }
  }, [isStudentToggle])

  const { theme } = useContext(ThemeContext);


  return (
    <PageContainer>
      <div className="registerPage" style={{ color: `${theme === "light" ? "" : "white"}` }} dir={dir}>
        <div className="container" style={{ marginTop: "50px" }}>

          {/* the login form and image and Parent and student Tabs*/}
          <div className="">
            <div className="registerStudentForm  font mb-5">

              <div className="px-4">

                {/* the login form and image */}
                {!loading ? (
                  <>
                    {
                      message && <div className={`mt-3 text-center alert ${message?.case ? 'alert-success' : 'alert-danger'}`} role="alert">
                        {message && message?.message}
                      </div>
                    }
                    <div className="formBody py-5">
                      <div className="row justify-content-between align-items-center">
                        <div className="col-lg-6 col-sm-6 col-md-6 col-12">
                          <div className="imagePart">
                            <img src={login_Rectangle} className="login-image w-100" alt="error" style={{ transform: `${dir === 'rtl' ? 'scaleX(-1)' : 'scaleX(1)'}` }} />
                          </div>
                        </div>

                        <div className="col-lg-6 col-sm-6 col-md-6 col-12">
                          <div className=" formBorder">
                            <form action="">

                              <div className="shadow formregister">
                                <div className="font">
                                  <p style={{ color: "#2B6CCD" }} className="fs-5">{HomePage.welcomeSentence[lang]}</p>
                                  {!isStudentToggle ? (<p className="fs-5" style={{ fontWeight: "400", color: "black" }}>{HomePage.writeDetailsParent[lang]}</p>) : (<p className="fs-5" style={{ fontWeight: "400", color: "black" }}>{HomePage.writeDetailsStudent[lang]}</p>)}

                                </div>
                                {/* البريد الإلكتروني */}
                                <div className="">
                                  <div className="mb-4">
                                    <input
                                      type="email"
                                      ref={inputRef}
                                      name="email"
                                      className={`form-control ${dir === "ltr" ? "input-ltr" : "input-rtl"}`}
                                      placeholder={loginTrans.email[lang]}
                                      id="registerPage8"
                                      aria-describedby="emailHelp"
                                      value={email}
                                      onChange={(event) => {
                                        setEmail(event.target.value);
                                      }}
                                    />
                                  </div>
                                </div>

                                {/* أسم المستخدم */}
                                {/* أسم ولي الأمر  الاب */}
                                {isStudentToggle ? (
                                  <div className="">
                                    <div className="mb-4">
                                      <input
                                        type="text"
                                        name="userName"
                                        className={`form-control ${dir === "ltr" ? "input-ltr" : "input-rtl"}`}
                                        placeholder={loginTrans.user_name[lang]}
                                        id="registerPage10"
                                        aria-describedby="emailHelp"
                                        defaultValue={userName}
                                        onChange={(event) => {
                                          setUserName(event.target.value);
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="">
                                    <div className="mb-4">
                                      <input
                                        type="text"
                                        name="parentName"
                                        className={`form-control ${dir === "ltr" ? "input-ltr" : "input-rtl"}`}
                                        placeholder={loginTrans.parent_name[lang]}
                                        id="registerPage21"
                                        aria-describedby="emailHelp"
                                        value={parentName}
                                        onChange={(event) => {
                                          setParentName(event.target.value);
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}

                                {/* وظيفة الاب */}
                                {/* {!isStudentToggle && (
                                  <div className="">
                                    <div className="mb-4 ">
                                      <input
                                        type="text"
                                        name="parentJob"
                                        className={`form-control ${dir === "ltr" ? "input-ltr" : "input-rtl"}`}
                                        placeholder={loginTrans.parent_job[lang]}
                                        id="registerPage201177"
                                        aria-describedby="emailHelp"
                                        value={parentJob}
                                        onChange={(event) => {
                                          setParentJob(event.target.value);
                                        }}
                                      />
                                    </div>
                                  </div>
                                )} */}

                                {/*  رقم التليفون الاب او الطالب */}
                                <div className="">
                                  <div className="mb-4 ">
                                    <input
                                      type="tel"
                                      name="mobile"
                                      className={`form-control ${dir === "ltr" ? "input-ltr" : "input-rtl"}`}
                                      placeholder={`${isStudentToggle
                                        ? loginTrans.student_phone[lang]
                                        : loginTrans.father_phone[lang]
                                        }`}
                                      id="registerPage20"
                                      aria-describedby="emailHelp"
                                      value={mobile}
                                      onChange={(event) => {
                                        setMobile(event.target.value);
                                      }}
                                    />
                                  </div>
                                </div>

                                {/* وظيفة الام */}
                                {/* {!isStudentToggle && (
                                  <div className="">
                                    <div className="mb-4 ">
                                      <input
                                        type="text"
                                        name="parentJob"
                                        className={`form-control ${dir === "ltr" ? "input-ltr" : "input-rtl"}`}
                                        placeholder={loginTrans.mother_job[lang]}
                                        id="registerPage2077"
                                        aria-describedby="emailHelp"
                                        value={motherJob}
                                        onChange={(event) => {
                                          setMotherJob(event.target.value);
                                        }}
                                      />
                                    </div>
                                  </div>
                                )} */}

                                {/* رقم التليفون الام */}
                                {/* {!isStudentToggle && (
                                  <div className="">
                                    <div className="mb-4 ">
                                      <input
                                        type="tel"
                                        name="mobile"
                                        className={`form-control ${dir === "ltr" ? "input-ltr" : "input-rtl"}`}
                                        placeholder={loginTrans.mother_phone[lang]}
                                        id="registerPage2024"
                                        aria-describedby="emailHelp"
                                        value={motherPhone}
                                        onChange={(event) => {
                                          setMotherPhone(event.target.value);
                                        }}
                                      />
                                    </div>
                                  </div>
                                )} */}

                                {/* أختر الصف */}
                                {isStudentToggle && (
                                  <div className="">
                                    <div className="mb-4 register_mdi_password_relative">
                                      <select
                                        className={`form-control ${dir === "ltr" ? "input-ltr" : "input-rtl"}`}
                                        aria-label="Default select example"
                                        name="category"
                                        id="registerPage23"
                                        value={category}
                                        onChange={(event) => {
                                          setCategory(event.target.value);
                                        }}
                                      >
                                        <option selected>{loginTrans.select_level[lang]}</option>
                                        {branchs.map(b => <option key={b._id} value={b._id}>{b.name}</option>)}
                                      </select>
                                      {/* <img
                                    className="register_mdi_password downArrow"
                                    src={register_oui_arrow_down}
                                    alt="error"
                                  /> */}
                                    </div>
                                  </div>
                                )}

                                {/* أختر المحافظه */}
                                <div className="">
                                  <div className="mb-4 register_mdi_password_relative">
                                    <select
                                      className={`form-control ${dir === "ltr" ? "input-ltr" : "input-rtl"}`}
                                      aria-label="Default select example"
                                      name="city"
                                      id="registerPage23521"
                                      value={city}
                                      onChange={(event) => {
                                        setCity(event.target.value);
                                      }}
                                    >
                                      <option selected>{loginTrans.choose_country[lang]}</option>
                                      {cities && cities.map(c => <option key={c.code} value={c.code}>{c.name}</option>)}

                                    </select>
                                    {/* <img
                                  className="register_mdi_password downArrow"
                                  src={register_oui_arrow_down}
                                  alt="error"
                                /> */}
                                  </div>
                                </div>

                                {/* النوع */}
                                {isStudentToggle ? <div className="">
                                  <div className="mb-4 register_mdi_password_relative">
                                    <select
                                      className={`form-control ${dir === "ltr" ? "input-ltr" : "input-rtl"}`}
                                      aria-label="Default select example"
                                      name="type"
                                      id="registerPage351"
                                      value={type}
                                      onChange={(event) => {
                                        setType(event.target.value);
                                      }}
                                    >
                                      <option value="0">{loginTrans.select_gender[lang]}</option>
                                      <option value="1">{loginTrans.male[lang]}</option>
                                      <option value="2">{loginTrans.female[lang]}</option>
                                    </select>
                                    {/* <img
                                  className="register_mdi_password downArrow"
                                  src={register_oui_arrow_down}
                                  alt="error"
                                /> */}
                                  </div>
                                </div> : <></>
                                }
                                {/* تأكيد كلمة السر */}
                                <div className="">
                                  <div className="mb-4 register_mdi_password_relative">
                                    <input
                                      type="password"
                                      name="password"
                                      className={`form-control ${dir === "ltr" ? "input-ltr" : "input-rtl"}`}
                                      placeholder={loginTrans.pass[lang]}
                                      id="registerPage9"
                                      aria-describedby="emailHelp"
                                      value={password}
                                      onChange={(event) => {
                                        setPassword(event.target.value);
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="">
                                  <div className="mb-4 register_mdi_password_relative">
                                    <input
                                      type="password"
                                      name="confirmPassword"
                                      className={`form-control ${dir === "ltr" ? "input-ltr" : "input-rtl"}`}
                                      placeholder={loginTrans.pass_confirm[lang]}
                                      id="registerPage11"
                                      aria-describedby="emailHelp"
                                      value={confirmpassword}
                                      onChange={(event) => {
                                        setConfirmpassword(event.target.value);
                                      }}
                                    />
                                  </div>
                                </div>



                                <div className="rules">

                                  <p className="rulesPara1" id="registerPage15" style={{ color: "black" }}>
                                    <input type="checkbox" name="policy" id="policy" className="mx-2" />
                                    {loginTrans.terms_1[lang]} {loginTrans.terms_2[lang]}
                                    &nbsp;
                                    <span>{loginTrans.and[lang]}</span>
                                    &nbsp;
                                    {loginTrans.privacy[lang]}
                                  </p>
                                </div>


                                <div className="btnDone text-center mt-5">
                                  {
                                    message && <div className={`mt-3 text-center alert ${message?.case ? 'alert-success' : 'alert-danger'}`} role="alert">
                                      {message && message?.message}
                                    </div>
                                  }
                                  <button
                                    type="button"
                                    style={{ border: "none", padding: "10px", borderRadius: "10px", color: "white", outline: "none" }}
                                    className="background w-75"
                                    onClick={handleSubmit}
                                  >
                                    <span id="registerPage17">
                                      {isStudentToggle ? loginTrans.complete_info[lang] : loginTrans.completed[lang]}
                                    </span>
                                  </button>
                                </div>

                                <div className="mt-4 text-center py-3">
                                  <div className="font">
                                    <span>{loginTrans.accountHas[lang]}</span>
                                    <br />
                                    <Link to="/login">{loginTrans.subscripe[lang]}</Link>
                                  </div>
                                </div>
                              </div>
                            </form>

                          </div>
                        </div>
                      </div>
                    </div>
                  </>

                ) : (
                  <div className="loaderIcon">
                    <span className="loader"></span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
}

export default RegisterPage;
