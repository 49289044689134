import React, { useEffect } from 'react'
import PageContainer from '../../components/layout/PagecContainer'
import HomeHeader from '../../components/Home/HomeHeader'
import OurPlatform from '../../components/Home/OurPlatform'
import AboutPlatform from '../../components/Home/AboutPlatform'
import Programs from '../../components/Home/Programs'
import JoinUs from '../../components/Home/JoinUs'
import Teachers from '../../components/Home/Teachers'
import { useSelector } from 'react-redux';
import Chat from '../../components-old/chat'
import { useLocation } from 'react-router-dom'
import SeshatStore from '../../components/Home/SeshatStore'
import EductionServices from '../../components/Home/EductionServices'
import OurSociety from '../../components/Home/OurSociety'
const HomePage = () => {
    const packages = useSelector((state) => state.packages);
    const location = useLocation();
    useEffect(() => {
        // حفظ المسار الحالي في localStorage
        localStorage.setItem("lastRoute", location.pathname);
    }, [location]);



    return (
        <div className='font'>
            <PageContainer>
                //<Chat />
                <main id="home" style={{ marginTop: "100px" }}>
                    <HomeHeader />

                    <OurPlatform />

                    <AboutPlatform />

                    <Programs packages={packages} />

                    <SeshatStore />

                    <EductionServices />

                    <JoinUs />


                    <OurSociety />


                    <Teachers />
                </main>
            </PageContainer>
        </div>
    )
}

export default HomePage
