import React from 'react'
import About from "../../assets/About.png"
import { HomePage } from '../../utils/Translation/HomePage'
import { useSelector } from 'react-redux';
const AboutPlatform = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div className=' p-4' style={{ marginTop: "120px" ,backgroundColor:"#4169E2"}} dir={`${dir}`}>
            <div className=''>
                <div className='row justify-content-between align-items-center aboutUs'>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <img src={About} alt="about" className='w-100' />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                        <div className='info' style={{ textAlign: `${dir === "rtl" ? 'right' : 'left'}` }}>
                            <h4>{HomePage.aboutPlatform[lang]}</h4>
                            <p className='mt-5'>{HomePage.aboutPlatform_content[lang]}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutPlatform
