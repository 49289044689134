export const HomePage = {
    landing_title: {
        AR: 'منصة',
        EN: 'platform',
        FR: 'plate-forme'
    },
    seshat: {
        AR: "متجر سيشات",
        EN: "SESHAT Store"
    },
    seshatContent: {
        AR: "قريبا",
        EN: "Soon"
    },

    landing_title2: {
        AR: 'برديات',
        EN: 'Bardyat',
        FR: 'Bardyat'
    },
    landing_title3: {
        AR: 'للتعليم الإفتراضي',
        EN: 'virtual education',
        FR: "pour l'éducation virtuelle"
    },
    landing_desc: {
        AR: "منصة تعليمية مصرية مبنية على أسس ومعايير تربوية، تقدم جملة من الخدمات التعليمية والتربوية إلى كلا من المعلمين، والطلاب، وأولياء الأمور؛ بهدف تحسين عمليتي التعليم والتعلم. ",
        EN: "An Egyptian educational platform built on educational principles and standards, offering a range of educational and pedagogical services to teachers, students, and parents; with the aim of improving the processes of teaching and learning.",
        FR: "Une plateforme éducative égyptienne construite sur des principes et des normes pédagogiques, offrant une gamme de services éducatifs et pédagogiques aux enseignants, aux élèves et aux parents ; dans le but d'améliorer les processus d'enseignement et d'apprentissage."
    },
    landing_about: {
        AR: 'تعرف علي برديات',
        EN: 'Know About Bardyat',
        FR: 'connais sur Bardyat'
    },
    landing_start_now: {
        AR: 'أبدء الآن',
        EN: 'start now',
        FR: 'Commencez maintenant'
    },
    whatDefference_title: {
        AR: "ما المختلف في برديات ؟",
        EN: "What is different about Bardiyyat ?",
        FR: "Qu'est-ce qui est différent à propos de Bardiyyat ?"
    },
    whatDefference_store: {
        AR: "متجر للمنتجات",
        EN: "Store For Products",
        FR: "Magasin de produits"
    },
    whatDefference_Games: {
        AR: "ألعاب تعليمية",
        EN: "Educational games",
        FR: "Jeux éducatifs"
    },
    whatDefference_monetoring: {
        AR: "متابعة مباشرة",
        EN: "Parent Monetoring",
        FR: "Suivi direct pour le tuteur de l'élève"
    },
    whatDefference_interactiveLessons: {
        AR: 'دروس تفاعلية',
        EN: 'Interactive lessons',
        FR: "Cours interactifs"
    },
    about_us_title: {
        AR: "من نحن؟",
        EN: "Who are we ?",
        FR: "Qui sommes-nous ?"
    },
    about_us_desc: {
        AR: "هى منصة تعليمية مصرية تطمح الى الوصول لمكانة كبيرة ومعروفة على المستوى المحلى مبدئيا وتقديم خدمات قادرة على التنافس مع أكبر المنصات التعليمية المصرية .",
        EN: "It is an Egyptian educational platform that aspires to achieve a prominent and well-known position locally initially and to provide services capable of competing with the largest educational platforms in Egypt.",
        FR: "C'est une plateforme éducative égyptienne qui aspire à atteindre une position importante et reconnue localement dans un premier temps, et à fournir des services capables de rivaliser avec les plus grandes plateformes éducatives en Égypte."
    },
    tools_store_title: {
        AR: "متجر للأدوات",
        EN: "Store for tools",
        FR: "Magasin d'outils"
    },
    tools_store_desc: {
        AR: "متجرنا يوفر الأدوات المدرسية والكتب التي يمكنك طلبها أونلاين تصلك أينما كنت وبأسعار منافسة",
        EN: "Our store provides school supplies and books that you can order online and have them delivered to you wherever you are, at competitive prices.",
        FR: "Notre magasin propose des fournitures scolaires et des livres que vous pouvez commander en ligne et vous faire livrer où que vous soyez, à des prix compétitifs."
    },
    challenge_title: {
        AR: "أختبر وتحدى",
        EN: 'Test and challenge',
        FR: 'Testez et défiez'
    },
    challenge_section1: {
        AR: "أحصل على تحديات جديدة يومية مع زملائك وقم بمشاركتها ",
        EN: 'Get new daily challenges with your peers and share them',
        FR: 'Obtenez de nouveaux défis quotidiens avec vos pairs et partagez-les'
    },
    challenge_section2: {
        AR: "تعرف على أدائك من خلال أختبارات ومراجعات دورية",
        EN: "Know your performance through regular tests and reviews",
        FR: 'Connaissez votre performance à travers des tests et des évaluations régulières'
    },
    nav_1: {
        AR: "الصفحة الرئيسية",
        EN: "Homepage",
        FR: "Page d'accueil"
    },
    nav_2: {
        AR: "الصف الدراسي",
        EN: "Classroom",
        FR: "Classe"
    },
    nav_3: {
        AR: "المتجر",
        EN: "Store",
        FR: "Magasin"
    },
    nav_4: {
        AR: "المجتمع",
        EN: "Community",
        FR: "Communauté"
    },
    nav_5: {
        AR: "تواصل معنا",
        EN: "Contact",
        FR: "Contactez"
    },
    nav_6: {
        AR: "لوحة التحكم",
        EN: "Dashboard",
        FR: "Tableau de bord"
    },
    nav_7: {
        AR: "تسجيل الخروج",
        EN: "Logout",
        FR: "Déconnexion"
    },
    nav_8: {
        AR: "اشترك الان",
        EN: "Subscribe",
        FR: "Abonnez-vous"
    },
    nav_9: {
        AR: "سجل الان",
        EN: "Register",
        FR: "Inscrivez-vous"
    },
    footer_about_us: {
        AR: "بـــــرديــــــــات مبســــــطـاهـا",
        EN: "Our vision is to provide comfort and assistance in enhancing your knowledge and skills",
        FR: "Notre vision est de fournir du confort et de l'assistance pour améliorer vos connaissances et compétences."
    },
    quick_links: {
        AR: 'روابط سريعة',
        EN: 'Quick links',
        FR: 'Liens rapides'
    }
    ,
    footer_about_us_title: {
        AR: "عنا",
        EN: "About",
        FR: "À propos"
    },
    footer_help: {
        AR: "مساعدة",
        EN: "Help",
        FR: "Aide"
    },
    categories: {
        AR: "التصنيفات",
        EN: "Categories",
        FR: "Catégories"
    },
    results: {
        AR: "النتائج",
        EN: "Results",
        FR: "Résultats"
    },
    program_sub: {
        AR: "اشتراك البرنامج",
        EN: "Program subscription",
        FR: "Abonnement au programme"
    },
    program_name: {
        AR: "اسم البرنامج",
        EN: "Program name",
        FR: "Nom du programme"
    },
    program_cost: {
        AR: "تكلفة البرنامج",
        EN: "Program cost",
        FR: "Coût du programme"
    },
    program_supervision: {
        AR: "تكلفة الاشراف",
        EN: "Supervision cost",
        FR: "Coût de supervision"
    },
    select_supervisor: {
        AR: "اختيار مشرف",
        EN: "select  supervisor",
        FR: "Choisir un superviseur"
    },
    yes: {
        AR: "نعم",
        EN: "Yes",
        FR: "Oui"
    },
    no: {
        AR: "لا",
        EN: "No",
        FR: "Non"
    },
    follow_steps: {
        AR: "اتبع الخطوات التالية",
        EN: "Follow the following steps",
        FR: "Suivez les étapes suivantes"
    },
    payment_method: {
        AR: "برجاء اختيار وسيلة الدفع",
        EN: "Please choose a payment method",
        FR: "Veuillez choisir un mode de paiement"
    },
    digital_wallet: {
        AR: "برجاء اختيار وسيلة الدفع",
        EN: "Digital wallet",
        FR: "Portefeuille numérique"
    },
    pay_on: {
        AR: "برجاء دفع مبلغ",
        EN: "Please make the payment",
        FR: "Veuillez effectuer le paiement"
    },
    on_number: {
        AR: "علي رقم",
        EN: "on Number",
        FR: "Sur le numéro"
    },
    then_upload: {
        AR: "ثم قم برفع ايصال الدفع من هنا",
        EN: "Then upload the payment receipt",
        FR: "Ensuite, téléchargez le reçu de paiement"
    },
    cancel: {
        AR: "الغاء",
        EN: "Cancel",
        FR: "Annuler"
    },
    pay: {
        AR: "ادفع",
        EN: "Pay",
        FR: "Payer"
    },

    bardyat_store: {
        AR: "متجر برديات",
        EN: "Bardyat Store",
        FR: "Boutique Bardiyyat"
    },
    products_collection: {
        AR: "متجر برديات",
        EN: "Product collection",
        FR: "Collection de produits"
    },
    all_products: {
        AR: "جميع المنتجات",
        EN: "All products",
        FR: "Tous les produits"
    },
    price: {
        AR: "السعر",
        EN: "Price",
        FR: "Prix"
    },
    show_cart: {
        AR: "عرض السلة",
        EN: "View cart",
        FR: "Voir le panier"
    },
    total: {
        AR: "المجموع",
        EN: "Total",
        FR: "Total"
    },
    order_confirm: {
        AR: "اتمام الطلب",
        EN: "Complete order",
        FR: "Finaliser la commande"
    },
    inStore: {
        AR: " في المخزن",
        EN: "Available in stock",
        FR: "Disponible en stock"
    },
    outOfStore: {
        AR: " غير متوفر في المخزن",
        EN: "Out of stock",
        FR: "En rupture de stock"
    },
    available_colors: {
        AR: "الالوان المتاحة",
        EN: "Available colors",
        FR: "Couleurs disponibles"
    },

    add_to_cart: {
        AR: "اضافة الي السلة",
        EN: "Add to cart",
        FR: "Ajouter au panier"
    },

    desc: {
        AR: "الوصف ",
        EN: "Description",
        FR: "Description"
    },

    image: {
        AR: "صورة ",
        EN: "Image",
        FR: "Image"
    },

    product: {
        AR: "المنتج",
        EN: "product",
        FR: "produit"
    },

    quantity: {
        AR: "العدد",
        EN: "Quantity",
        FR: "Quantité"
    },

    cart_summary: {
        AR: "اجمالي سلة المشتريات",
        EN: "Total shopping cart",
        FR: "Total du panier d'achats"
    },

    shipping_cost: {
        AR: "تكلفة الشحن",
        EN: "Shipping cost",
        FR: "Frais de livraison"
    },

    total_price: {
        AR: "الاجمالي",
        EN: "Total price",
        FR: "Prix total"
    },

    order_address: {
        AR: "من فضلك أدخل العنوان لاتمام الطلب",
        EN: "Please enter the address to complete the order",
        FR: "Veuillez saisir l'adresse pour finaliser la commande"
    },
    dashboard_programs: {
        AR: "البرامج الدراسية",
        EN: "Educational programs",
        FR: "Programmes éducatifs"
    },
    dashboard_results: {
        AR: "النتائج",
        EN: "Results",
        FR: "Résultats"
    },
    dashboard_subscription_req: {
        AR: "طلبات الاشتراك",
        EN: "Subscription requests",
        FR: "Demandes d'abonnement"
    },
    dashboard_subscriptions: {
        AR: "مشتريات المتجر",
        EN: "Subscriptions",
        FR: "Abonnements"
    },
    dashboard_society: {
        AR: "المجتمع",
        EN: "Community",
        FR: "Communauté"
    },
    dashboard_profile_info: {
        AR: "معلومات الصفحة الشخصية",
        EN: "Profile information",
        FR: "Informations du profil"
    },

    parent: {
        AR: "والد",
        EN: "parent of",
        FR: "parent of"
    },

    logout: {
        AR: "تسجيل الخروج",
        EN: "log out",
        FR: "Déconnexion"
    },

    pay_status: {
        AR: "حالة الدفع",
        EN: "Payment status",
        FR: "État du paiement"
    },
    notes: {
        AR: "ملاحظات",
        EN: "Notes",
        FR: "Remarques"
    },
    pending: {
        AR: "تحت الانتظار",
        EN: "Pending",
        FR: "En attente"
    },
    not_found: {
        AR: "لا يوجد ",
        EN: "Not Found",
        FR: "Aucun"
    },
    week_schedule: {
        AR: "الجدول الاسبوعي",
        EN: "Weekly schedule",
        FR: "Emploi du temps hebdomadaire"
    },
    category: {
        AR: "الفئة",
        EN: "Category",
        FR: "Catégorie"
    },

    order: {
        AR: "الطلب",
        EN: "Order",
        FR: "Commande"
    },
    color: {
        AR: "اللون",
        EN: "Color",
        FR: "Couleur"
    },

    date: {
        AR: "التاريخ",
        EN: "Date",
        FR: "Date"
    },
    status: {
        AR: "الحالة",
        EN: "Status",
        FR: "État"
    },
    address: {
        AR: "العنوان",
        EN: "Address",
        FR: "Adresse"
    },
    go_to_store: {
        AR: "الذهاب الي المتجر الان",
        EN: "Go to store now",
        FR: "Aller au magasin maintenant"
    },
    you_have_no_lessons: {
        AR: "لست مشترك في اي دروس",
        EN: "You are not enrolled in any classes",
        FR: "Vous n'êtes inscrit à aucun cours"
    },
    subscribe_now: {
        AR: "اشترك الان",
        EN: "Subscribe now",
        FR: "Inscrivez-vous maintenant"
    },
    continue_education: {
        AR: "متابعة الدراسة",
        EN: "Continuing education",
        FR: "Continuer les études"
    },
    subjects: {
        AR: "المواد",
        EN: "Subjects",
        FR: "Les matières"
    },
    choices: {
        AR: "اختيارات",
        EN: "Choices",
        FR: "Choix"
    },
    contact_supervisor: {
        AR: "تواصل مع المشرف",
        EN: "Contact supervisor",
        FR: "Communiquer superviseur"
    },
    Weekly_honor_roll: {
        AR: "لوحة شرف الاسبوع",
        EN: "Weekly honor roll",
        FR: "Tableau d'honneur de la semaine"
    },
    no_subjects_added_yet: {
        AR: "لم يتم اضافة فصل دراسي بعد",
        EN: "No academic term has been added yet",
        FR: "Aucune session n'a encore été ajoutée"
    },
    subject_name: {
        AR: "اسم المادة",
        EN: "Course name",
        FR: "Nom du cours"
    },
    teacher_name: {
        AR: "اسم المعلم",
        EN: "Teacher name",
        FR: "Nom de l'enseignant"
    },
    meeting_room: {
        AR: "غرفة الاجتماعات",
        EN: "Meeting room",
        FR: "Salle de réunion"
    },
    no_session_link: {
        AR: "لم يتم اضافة رابط الحصة بعد",
        EN: "The session link has not been added yet",
        FR: "Le lien de la session n'a pas encore été ajouté"
    },
    contact_student: {
        AR: 'تواصل مع الطالب',
        EN: 'Contact Student',
        FR: "Communiquer avec l'étudiant"
    },
    contact_teacher: {
        AR: 'تواصل مع المعلم',
        EN: 'Contact Teacher',
        FR: "Contacter le professeur"
    },
    contact_parent: {
        AR: 'تواصل مع ولي الامر',
        EN: 'Contact Parent',
        FR: "Communiquer avec le Fr"
    },
    contact: {
        AR: 'تواصل  ',
        EN: 'Contact',
        FR: "Contacter"
    },
    who_bardiaat: {
        AR: ' من برديات ؟',
        EN: 'Who Bardiaat?',
        FR: 'Qui Bardiaat?'
    },
    who_bardiaat_content: {
        AR: `هى منصة تعليمية مصرية تطمح الى الوصول لمكانة كبيرة ومعروفة على
        المستوى المحلى مبدئيا وتقديم خدمات قادرة على التنافس مع أكبر المنصات
        التعليمية المصرية .`,
        EN: `It is an Egyptian educational platform that aspires to achieve a significant and well-known status initially on the local level, providing services capable of competing with the largest Egyptian educational platforms.`,
        FR: `C'est une plateforme éducative égyptienne qui aspire à atteindre une position importante et reconnue au niveau local dans un premier temps, en offrant des services capables de rivaliser avec les plus grandes plateformes éducatives égyptiennes.`
    },
    our_goals: {
        AR: 'أهدافنا',
        EN: 'Our Goals',
        FR: 'Nos Objectifs'
    },
    our_goals_content: {
        AR: `     إنشاء منصة وسيطة بين الطالب والمدرس وسيكون دور المنصة فيه هو النظام
        الإدارى وتوفير المزايا والإمكانيات اللازمة بين الطالب والمدرس .
`,
        EN: `To create an intermediary platform between students and teachers, where the platform's role will be administrative, providing the necessary benefits and capabilities for both students and teachers.`
        ,
        FR: `Créer une plateforme intermédiaire entre les élèves et les enseignants, où le rôle de la plateforme sera administratif, en fournissant les avantages et les capacités nécessaires pour les élèves et les enseignants.`
    },
    our_vesion: {
        AR: 'رؤيتنا',
        EN: 'Our Vesion',
        FR: 'Notre Vision'
    },
    our_vesion_content: {
        AR: `            تقديم تجربة تعليمية مبتكرة وممتعة مبنية على أسس ومعايير تربوية؛ تعزز من التفاعل والمشاركة الفعالة بين المعلمين والطلاب وأولياء الأمور، وتُمكِن الطلاب من تحقيق إمكاناتهم الكاملة.
        `,
        EN: `To provide an innovative and enjoyable educational experience based on educational principles and standards; enhancing interaction and effective participation among teachers, students, and parents, and enabling students to achieve their full potential.`,
        FR: `Offrir une expérience éducative innovante et agréable basée sur des principes et des normes pédagogiques; renforcer l'interaction et la participation efficace entre les enseignants, les élèves et les parents, et permettre aux élèves de réaliser pleinement leur potentiel.`
    },
    the_message: {
        AR: "رسالتنا",
        EN: 'Our Message',
        FR: 'Notre message'
    },
    the_message_content: {
        AR: `إعداد جيل واعٍ من الطلاب في شتى مراحل التعليم؛ يكون قادر على فهم احتياجاته، ويوظفها بشكل مثمر في تحقيق أهدافه، مما يعزز من سبل منافسة التعليم المصري على المستوى القومي والعالمي.
        `,
        EN: `To prepare an aware generation of students at various educational stages; capable of understanding their needs and utilizing them productively to achieve their goals, thereby enhancing the competitiveness of Egyptian education at the national and global levels.`,
        FR: `Préparer une génération consciente d'élèves à divers stades de l'éducation; capable de comprendre leurs besoins et de les utiliser de manière productive pour atteindre leurs objectifs, renforçant ainsi la compétitivité de l'éducation égyptienne aux niveaux national et mondial.`
    },
    program_description: {
        AR: 'وصف البرنامج',
        EN: 'Program Description',
        FR: 'Description du programme'
    },
    superVisor_name: {
        AR: 'المشرف',
        EN: 'Supervisor',
        FR: 'Superviseuse'
    },
    chatting: {
        AR: 'دردشة',
        EN: 'Chatting',
        FR: 'bavardage'
    },
    No_SuperVisor_Added: {
        AR: 'لم يتم اضافتك الى اى مشرفين بعد',
        EN: 'No Supervisors added for you yet.',
        FR: `Aucun superviseur ajouté pour vous pour l'instant.`
    },


    // Navbar
    Home: {
        AR: "الصفحه الرئيسيه",
        EN: "Home"
    },
    Subjects: {
        AR: "الأنظمة الدراسية الدولية",
        EN: "International education systems"
    },
    contentSystem: {
        AR: `في "سيشات"، نقدم برامج تعليمية تتوافق مع أعلى المعايير العالمية، مما يتيح للطلاب الاستفادة من الأنظمة الدراسية الرائدة`,
        EN: `At "Seshat," we offer educational programs that align with the highest global standards, allowing students to benefit from leading international education systems.`
    },
    Teachers: {
        AR: "المعلمون",
        EN: "Teachers"
    },
    Contact_us: {
        AR: "تواصل معنا",
        EN: "contact us"
    },
    society: {
        AR: "المجتمع",
        EN: "School Community"
    },
    login: {
        AR: "تسجيل الدخول",
        EN: "Log In"
    },
    register: {
        AR: "اشترك الان",
        EN: "Register Now"
    },
    log_out: {
        AR: "تسجيل الخروج",
        EN: "Log Out"
    },
    dashboard: {
        AR: "لوحة التحكم",
        EN: "Dashboard",
    },


    // HomeHeader Component
    info_header: {
        AR: "مرحبًا بكم في مجتمع سيشات، حيث يلتقي التاريخ بالمستقبل، ليمهد الطريق لاكتشافات جديدة وآفاق أوسع",
        EN: "Welcome to the SESHAT community, where history meets the future, paving the way for new discoveries and broader horizons."
    },
    info_header2: {
        AR: "سيشات - بوابتك نحو المعرفة المستنيرة مستوحاة من سيشات، رمز الحكمة والكتابة في الحضارة المصرية القديمة سيشات هي منصة تعليمية تجمع بين قوة المعرفة وأحدث تقنيات الذكاء الاصطناعي لتقديم تجربة تعليمية شاملة وفريدة.",
        EN: "Seshat - Your gateway to enlightened knowledge. Inspired by Seshat, the symbol of wisdom and writing in ancient Egyptian civilization, Seshat is an educational platform that combines the power of knowledge with the latest AI technologies to offer a comprehensive and unique learning experience. "
    },
    explore: {
        AR: "اكتشف المزيد",
        EN: "Learn More"
    },
    watch: {
        AR: "شاهد الفيديو",
        EN: "watch video"
    },
    // Our platform (HomeHeader)
    titlePlatform: {
        AR: "لماذا تستخدم منصتنا ؟",
        EN: "Why choose our platform?"
    },
    performance: {
        AR: "التعلم الذاتي",
        EN: "Self-learning"
    },
    performance_content: {
        AR: "نوفر أدوات تعليمية ذكية تمكن المتعلمين من تحقيق التعلم الذاتي وفق المعايير الدولية، مع دعم تواصلهم مع خبراء عالميين",
        EN: "We provide intelligent educational tools that empower learners to achieve self-learning according to international standards, with support for connecting them with global experts."
    },
    maintaince: {
        AR: "الوعي الثقافي",
        EN: "Cultural awareness"
    },
    maintaince_content: {
        AR: "نوفر أدوات تعليمية ذكية تمكن المتعلمين من تحقيق التعلم الذاتي وفق المعايير الدولية، مع دعم تواصلهم مع خبراء عالميين",

        EN: "We aim to enhance awareness and understanding among people through educational content inspired by diverse cultures, utilizing the latest global communication tools."
    },
    interactive: {
        AR: "دعم التعلم الدولي",
        EN: "Supporting international learning"
    },
    interactive_content: {
        AR: "نقدم محتوى تعليمي شامل يتناسب مع الفكر العالمي ويواكب أحدث استراتيجيات التعلم، لتلبية احتياجات الأفراد في مختلف مراحل حياتهم",
        EN: " We provide comprehensive educational content that aligns with global thinking and the latest learning strategies, catering to the needs of individuals at various stages of their lives."
    },
    teacher_prof: {
        AR: "مهارات المستقبل",
        EN: "Future skills"
    },

    teacher_prof_content: {
        AR: "نوفر برامج متخصصة لإعداد الطلاب والمهنيين لسوق العمل المستقبلي، مع التركيز على تطوير المهارات الرقمية والتكنولوجية",
        EN: "We offer specialized programs to prepare students and professionals for the future job market, with a focus on developing digital and technological skills."
    },
    aboutPlatform: {
        AR: "الفكرة والرؤية",
        EN: "The idea and vision",
    },
    aboutPlatform_content: {
        AR: "نسعى لأن نكون روادًا في تقديم حلول تعليمية ذكية تتخطى الحدود التقليدية للتعليم. نطمح إلى بناء مجتمع عالمي من المتعلمين والمبتكرين الذين يسعون إلى تحقيق التغيير الإيجابي في العالم من خلال التعليم المستدام والتكنولوجيا المتطورة.",
        EN: "We aim to be leaders in providing smart educational solutions that transcend traditional boundaries. We aspire to build a global community of learners and innovators who seek to make a positive impact on the world through sustainable education and advanced technology."
    },

    joinUs: {
        AR: "نبذة عن تاريخ سيشات",
        EN: "Join us for a distinctive learning experience."
    },
    joinUsContent: {
        AR: "سيشات كانت تُعرف في مصر القديمة كإلهة للمعرفة والكتابة، وتُعتبر رمًزا للحكمة والمعرفة العلمية .كانت تلعب دو ًرا مهًما في تسجيل األحداث التاريخية والمعارف العلمية، مما يجعلها مصدر إلهام لمنصة تسعى إلى الريادة في مجال التعليم باستخدام التقنيات .الحديثة",
        EN: "We guarantee you access to your platform with a simple, user-friendly design tailored to your needs. This ensures easy navigation between different parts of the platform, using consistent colors and logos that reflect the platform’s identity and make it stand out"
    },

    // Footer
    contentFooter: {
        AR: "منصة سيشات التعليمية تهدف إلى تحقيق تحول جذري في منظومة التعليم من خالل دمج التكنولوجيا المتطورة مع األسس التعليمية التقليدية، مما يوفر بيئة تعليمية تفاعلية وفعالة لجميع الطالب والمعلمين .تتطلع سيشات إلى أن تكون في مقدمة االبتكار التعليمي عالميًا، مع االستمرار في تقديم محتوى تعليمي ذو جودة عالية يتماشى مع التطورات الحديثة في مجال الذكاء .االصطناعي والتعليم الإلكتروني",
        EN: "The school follows modern and advanced curricula focused on developing students' critical and creative thinking skills. It employs interactive teaching methods to stimulate curiosity and discovery. The school is staffed by a team of experienced and skilled teachers who are committed to providing the best for their students. Teachers benefit from ongoing training programs to continually update and enhance their skills"
    },

    services: {
        AR: "الخدمات",
        EN: "Services"
    },
    Privacy: {
        AR: "سياسة الخصوصية والإستخدام",
        EN: "Privacy and Usage Policy"
    },
    Asked: {
        AR: "أسئلة متكررة",
        EN: "FAQ"
    },
    Technical_Support: {
        AR: "الدعم الفني",
        EN: "Support"
    },
    About_Us: {
        AR: "من نحن",
        EN: "About Us"
    },
    platform: {
        AR: "المنصه",
        EN: "Platform"
    },
    sendTels: {
        AR: "انضم ليصلك اخبارنا",
        EN: "Subscribe to receive our updates"
    },
    subscribeNow: {
        AR: "اشترك الان",
        EN: "Subscribe"
    },

    // Login && Register
    welcomeSentence: {
        AR: "مرحبا بكم في سيشات",
        EN: "Welcome to the SESHAT"
    },
    pleaseLogin: {
        AR: "من فضلك قم بتسجيل الدخول",
        EN: "Please log in."
    },

    writeDetails: {
        AR: "سجل بيانات الاشتراكات",
        EN: "Register your subscription details."
    },
    writeDetailsStudent: {
        AR: "تسجيل بيانات الطالب",
        EN: "Student data registration."
    },
    writeDetailsParent: {
        AR: "تسجيل بيانات ولي الامر",
        EN: "Register parent information."
    },
    google: {
        AR: "تسجيل الدخول بواسطو جوجل",
        EN: "Login With Google"
    },
    facebook: {
        AR: "تسجيل الدخول بواسطة فيسبوك",
        EN: "Login With Google"
    },

    // Programs Page
    myPrograms: {
        AR: "برامجي الدراسيه",
        EN: "My Academic Programs"
    },

    choose_classes: {
        AR: "اختر من الصفوف",
        EN: "Choose  classes"
    },

    form_contact: {
        AR: "هل تريد التحدث معنا ؟ هنا يتم الرد على استفسارات",
        EN: "Do you want to talk to us? Here we address your inquiries."
    },
    form_contact_info: {
        AR: `
        في "سيشات"، نحن هنا لدعمك في كل خطوة من رحلتك التعليمية. سواء كنت طالبًا يحتاج إلى دعم أكاديمي، أو معلمًا يبحث عن موارد تعليمية، أو حتى شخصًا مهتمًا بالتكنولوجيا والتعليم و الأدوات و الأبنية الذكية، يسعدنا أن نكون شريكك في النجاح.`,
        EN: "If you have any questions, comments, or suggestions, just fill out the form and we'll get back to you soon.",
    },
    firstName: {
        AR: "الاسم الاول",
        EN: "First Name"
    },
    message: {
        AR: "اكتب رسالتك هنا",
        EN: "Write Your Message Here"
    },

    // Teachers Pages
    teachers: {
        AR: "المعلمون",
        EN: "Teachers"
    },
    details: {
        AR: "التفاصيل",
        EN: "Details"
    },
    info_subscribe: {
        AR: "لتتمكن من التسجيل في البرامج، عليك متابعة المعلم.",
        EN: "To enroll in the programs, you need to follow the teacher."
    },
    teach: {
        AR: "المعلم",
        EN: "Teacher"
    },
    aboutTeacher: {
        AR: "نبذه عن المعلم",
        EN: "About Teacher"
    },
    prevPage: {
        AR: "عوده الي الصفحه السابقه",
        EN: "Return to the previous page."
    },
    booking: {
        AR: "احجز الان",
        EN: "Book Now"
    },
    following: {
        AR: "متابعه",
        EN: "follow"
    },
    subComplete: {
        AR: "تم الاشتراك",
        EN: "Subscription completed."
    },
    send_message_now: {
        AR: "ارسل رساله",
        EN: "Send Message"
    },
    commonQueuestions: {
        AR: "الاسئله الشائعه",
        EN: "Frequently Asked Questions"
    },
    sharedTwo: {
        AR: "عن المنصة ، من نحن ؟",
        EN: "About the Platform, Who Are We?"
    },
    sharedThree: {
        AR: "لديك مشكلة ؟ أذهب للدعم الفني",
        EN: " Have a problem? Go to Technical Support"
    },

    // Details Program
    programDetails: {
        AR: "تفاصيل البرنامج",
        EN: "Program Details"
    },
    material: {
        AR: "الماده",
        EN: "Material"
    },
    material_name: {
        AR: "اسم الماده",
        EN: "Material Name"
    },
    return_main_page: {
        AR: "العوده الي الصفحه الرئيسيه",
        EN: "Return To Main Page"
    },
    doneSubscribe: {
        AR: "قم بالاشتراك الان",
        EN: "Subscribe Now"
    },
    subscripeProgram: {
        AR: "اشتراك البرنامج",
        EN: "Subscribe Program"
    },
    infoOne: {
        AR: "برجاء دفع مبلغ ",
        EN: "Please pay an amount of."
    },
    infoTwo: {
        AR: "على محفظة الرقم الموضح",
        EN: "to the wallet number shown"
    },
    cashIsal: {
        AR: "قم برفع صورة من إيصال أو رسالة الدفع",
        EN: "Please upload a picture of the receipt or payment message."
    },
    vodaphone: {
        AR: "فودافون",
        EN: "vodaphone"
    },
    code: {
        AR: "كود",
        EN: "Code"
    },
    chooseIsal: {
        AR: "أختر صورة الإيصال أو رسالة الدفع",
        EN: "Please select the receipt or payment message image."
    },
    uploadImage: {
        AR: "ارفع الصوره",
        EN: "Upload Image"
    },
    forSubscripe: {
        AR: "اشتراك",
        EN: "Subscribe"
    },
    infoCode: {
        AR: "الاشتراك بنظام الاكواد ملحوظة: لفتح كل درس يتطلب حصولك على كود خاص به",
        EN: "Subscription to the code system. Note: To access each lesson, you need to obtain a specific code for it."
    },

    // For Dashboard
    subscriptionsRequest: {
        AR: "طلبات الإشتراك",
        EN: "Subscription requests"
    },
    messages: {
        AR: "الرسائل",
        EN: "Messages"
    },
    subscriptions: {
        AR: "الاشتراكات",
        EN: "subscriptions"
    },
    students: {
        AR: "طلابي",
        EN: "My Students",
    },
    programs: {
        AR: "البرامج",
        EN: "Programs"
    },
    create_Program: {
        AR: "انشاء برنامج",
        EN: "Create Program"
    },
    classes: {
        AR: "الفصول",
        EN: "Classes"
    },
    materials: {
        AR: "المواد",
        EN: "Materials"
    },
    personalInformation: {
        AR: "معلومات الصفحه الشخصيه",
        EN: "Personal Information"
    },
    AwarenessTips: {
        AR: "نصائح توعيه",
        EN: "Awareness Tips"
    },
    aboutMe: {
        AR: "نبذه عني",
        EN: "About Me"
    },
    phone: {
        AR: "التليفون",
        EN: "Phone"
    },
    email: {
        AR: "الايميل",
        EN: "Email"
    },
    editPersonalInfo: {
        AR: "تعديل المعلومات الشخصيه",
        EN: "Edit Personal Information"
    },
    material_test: {
        AR: "اختبار الماده",
        EN: "Material Test"
    },
    dateTest: {
        AR: "تاريخ الاختبار",
        EN: "Date Test"
    },
    Tries: {
        AR: "عدد المحاولات",
        EN: "Number Of Tries"
    },
    mark: {
        AR: "الدرجه",
        EN: "Mark"
    },
    hour: {
        AR: "الساعه",
        EN: "Hour"
    },
    communicateSupport: {
        AR: ": طرق التواصل مع الدعم الفني",
        EN: "Ways to Contact Technical Support"
    },
    sendReasons: {
        AR: "البريد الإلكتروني: يمكنك إرسال استفساراتك أو مشاكلك عبر البريد الإلكتروني إلى عنوان الدعم المحدد.",
        EN: "Email: You can send your inquiries or issues via email to the specified support address.",
    },
    ticketSystem: {
        AR: ": نظام التذاكر",
        EN: "Ticket System"
    },
    reach: {
        AR: "الوصول: عادةً ما يكون هناك قسم الدعم أو تقديم تذكرة.",
        EN: "Access: There is usually a Support section or Submit a Ticket"
    },
    steps: {
        AR: ": خطوات الحصول على الدعم الفني",
        EN: "Steps to Get Technical Support"
    },
    selectProplem: {
        AR: ": تحديد المشكلة",
        EN: "Identify the Problem"
    },
    selectAllProplems: {
        AR: "قم بتحديد المشكلة التي تواجهها بوضوح، مثل مشكلة في تسجيل الدخول، مشكلة في تشغيل الفيديوهات، أو مشكلة في الدفع.",
        EN: "Clearly identify the issue you're facing, such as login issues, video playback problems, or payment issues."
    },
    collectInformation: {
        AR: "جمع المعلومات الازمه",
        EN: "Gather Necessary Information"
    },
    infoCollectInfo: {
        AR: "اجمع معلومات مفصلة حول المشكلة مثل رسائل الخطأ، خطوات إعادة الإنتاج، وأي لقطات شاشة قد تكون مفيدة.",
        EN: "Gather detailed information about the problem, such as error messages, reproduction steps, and any useful screenshots."
    },
    eductionService: {
        AR: "خدماتنا التعليميه المتقدمه",
        EN: "Our advanced educational services"
    },
    eductionServiceContent: {
        AR: "في سيشات، نقدم مجموعة شاملة من الخدمات التعليمية المصممة لتلبية احتياجات المتعلمين في العصر الرقمي. ",
        EN: `At "Seshat," we offer a comprehensive range of educational services designed to meet the needs of learners in the digital age.`
    },

    // Footers
    Academy: {
        AR: "الاكاديميه",
        EN: "The Academy"
    },
    interactiveStudy: {
        AR: "الانظمه الدراسيه التفاعليه ",
        EN: "Interactive Study Systems"
    },
    interactivelearning: {
        AR: "دراسه تفاعليه",
        EN: "Interactive learning"
    },
    interactiveFur: {
        AR: "الاثاث التفاعلي",
        EN: "Interactive Furniture"
    },
    contentLicensing: {
        AR: "ترخيص المحتوي",
        ENL: "Content Licensing"
    },
    discountPrice: {
        AR: "السعر المخفض",
        EN: "Discount Price"
    },
    followUs: {
        AR: "تابعنا على وسائل التواصل الاجتماعي للحصول على آخر التحديثات والفعاليات والأخبار. نحن دائماً هنا لمساعدتك والإجابة على أي استفسارات.",
        EN: "Follow us on social media to stay updated with the latest events, news, and updates. We're always here to assist you and answer any questions you may have."
    },

    societyTwo: {
        AR: "مجتمعنا",
        EN: "Our Society"
    },

    Asia: {
        AR: "اسيا",
        EN: "Asia"
    },
    AsiaDesc: {
        AR: " أكبر قارات العالم من حيث المساحة والسكان.تتميز بتنوع ثقافي واسع، تضم دول الخليج وتحتضن أقدم الحضارات الإنسانية مثل الصين والهند. كما تضم قوى اقتصادية  مثل اليابان وكوريا الجنوبية",
        EN: "The largest continents in the world in terms of area .It is characterized by a wide cultural diversity, encompassing the Gulf countries and hosting some of the oldest human civilizations such as China and India. It also includes economic powers such as Japan and South Korea."
    },
    africa: {
        AR: "افريقيا",
        EN: "Africa"
    },
    africaDesc: {
        AR: " القارة الثانية من حيث المساحة والسكان، موطن لتنوع ثقافي ولغوي كبير. تتميز أفريقيا بمواردها الطبيعية الغنية وتاريخها العريق كمهبط للحضارات القديمة مثل مصر الفرعونية",
        EN: "The second largest continent in terms of area and population, home to a significant cultural and linguistic diversity. Africa is characterized by its rich natural resources and its ancient history as the cradle of ancient civilizations such as Pharaonic Egypt."
    },
    northAmerica: {
        AR: "امريكا الشماليه",
        EN: "North America"
    },
    northAmericaDesc: {
        AR: "أمريكا الشمالية تتألف من دول مثل الولايات المتحدة وكندا والمكسيك. تعتبر هذه القارة مركزًا اقتصاديًا وسياسيًا عالميًا، وتتميز بتنوع جغرافي وثقافي واسع",
        EN: "North America consists of countries such as the United States, Canada, and Mexico. This continent is considered a global economic and political center, characterized by a wide geographical and cultural diversity."
    },
    europe: {
        AR: "اوروبا",
        EN: "Europe"
    },
    europeDesc: {
        AR: "  أوروبا هي قارة صغيرة نسبيًا من حيث المساحة، لكنها ذات تأثير كبير على الصعيدين التاريخي والسياسي. تعتبر مهدًا للحضارات الغربية الحديثة وتضم عددًا كبيرًا من الدول المتقدمة",
        EN: "Europe is a relatively small continent in terms of area, but it has a significant impact on both historical and political levels. It is considered the cradle of modern Western civilizations and includes a large number of developed countries."
    },

    // Content of School community
    note: {
        AR: "ملحوظة",
        EN: "Note"
    },
    note_content: {
        AR: "يجب ان تكون مشترك فى فصل دراسى حتى تتمكن من المشاركة فى الاسئلة التفاعلية بين الطلاب والمعلمين ولكن يمكنك التصفح بدون اشتراك",
        EN: "You need to be enrolled in a class to participate in interactive questions between students and teachers, but you can browse without enrollment."
    },
    searh: {
        AR: "بحث",
        EN: "Search",
    },
    allPostesShow: {
        AR: "عرض كل المنشورات",
        EN: "Show All Posts"
    },
    showMore: {
        AR: "شاهد المزيد",
        EN: "Show More"
    },
    gradeLevel: {
        AR: "البحث بالمستوي الدراسي",
        EN: "Search by grade level."
    },
    class_room: {
        AR: "الفصل الدراسي",
        EN: "Classroom"
    },
    noPosts: {
        AR: "لا توجد منشورات حاليا لهذا القسم",
        EN: "There are no posts currently available for this section."
    },

    // FAQ
    whoSeshat: {
        AR: "ما هي منصة سيشات",
        EN: "What is the SESHAT platform?"
    },
    whoSeshatContent: {
        AR: "منصة OLP هي نظام متكامل يهدف إلى تقديم تجربة تعليمية متميزة عبر اإلنترنت. تشمل المنصة مجموعة متنوعة من الدروس التفاعلية، الفيديوهات التعليمية، المقاالت، والمواد المقروءة التي تغطي مختلف المجاالت التعليمية.",
        EN: "The OLP platform is a comprehensive system aimed at providing a unique online learning experience. The platform includes a variety of interactive lessons, educational videos, articles, and reading materials covering various educational fields."
    },

    registerToPlatform: {
        AR: "كيف يمكنني التسجيل في المنصة؟",
        EN: "How can I register on the platform?"
    },

    registerToPlatformContent: {
        AR: `انقر على زر "التسجيل" في الصفحة الرئيسية. امأل النموذج بالمعلومات المطلوبة مثل االسم، البريد اإللكتروني، وكلمة المرور. تأكيد التسجيل عبر رابط التفعيل الذي سيتم إرساله إلى بريدك اإللكتروني.`,
        EN: `Click the "Register" button on the homepage. Fill out the form with the required information such as name, email, and password. Confirm your registration through the activation link that will be sent to your email.`
    },

    foundSub: {
        AR: "هل هناك رسوم لالشتراك في المنصة؟",
        EN: "Are there any fees to subscribe to the platform?"
    },
    foundSubContent: {
        AR: "تعتمد الرسوم على نوع االشتراك والدورات التي ترغب في التسجيل بها. هناك دورات مجانية وأخرى مدفوعة. يمكنك االطالع على تفاصيل الرسوم عند اختيار الدورة التي تهمك.",
        EN: "The fees depend on the type of subscription and the courses you want to register for. There are free and paid courses. You can check the fee details when selecting the course that interests you."
    },

    howReach: {
        AR: "كيف يمكنني الوصول إلى الدورات بعد التسجيل؟",
        EN: "How can I access the courses after registering?"
    },
    howReachContent: {
        AR: `بعد تسجيل الدخول إلى حسابك، يمكنك تصفح الدورات المتاحة من خالل صفحة "الدورات" واختيار الدورة التي ترغب في االنضمام إليها. ستتمكن من الوصول إلى المحتوى التعليمي مباشرة بعد االشتراك في الدورة.`,
        EN: `After logging into your account, you can browse the available courses through the "Courses" page and choose the course you want to join. You will be able to access the educational content immediately after subscribing to the course.`
    },

    interActiveTeach: {
        AR: "هل يمكنني التفاعل مع المعلمين والمحاضرين؟",
        EN: "Can I interact with teachers and lecturers?"
    },
    interActiveTeachContent: {
        AR: "نعم، يمكنك التفاعل مع المعلمين والمحاضرين عبر المنصة من خالل الدروس المباشرة، المناقشات الجماعية، والمنتديات. يمكنك طرح األسئلة والحصول على اإلرشاد الالزم",
        EN: "Yes, you can interact with teachers and lecturers through the platform via live lessons, group discussions, and forums. You can ask questions and receive the necessary guidance."
    },

    rateTeach: {
        AR: "كيف يتم تقييم المتعلمين؟",
        EN: "How are learners evaluated?"
    },

    rateTeachContent: {
        AR: "يتم تقييم المتعلمين من خالل اختبارات قصيرة، مشاريع عملية، وأعمال تقييمية يتم تقديمها في نهاية كل وحدة دراسية. باإلضافة إلى ذلك، يمكنك الحصول على تغذية راجعة فورية من المعلمين.",
        EN: "Learners are evaluated through short tests, practical projects, and assessments submitted at the end of each unit. Additionally, you can receive instant feedback from teachers."
    },

    multDevices: {
        AR: "هل يمكنني الوصول إلى المنصة من خالل األجهزة المختلفة؟",
        EN: "Can I access the platform through different devices?"
    },

    multDevicesContent: {
        AR: "نعم، المنصة متجاوبة ويمكنك الوصول إليها من خالل الحواسيب، الهواتف الذكية، واألجهزة اللوحية بكل سهولة.",
        EN: "Yes, the platform is responsive, and you can easily access it from computers, smartphones, and tablets."
    },

    faceProplem: {
        AR: "ماذا أفعل إذا واجهتني مشكلة فنية؟",
        EN: "What should I do if I encounter a technical issue?"
    },

    faceProplemContent: {
        AR: `يمكنك التواصل مع فريق الدعم الفني عبر البريد اإللكتروني، الدردشة المباشرة، أو من خالل صفحة "الدعم" على المنصة. نحن هنا لمساعدتك في حل أي مشكلة تواجهك.`,
        EN: `You can contact the technical support team via email, live chat, or through the "Support" page on the platform. We are here to help you solve any issues you encounter.`
    },

    updateContent: {
        AR: "كيف يتم تحديث المحتوى التعليمي؟",
        EN: "How is the educational content updated?"
    },

    updateContentData: {
        AR: "نقوم بتحديث المحتوى التعليمي بانتظام لضمان مواكبة أحدث التطورات العلمية والتقنية. يتم إضافة مواد جديدة وتحديث الدروس الحالية بشكل دوري.",
        EN: "We regularly update the educational content to ensure it keeps up with the latest scientific and technological developments. New materials are added, and current lessons are updated periodically."
    },

    shareWithTogether: {
        AR: "هل يمكنني مشاركة حسابي مع اآلخرين؟",
        EN: "Can I share my account with others?"
    },

    shareWithTogetherContent: {
        AR: "ال، كل حساب على المنصة مخصص لمستخدم واحد فقط. مشاركة الحساب مع اآلخرين يخالف سياسات االستخدام وقد يؤدي إلى تعليق الحساب.",
        EN: "No, each account on the platform is intended for a single user only. Sharing the account with others violates the usage policies and may result in account suspension."
    },

    updateInfo: {
        AR: "كيف يمكنني تحديث معلوماتي الشخصية؟",
        EN: "How can I update my personal information?"
    },

    updateInfoContent: {
        AR: `يمكنك تحديث معلوماتك الشخصية من خالل صفحة "الملف الشخصي" بعد تسجيل الدخول إلى حسابك. يمكنك تعديل البيانات الشخصية، تفضيالت اإلشعارات، وكلمة المرور`,
        EN: `You can update your personal information through the "Profile" page after logging into your account. You can edit personal data, notification preferences, and password.`
    },

    schoolsAcademy: {
        AR: "المدارس والاكاديميات",
        EN: "Schools and Academies",
    },

    TeacherTraining: {
        AR: "تدريبات المعلمين",
        EN: "Teacher Training"
    },
    InternationalEducation: {
        AR: "التعليم الدولي",
        EN: "International Education"
    },
    GlobalCompanies: {
        AR: "الشركات العالمية ",
        EN: " Global Companies"
    },

    knowledgeVideo: {
        AR: "الفيديو التعريفي",
        EN: "Introductory Video",
    }
    ,

    provide: {
        AR: "توفر بعض المنصات خدمة دعم عبر الهاتف  لطلبات المساعدة العاجلة.",
        EN: "Some platforms offer phone support for urgent assistance requests."
    },
    tawasl: {
        AR: "استخدم إحدى طرق التواصل المتاحة للوصول إلى فريق   الدعم الفني. تأكد من تقديم كافة التفاصيل  والمعلومات التي جمعتها",
        EN: "Use one of the available communication methods to reach the technical support team. Make sure to provide all the details and information you've gathered."
    },


    followUp: {
        AR: ": متابعة الحالة",
        EN: "Follow Up on the Case:"
    },
    followUpContent: {
        AR: "إذا كنت قد قدمت تذكرة دعم أو طلبًا عبر البريد الإلكتروني، قم بمتابعة الحالة بانتظام للتأكد من حل المشكلة في الوقت المناسب.",
        EN: "If you have submitted a support ticket or request via email, regularly follow up on the case to ensure the issue is resolved in a timely manner."
    },
    feedback: {
        AR: ": تقديم التغذية الراجعة:",
        EN: "Provide Feedback:"
    },
    feedbackContent: {
        AR: "بعد حل المشكلة، قد يتم طلب تغذية راجعة حول تجربة الدعم الفني. قدم ملاحظاتك لتحسين جودة الخدمة.",
        EN: "After the issue is resolved, you may be asked for feedback on your technical support experience. Provide your comments to improve service quality."
    },
    tips: {
        AR: ": نصائح للحصول على دعم فعال",
        EN: "Tips for Effective Support"
    },
    patience: {
        AR: ": كن صبورًا",
        EN: "Be Patient"
    },
    patienceContent: {
        AR: "قد يستغرق الرد على طلبات الدعم بعض الوقت، خاصة خلال ساعات الذروة.",
        EN: "It may take some time to respond to support requests, especially during peak hours."
    },
    specific: {
        AR: ":كن محددًا",
        EN: "Be Specific"
    },
    specificContent: {
        AR: "كلما كنت أكثر تحديدًا في وصف المشكلة، كان من الأسهل على فريق الدعم مساعدتك.",
        EN: "The more specific you are in describing the issue, the easier it will be for the support team to assist you."
    },
    resources: {
        AR: ":استخدم الموارد المتاحة",
        EN: "Use Available Resources"
    },
    resourcesContent: {
        AR: "تحقق من مركز المساعدة والمنتديات قبل الاتصال بالدعم الفني، حيث قد تجد إجابات لأسئلتك هناك.",
        EN: "Check the help center and forums before contacting technical support, as you might find answers to your questions there."
    },
    additionalHelp: {
        AR: "إذا كنت بحاجة إلى أي مساعدة إضافية أو لديك استفسارات أخرى، فلا تتردد في طرحها. نحن هنا لدعمك!",
        EN: "If you need any additional help or have other inquiries, don’t hesitate to ask. We are here to support you!"
    },

    asContent: {
        AR: "بعد حل المشكلة، قد يتم طلب تغذية راجعة حول تجربة الدعم الفني. قدم مالحظاتك لتحسين جودة الخدمة.",

        EN: "After the issue is resolved, you may be asked for feedback on your technical support experience. Provide your comments to improve service quality."
    },


    // About
    whoAreWe: {
        AR: "من نحن؟",
        EN: "Who Are We?"
    },
    seshatIntro: {
        AR: `"سيشات" ليست مجرد منصة تعليمية؛ إنها رؤية تنطلق من عمق التاريخ لتواكب تحديات المستقبل. نحن فريق من المبدعين والخبراء في مجالات التعليم، التكنولوجيا، والذكاء الاصطناعي و كذلك التصميم و العمارة و الهندسة و الفنون. تأسست "سيشات" على فكرة الجمع بين التراث الثقافي الغني والتكنولوجيا الحديثة، لتقديم تجربة تعليمية فريدة تجمع بين الأصالة والتقدم.`,
        EN: `"Seshat" is not just an educational platform; it is a vision rooted in deep history to meet the challenges of the future. We are a team of creatives and experts in education, technology, artificial intelligence, as well as design, architecture, engineering, and the arts. "Seshat" was founded on the idea of combining rich cultural heritage with modern technology to provide a unique educational experience that blends tradition with progress.`
    },
    ourVision: {
        AR: "رؤيتنا:",
        EN: "Our Vision:"
    },
    visionContent: {
        AR: `نسعى لأن نكون روادًا في تقديم حلول تعليمية ذكية تتخطى الحدود التقليدية للتعليم. نطمح إلى بناء مجتمع عالمي من المتعلمين والمبتكرين الذين يسعون إلى تحقيق التغيير الإيجابي في العالم من خلال التعليم المستدام والتكنولوجيا المتطورة.`,
        EN: `We aim to be leaders in providing smart educational solutions that transcend traditional educational boundaries. We aspire to build a global community of learners and innovators who strive to create positive change in the world through sustainable education and advanced technology.`
    },
    ourMission: {
        AR: "مهمتنا:",
        EN: "Our Mission:"
    },
    missionContent: {
        AR: `تقديم تعليم ذو جودة عالية، يتماشى مع المعايير العالمية.  - تمكين المتعلمين. من خلال توفير بيئة تعليمية تفاعلية تدعم التفكير النقدي والإبداع.  - تعزيز الفهم الثقافي. عبر تقديم محتوى تعليمي مستوحى من ثقافات متنوعة.  - إعداد الطلاب لسوق العمل المستقبلي. من خلال برامج تعليمية متخصصة تركز على المهارات التقنية والرقمية.`,
        EN: `Providing high-quality education that aligns with global standards.  - Empowering learners by offering an interactive educational environment that supports critical thinking and creativity.  - Enhancing cultural understanding by offering educational content inspired by diverse cultures.  - Preparing students for the future job market through specialized educational programs that focus on technical and digital skills.`
    },
    seshatFuture: {
        AR: `"سيشات" رؤية مستقبلية تمزج بين الحكمة القديمة والتكنولوجيا الحديثة. نحن نؤمن بأن التعليم يجب أن يكون شاملاً، يتجاوز الحدود الجغرافية والثقافية، ليصل إلى كل من يسعى للمعرفة. في "سيشات"، نسعى لتعزيز الوعي العالمي عبر تقديم تجربة تعليمية و أدوات ذكية تتسم بالعمق، المرونة، والابتكار.`,
        EN: `"Seshat" is a future vision that blends ancient wisdom with modern technology. We believe that education should be inclusive, transcending geographical and cultural boundaries to reach everyone seeking knowledge. At "Seshat," we strive to enhance global awareness by providing an educational experience and smart tools characterized by depth, flexibility, and innovation.`
    },
    globalVision: {
        AR: "رؤيتنا العالمية:",
        EN: "Our Global Vision:"
    },
    globalVisionContent: {
        AR: `رؤيتنا تتجلى في بناء مجتمع عالمي من المتعلمين والمبدعين الذين يمتلكون القدرة على إحداث تغيير إيجابي في مجتمعاتهم والعالم بأسره. نعتمد على التكنولوجيا المتقدمة والذكاء الاصطناعي ليس فقط لتقديم المحتوى التعليمي، ولكن لإثراء تجربة التعلم و تطوير أدواته و الأبنية التعليمية الذكية، بشكل يتكيف مع احتياجات كل فرد، مع الحفاظ على جوهر التعليم التقليدي الذي يقدّر الحكمة والثقافة، و يدعم الصحة النفسية و المبادئ التربوية السليمة.`,
        EN: `Our vision is manifested in building a global community of learners and creators who have the ability to create positive change in their communities and the world at large. We rely on advanced technology and artificial intelligence not only to deliver educational content but to enrich the learning experience, develop its tools, and smart educational structures, in a way that adapts to the needs of each individual while preserving the essence of traditional education that values wisdom and culture, and supports mental health and sound educational principles.`
    },
    ourGoals: {
        AR: "أهدافنا :",
        EN: "Our Goals:"
    },
    goalsContent: {
        AR: `- تمكين الأفراد:  نوفر أدوات تعليمية ذكية تجعل كل متعلم قادراً على تحقيق إمكانياته الكاملة و قادر على التعلم الذاتي و ندعم تواصل الفرد مع خبراء،التعليم في جميع أنحاء العالم . - تعزيز التفاهم الثقافي:  من خلال محتوى تعليمي مستوحى من ثقافات متعددة، نهدف إلى تعزيز الوعي والتفاهم بين الشعوب و نوفر أحدث الأدوات للتواصل العالمي بدون حدود. - دعم التعلم الدولي:  نوفر محتوى تعليمي يلبي احتياجات الأفراد في كل مراحل حياتهم المهنية والشخصية و الدراسية و يتناسب مع الفكر العالمي و نتبع أحدث استراتيجيات التعليم الدولي. - تطوير المهارات اللازمة للمستقبل:  نقدم برامج متخصصة تهدف إلى إعداد الطلاب و المهنيين لسوق العمل المستقبلي، مع التركيز على المهارات الرقمية والتكنولوجية.`,
        EN: `- Empowering Individuals:  We provide smart educational tools that enable every learner to achieve their full potential and support self-directed learning while facilitating connections with education experts worldwide. - Enhancing Cultural Understanding:  Through educational content inspired by various cultures, we aim to foster awareness and understanding among people, providing the latest tools for global communication without boundaries. - Supporting International Learning:  We offer educational content that meets the needs of individuals at all stages of their professional, personal, and academic lives, aligning with global thinking and adopting the latest international education strategies. - Developing Future Skills:  We offer specialized programs aimed at preparing students and professionals for the future job market, with a focus on digital and technological skills.`
    },



    // Academy
    schoolsAndAcademies: {
        AR: "المدارس والاكاديميات؟",
        EN: "Schools and Academies?"
    },
    seshatPlatform: {
        AR: `منصة سيشات التعليمية تفتح أبواب التعاون مع المدارس والأكاديميات من خلال تقديم حلول تعليمية مبتكرة وشاملة، تعتمد على أحدث التقنيات في مجال الذكاء الاصطناعي والتكنولوجيا التعليمية. نحن نؤمن بأهمية الشراكة مع المؤسسات التعليمية لتعزيز جودة التعليم وتوسيع نطاقه، ولذلك نقدم خدمات مخصصة لكل مدرسة وأكاديمية وفقًا لاحتياجاتها الخاصة.`,
        EN: `The Seshat educational platform opens doors for collaboration with schools and academies by offering innovative and comprehensive educational solutions that rely on the latest technologies in artificial intelligence and educational technology. We believe in the importance of partnering with educational institutions to enhance and expand the quality of education, and therefore provide customized services for each school and academy according to their specific needs.`
    },
    collaboration: {
        AR: "كيفية التعاون مع المدارس والأكاديميات:",
        EN: "How to Collaborate with Schools and Academies:"
    },
    curriculumDesign: {
        AR: `1. تصميم وتطوير المناهج: نتعاون مع المدارس والأكاديميات في تصميم وتطوير مناهج تعليمية حديثة، متوافقة مع متطلبات العصر الرقمي. المناهج التي نقدمها تجمع بين المعرفة التقليدية والتكنولوجيا الحديثة، مما يضمن تقديم تجربة تعليمية متكاملة للطلاب.`,
        EN: `1. Curriculum Design and Development: We collaborate with schools and academies in designing and developing modern educational curricula that align with the requirements of the digital age. The curricula we offer combine traditional knowledge with modern technology, ensuring a comprehensive educational experience for students.`
    },
    smartBuildings: {
        AR: `2. تجهيز الأبنية التعليمية الذكية: نوفر حلولًا مبتكرة لتجهيز المدارس بأحدث تكنولوجيا الأبنية الذكية، بما في ذلك الفصول الدراسية المجهزة بأدوات تفاعلية، وأثاث تعليمي ذكي يتكيف مع احتياجات التعليم الحديثة. هذه الحلول تهدف إلى خلق بيئة تعليمية محفزة ومناسبة للتعلم التفاعلي.`,
        EN: `2. Smart Educational Buildings: We provide innovative solutions for equipping schools with the latest smart building technologies, including classrooms equipped with interactive tools and smart educational furniture that adapts to modern teaching needs. These solutions aim to create an engaging and suitable learning environment for interactive education.`
    },
    teacherTraining: {
        AR: `3. تدريب المعلمين: رؤيتنا تتجلى في بناء مجتمع عالمي من المتعلمين والمبدعين الذين يمتلكون القدرة على إحداث تغيير إيجابي في مجتمعاتهم والعالم بأسره. نعتمد على التكنولوجيا المتقدمة والذكاء الاصطناعي ليس فقط لتقديم المحتوى التعليمي، ولكن لإثراء تجربة التعلم و تطوير أدواته و الأبنية التعليمية الذكية، بشكل يتكيف مع احتياجات كل فرد، مع الحفاظ على جوهر التعليم التقليدي الذي يقدّر الحكمة والثقافة، و يدعم الصحة النفسية و المبادئ التربوية السليمة.`,
        EN: `3. Teacher Training: Our vision is to build a global community of learners and creators who have the ability to create positive change in their communities and the world at large. We rely on advanced technology and artificial intelligence not only to deliver educational content but to enrich the learning experience, develop its tools, and smart educational structures, adapting to the needs of each individual while preserving the essence of traditional education that values wisdom and culture, and supports mental health and sound educational principles.`
    },
    educationManagementSystems: {
        AR: `4. أنظمة إدارة التعليم: توفر سيشات أنظمة إدارة تعليمية متكاملة تتيح للمدارس والأكاديميات متابعة أداء الطلاب، إدارة المناهج، وتقديم تقارير دورية تساعد في اتخاذ القرارات التربوية بشكل أفضل. هذه الأنظمة تعتمد على الذكاء الاصطناعي لتقديم تحليلات دقيقة حول تقدم الطلاب واحتياجاتهم التعليمية.`,
        EN: `4. Education Management Systems: Seshat provides integrated educational management systems that allow schools and academies to track student performance, manage curricula, and generate periodic reports to aid in making better educational decisions. These systems rely on artificial intelligence to offer accurate analyses of student progress and educational needs.`
    },
    experienceExchange: {
        AR: `5. تبادل الخبرات والتجارب التعليمية: تتيح المنصة فرصًا لتبادل الخبرات والتجارب التعليمية بين المدارس والأكاديميات حول العالم، من خلال تنظيم ورش عمل، مؤتمرات، وبرامج تبادل طلابي. هذا التعاون الدولي يساهم في توسيع آفاق الطلاب والمعلمين على حد سواء، ويعزز من فهمهم للتحديات والفرص في التعليم العالمي.`,
        EN: `5. Exchange of Educational Experiences: The platform provides opportunities for exchanging educational experiences between schools and academies around the world through organizing workshops, conferences, and student exchange programs. This international collaboration contributes to broadening the horizons of both students and teachers and enhances their understanding of challenges and opportunities in global education.`
    }
    ,
    experienceExchangeTitle: {
        AR: "5. تبادل الخبرات والتجارب التعليمية:",
        EN: "5. Exchange of Educational Experiences:"
    },
    trainingTeachers: {
        AR: "تدريب المعلمين",
        EN: "Training Teacher"
    },
    to: {
        AR: `
         والمبدعين الذين يمتلكون القدرة على إحداث تغيير إيجابي في مجتمعاتهم والعالم بأسره. نعتمد على التكنولوجيا المتقدمة والذكاء الاصطناعي ليس فقط لتقديم المحتوى التعليمي، ولكن لإثراء تجربة التعلم و تطوير أدواته و الأبنية التعليمية الذكية، بشكل يتكيف مع احتياجات كل فرد، مع الحفاظ على جوهر التعليم التقليدي الذي يقدّر الحكمة والثقافة، و يدعم الصحة النفسية و المبادئ التربوية السليمة.
                        

        `,
        EN: "Our vision is to build a global community of learners and creators who have the ability to create positive change in their communities and the world at large. We rely on advanced technology and artificial intelligence not only to deliver educational content but to enrich the learning experience and develop its tools and smart educational structures, adapting to the needs of each individual while preserving the essence of traditional education that values wisdom and culture, and supports mental health and sound educational principles"
    },
    anotherSys: {
        AR: "4. أنظمة إدارة التعليم:",
        EN: "Management"
    },
    // Interactive
    interactiveTitle: {
        AR: "التعليم التفاعلي المدعوم بالذكاء الاصطناعي",
        EN: "AI-Powered Interactive Learning"
    },
    interactiveContent: {
        AR: `نستخدم تقنيات الذكاء الاصطناعي لتخصيص تجربة التعلم لكل طالب. هذه التقنية تتيح للمنصة فهم احتياجات المتعلمين وتقديم محتوى تعليمي مخصص يتماشى مع مستوى فهمهم، ووتيرة تعلمهم. يساعد هذا النهج على تحسين نتائج التعلم، حيث يحصل كل طالب على الدعم الذي يحتاجه بالضبط في الوقت المناسب.`,
        EN: `We use artificial intelligence technologies to personalize the learning experience for each student. This technology allows the platform to understand learners' needs and provide tailored educational content that matches their understanding level and learning pace. This approach helps improve learning outcomes, as each student receives the exact support they need at the right time.`
    },

    internationalSystemsTitle: {
        AR: "الانظمه الدراسيه الدوليه",
        EN: "International Educational Systems"
    },

    globalStandards: {
        AR: `في "سيشات"، نقدم برامج تعليمية تتوافق مع أعلى المعايير العالمية، مما يتيح للطلاب الاستفادة من الأنظمة الدراسية الرائدة مثل:`,
        EN: `At "Seshat," we offer educational programs that align with the highest global standards, allowing students to benefit from leading educational systems such as:`
    },
    britishSystem: {
        AR: `- النظام البريطاني:`,
        EN: `- The British System:`
    },
    britishSystemDescription: {
        AR: `يعكس هذا النظام العريق التزامًا بالتعليم المتوازن الذي يركز على تطوير التفكير النقدي والمهارات التحليلية. من خلال مؤهلات مثل GCSE وA-Levels، نوفر للطلاب أساسًا قويًا يساعدهم على التفوق الأكاديمي والمهني.`,
        EN: `This esteemed system reflects a commitment to balanced education that focuses on developing critical thinking and analytical skills. Through qualifications such as GCSE and A-Levels, we provide students with a strong foundation to excel academically and professionally.`
    },
    ibSystem: {
        AR: `- نظام البكالوريا الدولية IB: يتميز بجودته العالية ويركز على التفكير النقدي والتعلم الذاتي. يتيح هذا النظام للطلاب فرصة التأهل لأفضل الجامعات العالمية من خلال منهجية تعليمية متقدمة تهدف إلى تطوير مهارات الطلاب الفكرية والتحليلية.`,
        EN: `- The International Baccalaureate (IB) System: Known for its high quality, this system focuses on critical thinking and self-directed learning. It provides students with the opportunity to qualify for top global universities through an advanced educational methodology aimed at developing intellectual and analytical skills.`
    },
    americanSystem: {
        AR: `- النظام الأمريكي: يشتهر هذا النظام بمرونته وتركيزه على تنمية مهارات الحياة العملية والتفكير الإبداعي. عبر برامج مثل SAT وAP، نقدم للطلاب فرصة للتأهل للدراسة في أفضل الجامعات حول العالم، بالإضافة إلى إعدادهم لمسارات مهنية متميزة.`,
        EN: `- The American System: Renowned for its flexibility and focus on practical life skills and creative thinking. Through programs like SAT and AP, we offer students the chance to qualify for top universities worldwide, in addition to preparing them for distinguished career paths.`
    },

    // Main Interactive
    educationalFurniture: {
        AR: `في إطار جهود منصة سيشات لتقديم بيئة تعليمية متطورة ومتكاملة، تقدم حلولاً متقدمة في مجال الأثاث التعليمي التفاعلي الذكي. هذا النوع من الأثاث ليس مجرد عناصر تقليدية للفصل الدراسي، بل هو مكون رئيسي لبيئة تعليمية مدعومة بالتكنولوجيا المتقدمة، بما في ذلك الهولوجرام، الأجهزة الذكية، وتقنيات الواقع الافتراضي والمعزز.`,
        EN: `As part of Seshat Platform's efforts to provide an advanced and integrated educational environment, it offers innovative solutions in the field of interactive smart educational furniture. This type of furniture is not just traditional classroom elements but a key component of a technologically advanced educational environment, including holograms, smart devices, and virtual and augmented reality technologies.`
    },
    interactiveFurniture: {
        AR: `الأثاث التفاعلي`,
        EN: `Interactive Furniture`
    },
    interactiveSmartFurniture: {
        AR: `1. الأثاث التفاعلي الذكي:`,
        EN: `1. Interactive Smart Furniture:`
    },
    smartTables: {
        AR: `- الطاولات الذكية المتعددة الاستخدامات: تم تصميم الطاولات الذكية على منصة سيشات لتكون متعددة الاستخدامات، حيث يمكنها التحول من طاولات دراسية تقليدية إلى شاشات تفاعلية مزودة بتقنيات اللمس المتقدمة. هذه الطاولات يمكن أن تعرض محتوى تعليمي، مثل الخرائط التفاعلية، الفيديوهات التعليمية، والأنشطة التفاعلية، مما يتيح للطلاب التفاعل مع المحتوى بشكل مباشر.`,
        EN: `- Versatile Smart Tables: The smart tables on the Seshat platform are designed to be versatile, capable of transforming from traditional study tables into interactive screens equipped with advanced touch technologies. These tables can display educational content, such as interactive maps, educational videos, and interactive activities, allowing students to engage directly with the content.`
    },
    smartSeats: {
        AR: `- المقاعد الذكية: المقاعد الذكية مجهزة بأجهزة استشعار تقوم بقياس جلوس الطلاب ووضعيتهم لضمان راحتهم على مدار اليوم الدراسي. كما توفر المقاعد منافذ شحن USB ومنصات لحمل الأجهزة اللوحية، مما يسهل على الطلاب البقاء متصلين واستخدام أجهزتهم أثناء التعلم.`,
        EN: `- Smart Seats: The smart seats are equipped with sensors that measure students' seating positions to ensure their comfort throughout the school day. The seats also offer USB charging ports and platforms for holding tablets, making it easier for students to stay connected and use their devices during learning.`
    },
    integrationWithAdvancedTechnology: {
        AR: `2. الدمج مع التكنولوجيا المتقدمة:`,
        EN: `2. Integration with Advanced Technology:`
    },
    hologramsInClassrooms: {
        AR: `-*الهولوجرام في الفصول الدراسية:`,
        EN: `-*Holograms in Classrooms:`
    },
    hologramTechnology: {
        AR: `تعد تقنية الهولوجرام من الابتكارات الرائدة في التعليم التي تقدمها منصة سيشات. يمكن استخدام الهولوجرام لعرض مجسمات ثلاثية الأبعاد تفاعلية تساعد في توضيح المفاهيم المعقدة، مثل التشريح البشري، العمليات الكيميائية، والهندسة المعمارية. هذه التقنية تمكن الطلاب من التفاعل مع المحتوى التعليمي بطريقة لم تكن ممكنة من قبل.`,
        EN: `Hologram technology is one of the pioneering innovations in education offered by the Seshat platform. Holograms can be used to display interactive 3D models that help explain complex concepts, such as human anatomy, chemical processes, and architectural design. This technology enables students to interact with educational content in ways that were previously impossible.`
    },
    vrArFurniture: {
        AR: `- الواقع الافتراضي والواقع المعزز: الأثاث التفاعلي المدمج مع تقنيات الواقع الافتراضي (VR) والواقع المعزز (AR) يسمح للطلاب بخوض تجارب تعليمية غامرة. يمكنهم، على سبيل المثال، استكشاف العوالم الافتراضية أو رؤية الهياكل المعمارية ثلاثية الأبعاد في الواقع المعزز، مما يعزز من فهمهم وتفاعلهم مع المواد الدراسية.`,
        EN: `- Virtual Reality and Augmented Reality: Interactive furniture integrated with Virtual Reality (VR) and Augmented Reality (AR) technologies allows students to engage in immersive educational experiences. For example, they can explore virtual worlds or view 3D architectural structures in augmented reality, enhancing their understanding and interaction with study materials.`
    },
    integratedDevices: {
        AR: `3. الأجهزة المتكاملة مع الأثاث:`,
        EN: `3. Devices Integrated with Furniture:`
    },
    integratedDisplayScreens: {
        AR: `- *شاشات عرض مدمجة: يتم تزويد الطاولات واللوحات البيضاء بشاشات عرض مدمجة يمكن استخدامها لعرض المحتويات التعليمية بشكل تفاعلي. هذه الشاشات يمكنها التفاعل مع الأجهزة الذكية الأخرى في الفصل، مثل الهواتف الذكية والأجهزة اللوحية، لتقديم تجربة تعليمية متكاملة.`,
        EN: `- *Integrated Display Screens: Tables and whiteboards are equipped with integrated display screens that can be used to interactively present educational content. These screens can interact with other smart devices in the classroom, such as smartphones and tablets, to provide a comprehensive learning experience.`
    },
    interactionSensors: {
        AR: `- أجهزة استشعار التفاعل: تم تجهيز الأثاث التعليمي بأجهزة استشعار تراقب وتجمع البيانات حول تفاعل الطلاب مع المحتوى التعليمي. هذه البيانات يمكن استخدامها لتحليل أداء الطلاب وتحديد الأنماط التي تساعد المعلمين على تحسين خطط الدروس.`,
        EN: `- Interaction Sensors: The educational furniture is equipped with sensors that monitor and collect data on students' interaction with the educational content. This data can be used to analyze student performance and identify patterns that help teachers improve lesson plans.`
    },


    integratedDisplayScreensD: {
        AR: `- *شاشات عرض مدمجة:`,
        EN: `- *Integrated Display Screens:`
    },

    smartTablesD: {
        AR: `- الطاولات الذكية المتعددة الاستخدامات:`,
        EN: `- Versatile Smart Tables:`
    },
    smartSeatsD: {
        AR: `- المقاعد الذكية:`,
        EN: `- Smart Seats:`
    },


    vrAr: {
        AR: `- الواقع الافتراضي والواقع المعزز:`,
        EN: `- Virtual Reality and Augmented Reality:`
    },
    interactionSensorsD: {
        AR: `- أجهزة استشعار التفاعل:`,
        EN: `- Interaction Sensors:`
    },

    // 
    connectedLearningEnvironment: {
        AR: `4. بيئة تعليمية متصلة:`,
        EN: `4. Connected Learning Environment:`
    },
    interactiveSmartBoards: {
        AR: `- السبورات التفاعلية الذكية:`,
        EN: `- Interactive Smart Boards:`
    },
    interactiveSmartBoardsD: {
        AR: `- السبورات التفاعلية الذكية: السبورات التفاعلية هي جزء أساسي من البيئة التعليمية في منصة سيشات. يتميز هذا النوع من السبورات بالقدرة على التفاعل مع المحتوى الرقمي، السماح للمعلمين بإضافة الملاحظات، التفاعل مع الرسوم البيانية، وتشجيع الطلاب على المشاركة في حل المسائل في الوقت الحقيقي.`,
        EN: `- Interactive Smart Boards: Interactive boards are a fundamental part of the educational environment on the Seshat platform. These boards feature the ability to interact with digital content, allowing teachers to add notes, interact with charts, and encourage students to participate in problem-solving in real-time.`
    },
    advancedAudioVisualSystems: {
        AR: `- الأنظمة الصوتية والمرئية المتقدمة:`,
        EN: `- Advanced Audio-Visual Systems:`
    },
    advancedAudioVisualSystemsD: {
        AR: `- الأنظمة الصوتية والمرئية المتقدمة: تكمل الأنظمة الصوتية والمرئية المتطورة تجربة التعلم التفاعلي. يمكن للطلاب استخدام سماعات الواقع الافتراضي، وأنظمة الصوت المحيطي، والميكروفونات الذكية لتجربة تعليمية شاملة وغامرة.`,
        EN: `- Advanced Audio-Visual Systems: Advanced audio-visual systems enhance the interactive learning experience. Students can use virtual reality headsets, surround sound systems, and smart microphones for a comprehensive and immersive educational experience.`
    },

    // 
    smartControlAndConnectivity: {
        AR: `5. التحكم الذكي والاتصال:`,
        EN: `5. Smart Control and Connectivity:`
    },
    centralControlPanels: {
        AR: `- لوحات تحكم مركزية:`,
        EN: `- Central Control Panels:`
    },
    centralControlPanelsD: {
        AR: `- لوحات تحكم مركزية: يتم دمج جميع عناصر الأثاث التفاعلي والأجهزة في نظام تحكم مركزي يمكن للمعلمين إدارته بسهولة. هذا النظام يسمح للمعلمين بالتحكم في الإضاءة، العرض التقديمي، وإدارة المحتوى التعليمي من مكان واحد.`,
        EN: `- Central Control Panels: All interactive furniture elements and devices are integrated into a central control system that teachers can manage easily. This system allows teachers to control lighting, presentations, and manage educational content from a single location.`
    },
    iotConnectivity: {
        AR: `- اتصال إنترنت الأشياء (IoT):`,
        EN: `- Internet of Things (IoT) Connectivity:`
    },
    innovationsAndGoals: {
        AR: `من خلال هذه الابتكارات في الأثاث التعليمي التفاعلي والذكي، و المزيد من الابتكارات الأخرى، تسعى منصة سيشات إلى تقديم بيئة تعليمية متطورة ومرنة، حيث يمكن للطلاب والمعلمين التفاعل مع التكنولوجيا المتقدمة بطريقة تجعل التعلم أكثر فعالية، إبداعية، وشمولية. هذه الحلول تساعد في تجهيز الطلاب بالمهارات والمعرفة التي يحتاجونها لمواكبة متطلبات المستقبل.`,
        EN: `Through these innovations in interactive and smart educational furniture, and other advancements, the Seshat Platform aims to provide an advanced and flexible educational environment where students and teachers can interact with cutting-edge technology in ways that make learning more effective, creative, and inclusive. These solutions help equip students with the skills and knowledge they need to meet the demands of the future.`
    },
    // Modal s AI
    ai: {
        AR: "اختبار بالذكاء الاصطناعي",
        EN: "Test By AI"
    },
    selectSystem: {
        AR: "اختر النظام",
        EN: "Select System"
    },
    selectLevel: {
        AR: "اختر المستوي",
        EN: "Select Level"
    },
    lesson: {
        AR: "الدرس",
        EN: "Lesson"
    },
    keyWord: {
        AR: "جمل مساعده",
        EN: "Enter Helpful sentences"
    },
    contentAdd: {
        AR: "تضمين محتوي الدرس",
        EN: "Incorporating lesson content"
    },
    Multiplechoice: {
        AR: "اختيار من متعدد",
        EN: "Multiple choice"
    },
    trueAndFalse: {
        AR: "صح وخطا",
        EN: "True Or False",
    },
    writtenQuestion: {
        AR: "نصي",
        EN: "Text"
    },
    summarizeYoutube: {
        AR: "تلخيص من يوتيوب",
        EN: "Summary from YouTube link"
    },
    writeHere: {
        AR: "اكتب الجمل هنا",
        EN: "Enter Here"
    },

    // material


    // Video Sub Translation
    summerVideo: {
        AR: "تلخيص الفيديو",
        EN: "Summary the video",
    },
    includeVideo: {
        AR: "تضمين محتوي الفيديو",
        EN: "Incorporating lesson content",
    },
    showResult: {
        AR: "اظهر النتائج",
        EN: "Show Result",
    },

    // Dashboard Mateirals
    materialCode: {
        AR: "كود المادة",
        EN: "Material Code",
    },
    AddLesson: {
        AR: "اضافة درس",
        EN: "Add Lesson",
    },
    teacherLesson: {
        AR: "معلم الماده",
        EN: "Teacher Lesson"
    },
    edit: {
        AR: "تعديل",
        EN: "Edit"
    },
    del: {
        AR: "حذف",
        EN: "Delete"
    },
    // Lesson Modal
    addLesson: {
        AR: "اضافة درس",
        EN: "Add Lesson"
    },
    addNewLesson: {
        AR: "اضافة درس جديد",
        EN: "Add New Lesson"
    },
    lessonName: {
        AR: "اسم الدرس",
        EN: "Lesson Name"
    },
    uploadPhoto: {
        AR: "رفع الصور",
        RN: "Upload Photo"
    },
    uploadVideo: {
        AR: "رفع الفيديو",
        RN: "Upload video"
    },
    addVideoLink: {
        AR: "اضافة رابط الفيديو",
        EN: "Add Video Link"
    },
    uploadPdf: {
        AR: "اضافة ملف",
        EN: "Upload PDF"
    },
    addLEsonFunc: {
        AR: "اضافة الدرس",
        EN: "Add Lesson"
    },
    // View Leson
    materialLesson: {
        AR: "دروس الماده",
        EN: "Lessons Material"
    },
    showTest: {
        AR: "معاينه",
        EN: "Show Test"
    },
    addTest: {
        AR: "اضافة اختبار",
        EN: "Add Test"
    },
    show: {
        AR: "عرض",
        EN: "Show"
    },
    confirmDelete: {
        AR: "تاكيد الحذف",
        EN: "Confirm Delete"
    },
    confirmDeleteQue: {
        AR: "هل انت متاكد انك تريد حذف الدرس",
        EN: "Do You Want To Delete The Lesson"
    },
    confirm: {
        AR: "تاكيد",
        EN: "Confirm"
    },

    // For Translation who Seshat
    seshatAt: {
        AR: "سيشات",
        EN: "Seshat:"
    },
    ramz: {
        AR: "رمز الحكمة و العلم والكتابة في مصر القديمة",
        EN: " The symbol of wisdom, knowledge, and writing in ancient Egypt"
    },
    tora: {
        AR: "  سيشات، التي تمثل رمزًا للمعرفة والكتابة في الثقافة المصرية القديمة، لعبت دورًا محوريًا في تطور الحضارة المصرية. على مر العصور، كانت سيشات تجسد قيمة العلم والمعرفة، وشغلت موقعًا أساسيًا في الكتابة، التوثيق، والهندسة، مما جعلها رمزًا مركزيًا في الحفاظ على تراث مصر القديمة.",
        EN: "Seshat, who represents a symbol of knowledge and writing in ancient Egyptian culture, played a pivotal role in the development of Egyptian civilization. Throughout the ages, Seshat embodied the value of science and knowledge, occupying a fundamental position in writing, documentation, and engineering, which made her a central symbol in preserving the heritage of ancient Egypt"
    },
    polymr: {
        AR: "سيشات:  رمزا متعددة الأبعاد",
        EN: "Seshat: A multidimensional symbol"
    },
    ses1: {
        AR: ` سيشات، التي يُعتقد أن اسمها يعني "الكتابة" أو "الذي يكتب"، كانت تُصور عادة كامرأة ترتدي ثوبًا نقيًا به نقوش يرمز إلى الحكمة والنقاء، مع تاج مميز يتكون من نجمة سباعية أو زهرة بسبع بتلات فوق عصا. كانت تُمسك بقلم وحافظة بردى، مما يعكس دورها كرمز للكتابة والتوثيق. ما يميز سيشات عن غيرها من الرموز المصرية هو قدرتها على التجسيد في عدة أدوار ووظائف، مما جعلها رمزًا "متعدد الابعاد".`,
        EN: `
        Seshat, whose name is believed to mean "writing" or "the one who writes," was typically depicted as a woman wearing a pure dress adorned with symbols representing wisdom and purity, with a distinctive crown consisting of a seven-pointed star or a flower with seven petals atop a staff. She was holding a pen and a papyrus holder, reflecting her role as a symbol of writing and documentation. What distinguishes the Seshat from other Egyptian symbols is its ability to embody multiple roles and functions, making it a "multidimensional" symbol. 
        `
    },
    ses2: {
        AR: `  سيشات، باعتبارها رمزًا مركزيًا في الثقافة المصرية القديمة، كانت تجسد مجموعة واسعة من العلوم والمعارف والمعاني. عبر العصور، كانت تمثل العديد من الجوانب المختلفة التي تتعلق بالمعرفة والكتابة والهندسة، وفيما يلي أبرز العلوم والمعارف والمعاني التي تجسدها سيشات:`,
        EN: `
        Seshat, as a central symbol in ancient Egyptian culture, embodied a wide range of sciences, knowledge, and meanings. Throughout the ages, she has represented many different aspects related to knowledge, writing, and engineering. Here are the most prominent sciences, knowledge, and meanings embodied by Seshat:
        `
    },

    ses3: {
        AR: ` 1. الكتابة والتوثيق`,
        EN: `1. Writing and documentation `
    },
    ses4: {
        AR: ` - تدوين السجلات: سيشات كانت مسؤولة عن توثيق الأحداث الملكية، مثل الانتصارات العسكرية، الطقوس الدينية، وتسجيل الغنائم. كانت الكتابة تعتبر أداة هامة للحفاظ على تاريخ الأمة المصرية.`,
        EN: `Record keeping: The Seshat was responsible for documenting royal events, such as military victories, religious rituals, and recording spoils. Writing was considered an important tool for preserving the history of the Egyptian nation.- Legal documentation: Seychelles played a role in documenting contracts and agreements, including royal orders and decrees, which helped maintain order and justice in society`,
    },


    ses6: {
        AR: 'التوثيق القانوني: لعبت سيشات دورًا في توثيق العقود والاتفاقيات، بما في ذلك الأوامر الملكية والمرسومات، مما ساعد في الحفاظ على النظام والعدالة في المجتمع.',
        EN: 'Record keeping: The Seshat was responsible for documenting royal events, such as military victories, religious rituals, and recording spoils. Writing was considered an important tool for preserving the history of the Egyptian nation. - Legal documentation: Seshat played a role in documenting contracts and agreements, including royal orders and decrees, which helped maintain order and justice in society.'
    },
    ses7: {
        AR: '2. الهندسة والرياضيات',
        EN: 'Engineering and Mathematics'
    },
    ses8: {
        AR: '- القياسات الهندسية: كانت سيشات مسؤولة عن "مد الحبل"، وهو الطقس الذي يحدد الأسس الهندسية لبناء المعابد والأهرامات. هذا الدور يعكس أهميتها في الرياضيات والهندسة.',
        EN: 'Geometric measurements: The Seshat was responsible for "stretching the cord," a ritual that determines the geometric foundations for building temples and pyramids. This role reflects its importance in mathematics and engineering.'
    },
    ses9: {
        AR: '- الحسابات الفلكية: كانت سيشات تُعتبر المسؤولة عن الحسابات الدقيقة المتعلقة بالنجوم والكواكب، والتي كانت ضرورية لتوجيه المعابد نحو النجوم، مما يربط بين الهندسة والفلك.',
        EN: 'Astronomical Calculations: Seshat was considered responsible for the precise calculations related to stars and planets, which were essential for aligning temples with the stars, thus linking architecture with astronomy.'
    },
    ses10: {
        AR: '3. الحكمة والمعرفة',
        EN: 'Writing and documentation'
    },
    ses11: {
        AR: '- رمز الحكمة: سيشات كانت تجسد الحكمة في شكلها الأسمى. كانت ترتبط بالمعرفة العميقة والفهم الدقيق، مما جعلها رمزًا لكل ما يتعلق بالحكمة في الحياة المصرية.',
        EN: 'Record keeping: The Seshat was responsible for documenting royal events, such as military victories, religious rituals, and recording spoils. Writing was considered an important tool for preserving the history of the Egyptian nation.'
    },
    ses12: {
        AR: '- التعليم والتعلم: تُعتبر سيشات حامية للمعرفة والتعليم، حيث كانت تمثل الدور الحيوي للمعرفة في التطور البشري والحفاظ على التراث.',
        EN: 'Geometric measurements: The Seshat was responsible for "stretching the cord," a ritual that determines the geometric foundations for building temples and pyramids. This role reflects its importance in mathematics and engineering.'
    },
    ses13: {
        AR: '4. الطقوس الدينية',
        EN: 'Religious rituals'
    },
    ses14: {
        AR: '- *المشاركة في الطقوس الدينية: كانت سيشات حاضرة في العديد من الطقوس الدينية، مثل طقوس تأسيس المعابد والاحتفالات الملكية. كانت تُعتبر ضامنة للنظام الكوني من خلال توثيق هذه الطقوس.',
        EN: '*Participation in religious rituals: Seshat was present in many religious rituals, such as the rituals for the founding of temples and royal celebrations.* It was considered a guarantor of the cosmic order by documenting these rituals.'
    },
    timeLine: {
        AR: '5 . *التقويم الزمني',
        EN: 'timeLine'
    },
    contentTimeLine: {
        AR: '- تسجيل الزمن: سيشات كانت تُعتبر المسؤولة عن تسجيل الوقت، بما في ذلك الأعمار الملكية وفترات الحكم. كانت لها دور في حساب وتوثيق مرور الزمن، مما كان مهمًا للحفاظ على تقويم دقيق.',
        EN: 'Star observation: Seshat embodied astronomy through her ability to determine the positions of the stars and use them to guide the temples.'
    },
    ses15: {
        AR: 'العلوم الفلكيه',
        EN: '*Astronomical Sciences'
    },
    ses16: {
        AR: '- رصد النجوم: سيشات كانت تجسد علم الفلك من خلال قدرتها على تحديد مواقع النجوم واستخدامها في توجيه المعابد.',
        EN: 'Star observation: Seshat embodied astronomy through her ability to determine the positions of the stars and use them to guide the temples.'
    },
    ses17: {
        AR: '- الفهم الفلكي: كانت مسؤولة عن الفهم العميق للعلاقات الفلكية بين الأرض والسماء، مما جعلها جزءًا لا يتجزأ من الحسابات الفلكية.',
        EN: 'Seshat was responsible for a deep comprehension of the astronomical relationships between the Earth and the sky, making it an integral part of the astronomical calculations.'
    },
    ses18: {
        AR: '7. العدالة والنظام',
        EN: 'Justice and order'
    },
    ses19: {
        AR: '- حماية النظام والعدالة: سيشات كانت تُعتبر ضامنة للنظام الكوني من خلال التوثيق والكتابة. هذا الدور جعلها حامية للنظام والعدالة في المجتمع المصري.',
        EN: '- Protection of the system and justice: Seshat was considered a guarantor of the cosmic order through documentation and writing.'
    },
    sec20: {
        AR: '8. العمارة والبناء',
        EN: 'Architecture and construction'
    },
    sec21: {
        AR: '- بناء الهياكل الضخمة: لعبت دورًا في بناء الهياكل الضخمة التي تتطلب دقة هندسية كبيرة، مثل الأهرامات والمعابد الكبرى.',
        EN: '- Constructing massive structures: Seshat played a role in building massive structures that required great engineering precision, such as the pyramids and grand temples.'
    },
    ses22: {
        AR: 'التوثيق الملكي',
        EN: 'Royal Documentation'
    },
    ses23: {
        AR: 'تسجيل الإنجازات الملكية: سيشات كانت مسؤولة عن توثيق إنجازات الملوك، بما في ذلك الانتصارات العسكرية، المشاريع الإنشائية، والاحتفالات.',
        EN: 'Recording royal achievements: Seshat was responsible for documenting the achievements of kings, including military victories, construction projects, and celebrations.'
    },
    ses24: {
        AR: `
            - تسجيل الإنجازات الملكية: سيشات كانت مسؤولة عن توثيق إنجازات الملوك، بما في ذلك الانتصارات العسكرية، المشاريع الإنشائية، والاحتفالات. هذا الدور كان جزءًا من الحفاظ على إرث الفراعنة.
        `,
        EN: `
            
            Seshat was responsible for documenting the achievements of kings, including military victories, construction projects, and celebrations. This role was part of preserving the pharaohs' legacy.
Royal History:
She contributed to preserving royal history by documenting key events in the lives of the pharaohs, helping to chronicle their reigns.
            `
    },

    ses25: {
        AR: `
            - التاريخ الملكي: ساهمت في الحفاظ على التاريخ الملكي من خلال تدوين الأحداث الرئيسية في حياة الفراعنة، مما يساعد في تأريخ فترات حكمهم.
        `,
        EN: `
            
            She was considered a protector of literature and the arts, including ancient Egyptian poetry and prose. Her primary role in writing made her a symbol of everything related to literature and culture..
            `
    },

    ses26: {
        AR: ' 10. الأدب والفنون',
        EN: 'Art and Inscriptions',
    },
    ses27: {
        AR: `
          - *رعاية الأدب*: كانت تُعتبر حامية للأدب والفنون، بما في ذلك الشعر والنثر المصري القديم. كانت دورها الأساسي في الكتابة يجعلها رمزًا لكل ما هو مرتبط بالأدب والثقافة.
                                    - الفن والنقوش: تُظهر سيشات في النقوش الفنية المصرية وهي تحمل أدوات الكتابة، مما يعكس علاقتها الوثيقة بالفنون وتوثيق الحياة الثقافية في مصر القديمة.
        `,
        EN: 'Seshat appears in Egyptian artistic inscriptions, carrying writing tools, reflecting her close association with the arts and documenting the cultural life of ancient Egypt.',
    },
    ses28: {
        AR: `سيشات كانت تمثل تجسيدًا للعديد من العلوم والمعارف التي كانت تشكل أساس الحضارة المصرية القديمة. من خلال أدوارها المتعددة، ساهمت في تطوير وتوثيق المعرفة التي كانت حيوية لبقاء وتطور مصر القديمة.`,
        EN: `
        Seshat represented the embodiment of various sciences and knowledge that formed the foundation of ancient Egyptian civilization. Through her numerous roles, she contributed to the development and documentation of knowledge essential to the survival and progress of ancient Egypt.
        `
    },
    ses29: {
        AR: ` أصل سيشات ودورها الرمزي`,
        EN: `The Origin and Symbolic Role of Seshat`
    },
    ses30: {
        AR: `
         يعود أقدم ذكر لسيشات إلى فترة ما قبل الأسرات (حوالي 3000 ق.م.)، حيث كانت تُقدس كرمز للكتابة، المعرفة، والحسابات الدقيقة. في البداية، كانت سيشات تُعتبر حامية للكتابة والأدب، ولكن مع مرور الزمن، توسع دورها ليشمل التسجيل الدقيق للأحداث الملكية والحسابات الهندسية، خاصة في بناء المعابد والأهرامات.
        `,
        EN: `The earliest mention of Seshat dates back to the pre-dynastic period (around 3000 BCE), where she was revered as a symbol of writing, knowledge, and precise calculations. Initially, Seshat was considered the protector of writing and literature, but over time, her role expanded to include the accurate recording of royal events and geometric calculations, especially in the construction of temples and pyramids.`
    },
    sec31: {
        AR: ` المعابد والبرديات التي تظهر فيها سيشات`,
        EN: `Temples and Papyruses Featuring Seshat`,
    },
    ses32: {
        AR: ` سيشات تظهر بشكل واضح في العديد من النقوش والنصوص داخل المعابد المصرية القديمة، حيث كانت تُكرم لدورها في توثيق الأحداث الملكية والدينية. بعض من أبرز الأماكن التي ظهرت فيها سيشات تشمل:`,
        EN: `Seshat appears prominently in numerous inscriptions and texts within ancient Egyptian temples, where she was honored for her role in documenting royal and religious events. Some of the most prominent locations featuring Seshat include`
    },



    ses33: {
        AR: `1. معبد الكرنك`,
        EN: `Karnak Temple`,
    },

    ses34: {
        AR: ` 2. معبد رمسيس الثاني في أبو سمبل`,
        EN: `Ramses II Temple in Abu Simbel`,
    },

    ses35: {
        AR: `
    في معبد الكرنك، الواقع في الأقصر، تُصور سيشات بجانب الفرعون خلال طقوس "مد الحبل"، وهي طقوس هندسية مقدسة تُمارس لتحديد الأسس الصحيحة لبناء المعابد. تظهر سيشات في النقوش وهي تساعد الفرعون في تحديد النقاط الأساسية للمبنى باستخدام الحبال، مما يعكس دورها كضامنة لدقة هذه العمليات الهندسية الهامة.
        `,
        EN: `At the Karnak Temple in Luxor, Seshat is depicted alongside the pharaoh during the "stretching the cord" ritual, a sacred geometric practice performed to establish the correct foundations for building temples. Seshat appears in these inscriptions assisting the pharaoh in determining the essential points of the building using ropes, highlighting her role as a guarantor of the accuracy of these vital engineering processes.`
    },

    ses36: {
        AR: `في معبد رمسيس الثاني بأبو سمبل، يمكن رؤية سيشات في النقوش وهي تسجل الفتوحات العسكرية للفرعون. هذه النقوش تعتبر من الأمثلة البارزة على دور سيشات في توثيق الأحداث الهامة في حياة الملوك المصريين، حيث كانت تُظهر وهي تُحصي الغنائم وتوثق الانتصارات.`,
        EN: `In the Ramses II Temple at Abu Simbel, Seshat can be seen in the inscriptions recording the pharaoh's military conquests. These inscriptions are notable examples of Seshat's role in documenting significant events in the lives of Egyptian kings, where she is shown counting spoils and recording victories.`
    },

    ses37: {
        AR: `3. معبد حتشبسوت في الدير البحري`,
        EN: `Hatshepsut Temple at Deir el-Bahari`
    },
    ses38: {
        AR: ` في معبد الملكة حتشبسوت في الدير البحري، تظهر سيشات في النقوش كجزء من الطقوس الدينية والبنائية، مما يعكس دورها في تأكيد مشروعية وقوة حكم حتشبسوت. كانت سيشات تُعتبر رمزًا لتأكيد النظام والحقيقة من خلال الكتابة والتوثيق، وهو ما يتجلى في النقوش التفصيلية في هذا المعبد.`,
        EN: `In Queen Hatshepsut's temple at Deir el-Bahari, Seshat appears in the inscriptions as part of religious and construction rituals, reflecting her role in affirming the legitimacy and strength of Hatshepsut's reign. Seshat was considered a symbol of affirming order and truth through writing and documentation, as demonstrated in the detailed inscriptions in this temple`
    },
    ses39: {
        AR: ` 4. برديات شهيرة تظهر فيها سيشات`,
        EN: `Famous Papyruses Featuring Seshat`
    },
    ses40: {
        AR: ` تظهر سيشات أيضًا في عدد من البرديات الهامة التي تعود إلى مصر القديمة. من بين هذه البرديات:`,
        EN: `Seshat also appears in several important papyri from ancient Egypt, including:`
    },
    ses41: {
        AR: `- بردية حكايات بتاح حتب: تقدم مجموعة من الحكم والنصائح حول أهمية الكتابة والمعرفة. يعود تاريخ هذه البردية إلى عصر الدولة القديمة، وتعكس الفكر المصري القديم حول قيمة التعليم والتوثيق، ويُعتقد أن تأثير سيشات يظهر بوضوح في النصوص التي تركز على أهمية المعرفة والكتابة.`,
        EN: `This document offers a collection of wisdom and advice on the importance of writing and knowledge. Dating back to the Old Kingdom, this papyrus reflects the ancient Egyptian view on the value of education and documentation, with Seshat's influence visible in the texts emphasizing the importance of knowledge and writing.`
    },

    ses42: {
        AR: `1. موسوعة التاريخ القديم (Ancient History Encyclopedia): تقدم موسوعة التاريخ القديم تحليلاً شاملاً لدور سيشات في مصر القديمة، مع التركيز على دورها في الكتابة والمعرفة. يمكن الاطلاع على مقال حول سيشات عبر الرابط: [Ancient History Encyclopedia - Seshat](https://www.ancient.eu/Seshat/).`,
        EN: `1. Ancient History Encyclopedia: The Ancient History Encyclopedia provides a comprehensive analysis of Seshat's role in ancient Egypt, focusing on her role in writing and knowledge. You can read an article about Seshat at: [Ancient History Encyclopedia - Seshat](https://www.ancient.eu/Seshat/).`
    },
    ses43: {
        AR: `2. المتحف البريطاني (British Museum): يحتوي المتحف البريطاني على عدة نقوش وأعمال فنية تصور سيشات في سياقات مختلفة. تعتبر هذه الأعمال مصدرًا رئيسيًا لفهم كيف كانت سيشات تُقدس وتصور في مصر القديمة. يمكن البحث عن مقتنيات سيشات على الموقع الرسمي للمتحف: [British Museum - Seshat](https://www.britishmuseum.org/).`,
        EN: `2. British Museum: The British Museum houses several inscriptions and artworks depicting Seshat in various contexts. These works are a primary source for understanding how Seshat was revered and portrayed in ancient Egypt. You can search for Seshat’s artifacts on the museum's official website: [British Museum - Seshat](https://www.britishmuseum.org/).`
    },
    ses44: {
        AR: `3. بردية حكايات بتاح حتب وبردية كتاب الموتى: يمكن العثور على معلومات حول هذه البرديات في العديد من المراجع الأكاديمية والمكتبات الرقمية مثل [Google Scholar](https://scholar.google.com) و[Internet Archive](https://archive.org).`,
        EN: `3. The Teachings of Ptahhotep Papyrus and the Book of the Dead: Information about these papyri can be found in numerous academic references and digital libraries like [Google Scholar](https://scholar.google.com) and [Internet Archive](https://archive.org).`
    },
    ses45: {
        AR: `خاتمة`,
        EN: `Conclusion`
    },
    ses46: {
        AR: `سيشات ليست مجرد رمز منسي في صفحات التاريخ، بل هي تجسيد حي لفكرة أن المعرفة والكتابة هما عماد الحضارة. كانت سيشات، وما زالت، تمثل القوة التي تحفظ وتوثق الإنجازات البشرية، وتضمن انتقال المعرفة من جيل إلى جيل. في عالمنا الحديث، تظل سيشات رمزًا مستدامًا للعلم والمعرفة، مما يجعلها جزءًا لا يتجزأ من التراث الثقافي العالمي.`,
        EN: `Seshat is not merely a forgotten symbol in the pages of history, but a living embodiment of the idea that knowledge and writing are the foundation of civilization. Seshat, both then and now, represents the force that preserves and documents human achievements, ensuring that knowledge is passed from one generation to the next. In our modern world, Seshat remains a lasting symbol of science and knowledge, making her an integral part of the global cultural heritage.`
    },
    ses47: {
        AR: `مصادر لتعرف أكثر :`,
        EN: `Sources to Learn More:`
    },
    ses48: {
        AR: `صور لسيشات :`,
        EN: `Images of Seshat:`
    }

};