import React from 'react'
import PostCard from './postCard'
import { Container } from 'react-bootstrap'
import { HomePage } from '../../utils/Translation/HomePage'
import { useSelector } from 'react-redux';

function Posts({posts}) {

  //  console.log('posts',posts)
  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
});
    return (
    <Container>
        {posts&& posts.length? posts.map((p,i)=><PostCard key={i} post={p}/>)
        :
        <h2 className='text-center font'>{HomePage.noPosts[lang]}</h2>
        }
    </Container>
  )
}

export default Posts