import React, { useContext } from 'react'
import PageContainer from '../layout/PagecContainer'
import SharedImg from '../shared/modules/SharedImg'
import about from "../../assets/aboutUs.png"
import SharedStatic from './modules/SharedStatic'
import { useSelector } from 'react-redux'
import { ThemeContext } from '../../ThemeProvider';
import { HomePage } from '../../utils/Translation/HomePage'
const About = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    
    const { theme } = useContext(ThemeContext);
    return (
        <div className='font'>
            <PageContainer>
                <main id="contact-us"  >
                    <SharedImg img={about} />
                    <div className='container' dir={dir}>
                        <div className='infoContact mx-3'>
                            <div className='mx-2'>{HomePage.whoAreWe[lang]}</div>
                        </div>
                        <p  className={`sharedColor mx-4 mt-5 ${theme === 'light' ? 'infofordetails' : "text-light"} `}>
                            <p className='fs-5'>
                                {HomePage.seshatIntro[lang]}
                            </p>

                            <p className='mt-5 fs-6'>
                                {HomePage.ourVision[lang]}
                                <br />
                                {HomePage.visionContent[lang]}
                            </p>
                            <p className='mt-5 fs-6'>
                                {HomePage.ourMission[lang]}
                                <br />
                                {HomePage.missionContent[lang]}
                            </p>
                            <p className='mt-5 fs-6'>
                                {HomePage.seshatFuture[lang]}
                            </p>
                            <p className='mt-5 fs-6'>
                                {HomePage.globalVision[lang]}
                                <br />
                                {HomePage.globalVisionContent[lang]}                            </p>
                            <p className='mt-5 fs-6'>
                                {HomePage.ourGoals[lang]}
                                <br />
                                {HomePage.goalsContent[lang]}
                            </p>

                        </p>
                    </div>


                    <SharedStatic hint="about" />

                </main>
            </PageContainer>
        </div>
    )
}

export default About
