import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AddMaterial from './addMaterial';
import { Update_Material, Delete_Material } from '../../../actions/curriculums/materials';
import { Table, Button, Modal, NavLink } from 'react-bootstrap';
import { Link, Outlet } from 'react-router-dom';
import AddLesson from '../lessons/addLesson';
import ViewLessons from '../lessons/viewLessons'
import AddTestBtn from '../tests/AddTestBtn';
import EditMaterial from './editMaterial';
import { success } from '../../../components/shared/modules/Notifications';
import { HomePage } from '../../../utils/Translation/HomePage';
function Materials() {
  const materials = useSelector(state => {
    const user = state?.loggedUser?.user;
    // console.log('user',user)
    if (user?.role === "Admin") {
      return state.materials
    }
    else if (user?.role === 'teacher') {
      return state.materials.filter(m => m?.teacherId?._id === user?._id)
    }
  })

  const { user } = useSelector(state => state.loggedUser);
  const dispatch = useDispatch();
  const [deleteMaterialId, setDeleteMaterialId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteConfirmation = (materialId) => {
    setDeleteMaterialId(materialId);
    setShowDeleteModal(true);
  };

  const handleDeleteMaterial = async () => {
    // Dispatch the delete material action
    const res = await dispatch(Delete_Material({ _id: deleteMaterialId }));
    if (res?.data?.case) {
      success(res?.data?.message);
      handleCloseDeleteModal();
    }
    // Reset delete-related states
    setDeleteMaterialId(null);
    setShowDeleteModal(false);
  };

  const handleCloseDeleteModal = () => {
    // Reset delete-related states
    setDeleteMaterialId(null);
    setShowDeleteModal(false);
  };

  const { lang, dir } = useSelector(({ language }) => {
    return { lang: language.lang, dir: language.dir };
  });

  return (
    <div>
      {
        user.role === 'Admin' ?
          <AddMaterial />
          : <></>
      }

      <Table responsive className='container mt-5 font fs-6' dir={dir}>
        <thead>
          <tr style={{ color: "#120C54", textAlign: "center" }}>
            <th style={{ color: "#120C54" }}>{HomePage.material[lang]}</th>
            <th style={{ color: "#120C54" }}>{HomePage.materialCode[lang]}</th>
            <th style={{ color: "#120C54" }}>{HomePage.AddLesson[lang]}</th>
            <th style={{ color: "#120C54" }}>{HomePage.lesson[lang]}</th>
            {/* <th>اضافة اختبار</th> */}
            <th style={{ color: "#120C54" }}>{HomePage.teacherLesson[lang]}</th>
            <th style={{ color: "#120C54" }}>{HomePage.edit[lang]}</th>
            <th style={{ color: "#120C54" }}>{HomePage.del[lang]}</th>
          </tr>
        </thead>
        <tbody >
          {materials.map(material => (
            <tr key={material._id} style={{ textAlign: "center" }}>
              <td>{material.name}</td>
              <td>{material.code}</td>
              <td>
                <AddLesson materialCode={material.code} />
              </td> 
              <td><ViewLessons materialCode={material.code} materialData={material} /></td>
              {/* <td>
              <AddTestBtn 
                type={'material'}
                testFor={material._id}
                />
              </td> */}
              <td>
                {material.teacherId ? material?.teacherId?.name : "لايوجد"}
              </td>
              <td>
                {/* Add your edit functionality or button here */}
                {/* Example: <Button variant="primary" onClick={() => handleEditMaterial(material.id)}>Edit</Button> */}
                <EditMaterial material={material} />
              </td>
              <td>
                <button className=' btn' style={{ backgroundColor: "#E24144", color: "white" }} onClick={() => handleDeleteConfirmation(material._id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered className='font' style={{ color: "black" }}>
        <Modal.Header>
          <Modal.Title>Delete Material</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this material?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteMaterial}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Materials;

// import React, { useState, useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import AddMaterial from './addMaterial';
// import { Update_Material, Delete_Material, Get_Materials } from '../../../actions/curriculums/materials';
// import { Table, Button, Modal } from 'react-bootstrap';
// import AddLesson from '../lessons/addLesson';
// import ViewLessons from '../lessons/viewLessons';
// import EditMaterial from './editMaterial';
// import { success } from '../../../components/shared/modules/Notifications';

// function Materials() {
//   const dispatch = useDispatch();
//   const { user } = useSelector(state => state.loggedUser);

//   // Copy materials to local state
//   const initialMaterials = useSelector(state => {
//     const user = state?.loggedUser?.user;
//     if (user?.role === "Admin") {
//       return state.materials;
//     } else if (user?.role === 'teacher') {
//       return state.materials.filter(m => m?.teacherId?._id === user?._id);
//     }
//     return [];
//   });

//   const [materials, setMaterials] = useState(initialMaterials);
//   const [deleteMaterialId, setDeleteMaterialId] = useState(null);
//   const [showDeleteModal, setShowDeleteModal] = useState(false);

//   useEffect(() => {
//     // Sync the materials from Redux store to local state whenever it changes
//     setMaterials(initialMaterials);
//   }, [initialMaterials]);

//   const handleDeleteConfirmation = (materialId) => {
//     setDeleteMaterialId(materialId);
//     setShowDeleteModal(true);
//   };

//   const handleDeleteMaterial = async () => {
//     // Dispatch the delete material action
//     await dispatch(Delete_Material({ _id: deleteMaterialId }));

//     // Remove the deleted material from the UI by filtering it out from the local state
//     setMaterials(materials.filter(material => material._id !== deleteMaterialId));
//     handleCloseDeleteModal();
//   };

//   const handleCloseDeleteModal = () => {
//     setDeleteMaterialId(null);
//     setShowDeleteModal(false);
//   };

//   // console.log(materials)

//   return (
//     <div>
//       {user.role === 'Admin' && <AddMaterial />}

//       <Table responsive className='container mt-5 font fs-6'>
//         <thead>
//           <tr style={{ color: "#120C54", textAlign: "center" }}>
//             <th style={{ color: "#120C54" }}>المادة</th>
//             <th style={{ color: "#120C54" }}>كود المادة</th>
//             <th style={{ color: "#120C54" }}>اضافة درس</th>
//             <th style={{ color: "#120C54" }}>الدروس</th>
//             <th style={{ color: "#120C54" }}>معلم المادة</th>
//             <th style={{ color: "#120C54" }}>تعديل</th>
//             <th style={{ color: "#120C54" }}>حذف</th>
//           </tr>
//         </thead>
//         <tbody>
//           {materials.map(material => (
//             <tr key={material._id} style={{ textAlign: "center" }}>
//               <td>{material.name}</td>
//               <td>{material.code}</td>
//               <td><AddLesson materialCode={material.code} /></td>
//               <td><ViewLessons materialCode={material.code} /></td>
//               <td>{material.teacherId ? material?.teacherId?.name : "لايوجد"}</td>
//               <td><EditMaterial material={material} /></td>
//               <td>
//                 <button className='btn' style={{ backgroundColor: "#E24144", color: "white" }} onClick={() => handleDeleteConfirmation(material._id)}>
//                   Delete
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>

//       {/* Delete Confirmation Modal */}
//       <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered className='font' style={{ color: "black" }}>
//         <Modal.Header>
//           <Modal.Title>Delete Material</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>Are you sure you want to delete this material?</Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseDeleteModal}>Cancel</Button>
//           <Button variant="danger" onClick={handleDeleteMaterial}>Delete</Button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// }

// export default Materials;
