import React from 'react'
import dashboard from "../../../assets/dashboard.png"


import SideNav from '../modules/SideNav'
import { useSelector } from 'react-redux'
import { urlBase } from '../../../utils/API'
import ChangeImgProfile from './ChangeImgProfile'
import coverImage from "../../../assets/aboutUs.png"
const HeaderDashboard = () => {
    const loggedUser = useSelector((state) => state.loggedUser);
    return (
        <div className=' font ' >

            <div className='m-auto' style={{width:"82%",color:"black"}}>
                <div>
                    {/* <img src={loggedUser?.user?.coverImage ? `${urlBase}/${loggedUser?.user?.coverImage}` : ""} alt='dashboardImage' className='w-100 ' style={{ height: "25vh", zIndex:"-1"}} /> */}
                    <img src={coverImage} alt='dashboardImage' className='w-100 '  />
                    {/* <div className='handleCamera'>
                        <ChangeImgProfile type={"coverImage"} />
                    </div> */}
                </div>
            </div>

            <div style={{ textAlign: "right" , marginTop:"45px"}}>
                {/* <img src={imageProfile} alt="imageProfile" style={{ width: "80px", height: "80px", borderRadius: "50%" }} /> */}
                {
                    <div className='d-flex justify-content-center align-items-center w-25' style={{ marginLeft: "auto", paddingRight: "53px" }}>
                        <div style={{ cursor: "pointer", fontSize: "20px" , color:"black" }} >
                            <p style={{ fontWeight: "600", fontSize: "20px" }}>{loggedUser?.user?.username}</p>
                        </div>
                        <div className='mx-3'>
                            <img src={loggedUser?.user?.personalImage ? `${urlBase}/${loggedUser?.user?.personalImage}` : ""} alt="imageProfile" style={{ width: "80px", height: "80px", borderRadius: "5px" }} />
                            <div style={{marginTop:"-20px"}}>
                                <ChangeImgProfile color="black" type={"personalImage"} />
                            </div>
                        </div>

                    </div>
                }
            </div>

            <div style={{marginTop:"-50px"}}>
                <SideNav user={loggedUser} />
            </div>
        </div>
    )
}

export default HeaderDashboard
