import React, { useContext } from 'react';
import platformOne from "../../assets/platform1.png";
import platformTwo from "../../assets/images/platform2.png";
import platformThree from "../../assets/platform3.png";
import platformFour from "../../assets/platform4.png";
import { useSelector } from 'react-redux';
import { HomePage } from '../../utils/Translation/HomePage';
import { ThemeContext } from '../../ThemeProvider';

const OurPlatform = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const { theme } = useContext(ThemeContext);

    return (
        <div dir={dir} className='ourPlatform'>
            <div style={{ textAlign: "center", marginTop: "50px" }}>
                <h4 style={{ marginBottom: "100px", color: theme === "dark" ? "white" : "black" }}>{HomePage.titlePlatform[lang]}</h4>
            </div>
            <div className='px-5' dir={dir}>
                <div className='platformGrid' style={{ marginTop: "30px" }}>
                    <div className='platformItem'>
                        <div className='w-50 m-auto text-center'>
                            <img src={platformOne} alt="imagePlatform" className='mb-4 handleImage' />
                        </div>
                        <div className='text-center m-auto px-2'>
                            <p className={`  fs-4`} style={{ color: theme === "dark" ? "white" : "black" }}>{HomePage.performance[lang]}</p>
                            <p className={`handleText text-center`} style={{ color: theme === "dark" ? "white" : "black" }}>{HomePage.performance_content[lang]}</p>
                        </div>
                    </div>
                    <div className='platformItem'>
                        <div className='w-50 m-auto text-center'>
                            <img src={platformTwo} alt="imagePlatform" className='mb-4 handleImage' />
                        </div>
                        <div className='text-center m-auto px-2'>
                            <p className={`  fs-4`} style={{ color: theme === "dark" ? "white" : "black" }}>{HomePage.maintaince[lang]}</p>
                            <p className={`handleText text-center`} style={{ color: theme === "dark" ? "white" : "black" }}>{HomePage.maintaince_content[lang]}</p>
                        </div>
                    </div>
                    <div className='platformItem'>
                        <div className='w-50 m-auto text-center'>
                            <img src={platformThree} alt="imagePlatform" className='mb-4 handleImage' />
                        </div>
                        <div className='text-center m-auto px-2'>
                            <p className={`  fs-4`} style={{ color: theme === "dark" ? "white" : "black" }}>{HomePage.interactive[lang]}</p>
                            <p className={`handleText text-center`} style={{ color: theme === "dark" ? "white" : "black" }}>{HomePage.interactive_content[lang]}</p>
                        </div>
                    </div>
                    <div className='platformItem'>
                        <div className='w-50 m-auto text-center'>
                            <img src={platformFour} alt="imagePlatform" className='mb-4 handleImage' />
                        </div>
                        <div className='text-center m-auto px-2'>
                            <p className={`  fs-4`} style={{ color: theme === "dark" ? "white" : "black" }}>{HomePage.teacher_prof[lang]}</p>
                            <p className={`handleText text-center`} style={{ color: theme === "dark" ? "white" : "black" }}>{HomePage.teacher_prof_content[lang]}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurPlatform;
