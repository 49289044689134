import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Add_Class_SuperVisor } from '../../../actions/class';
import AddSupervisor from './addSupervisor';

function AddSupervisorModal({ Class,toast }) {
  const [show, setShow] = useState(false);
  const [selectedSupervisor, setSelectedSupervisor] = useState(null);
  const { supervisors } = useSelector(({ users }) => {
    return {
      supervisors: users.supervisors
    };
  });

  const dispatch = useDispatch();
  // console.log(toast())
// toast().success('hi') 
  const handleConfirm =async () => {
    try{ 
    if (selectedSupervisor) {
      const res = await dispatch(Add_Class_SuperVisor({ id: Class._id, supervisorID: selectedSupervisor }))
      //  console.log(res)
      setShow(false);
      // console.log(toast())
      if (res.data.case) {
        // console.log('success')
        // Success notification
        toast().success(res.data.message, {
            position: "top-center",
            
        });
    } else {
        // Error notification
        // console.log('unsuccess')

        toast().error(res.data.message, {
            position: "top-center"
        });
    }
    }
  }catch(err){
    console.warn(err.message);
    toast().error(err.message, {
      position: "top-center"
  })
  } finally {
    // Close the modal
    // setShow(false);
}
}

  return (
    <div>

      <p className={'btn sharedButton'} onClick={() => setShow(true)}>اضافة مشرف</p>
      <AddSupervisor show={show} setShow={setShow} Class={Class} toast={toast}/>

    </div>
  );
}

export default AddSupervisorModal;
