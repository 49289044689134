import React, { useContext } from 'react'
import PageContainer from '../layout/PagecContainer'
import SharedImg from '../shared/modules/SharedImg'
import about from "../../assets/aboutUs.png"
import SharedStatic from './modules/SharedStatic'
import { useSelector } from 'react-redux'
import { HomePage } from '../../utils/Translation/HomePage'
import { ThemeContext } from '../../ThemeProvider'
const Academy = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const { theme } = useContext(ThemeContext);
    return (
        <div className='font'>
            <PageContainer>
                <main id="contact-us" dir='rtl'>
                    <SharedImg img={about} />
                    <div className='container' dir={dir}>
                        <div className='infoContact mx-3'>
                            <div className=''>{HomePage.schoolsAndAcademies[lang]}</div>
                        </div>
                        <p  className={`sharedColor mx-4 mt-5  ${theme === 'light' ? 'infofordetails' : "text-light"} `}>
                            <p className='fs-5'>
                                {HomePage.seshatPlatform[lang]}
                            </p>
                            <p className='fs-6'>

                                {HomePage.collaboration[lang]}
                            </p>

                            <p className='mt-5 fs-6'>
                                {HomePage.curriculumDesign[lang]}
                                <br />
                                {HomePage.smartBuildings[lang]}
                            </p>
                            <p className='mt-5 fs-6'>
                                2. تجهيز الأبنية التعليمية الذكية:
                                <br />
                                {HomePage.teacherTraining[lang]}
                            </p>

                            <p className='mt-5 fs-6'>
                                {HomePage.trainingTeachers[lang]}
                                <br />
                                {HomePage.to[lang]}
                            </p>
                            <p className='mt-5 fs-6'>
                                {HomePage.anotherSys[lang]}
                                <br />
                                {HomePage.educationManagementSystems[lang]}
                            </p>
                            <p className='mt-5 fs-6'>
                                {HomePage.experienceExchangeTitle[lang]}
                                <br />
                                {HomePage.experienceExchange[lang]}
                            </p>

                        </p>
                    </div>


                    <SharedStatic hint="about" />

                </main>
            </PageContainer>
        </div>
    )
}

export default Academy
