



import React, { useContext } from 'react';
import PageContainer from '../layout/PagecContainer';
import SharedImg from '../shared/modules/SharedImg';
import SharedStatic from './modules/SharedStatic';
import about from "../../assets/aboutUs.png";
import { HomePage } from '../../utils/Translation/HomePage';
import { useSelector } from 'react-redux';
import { ThemeContext } from '../../ThemeProvider';

const CommponQue = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });


    const { theme } = useContext(ThemeContext);

    const textAlign = lang === 'EN' ? 'left' : 'right';

    return (
        <div className='font'>
            <PageContainer>
                <main id="contact-us">
                    <SharedImg img={about} />
                    <div className={`container `} dir={dir}>
                        <div className='infoContact'>
                            <div className='mx-4'>{HomePage.Asked[lang]}</div>
                        </div>
                        <div className={`sharedColor mx-4  ${theme === 'light' ? 'infofordetails' : "text-light"} `}>
                            <div style={{ textAlign }}>
                                <h6>{HomePage.whoSeshat[lang]}</h6>
                                <p>
                                    {HomePage.whoSeshatContent[lang]}
                                </p>
                            </div>

                            <div style={{ textAlign, marginTop: "50px" }}>
                                <h6>{HomePage.registerToPlatform[lang]}</h6>
                                <p>
                                    {HomePage.registerToPlatformContent[lang]}
                                </p>
                            </div>

                            <div style={{ textAlign, marginTop: "50px" }}>
                                <h6>{HomePage.foundSub[lang]}</h6>
                                <p>
                                    {HomePage.foundSubContent[lang]}
                                </p>
                            </div>
                            <div style={{ textAlign, marginTop: "50px" }}>
                                <h6>{HomePage.howReach[lang]}</h6>
                                <p>
                                    {HomePage.howReachContent[lang]}
                                </p>
                            </div>
                            <div style={{ textAlign, marginTop: "50px" }}>
                                <h6>{HomePage.interActiveTeach[lang]}</h6>
                                <p>
                                    {HomePage.interActiveTeachContent[lang]}
                                </p>
                            </div>
                            <div style={{ textAlign, marginTop: "50px" }}>
                                <h6>{HomePage.rateTeach[lang]}</h6>
                                <p>
                                    {HomePage.rateTeachContent[lang]}
                                </p>
                            </div>
                            <div style={{ textAlign, marginTop: "50px" }}>
                                <h6>{HomePage.multDevices[lang]}</h6>
                                <p>
                                    {HomePage.multDevicesContent[lang]}
                                </p>
                            </div>

                            <div style={{ textAlign, marginTop: "50px" }}>
                                <h6>{HomePage.faceProplem[lang]}</h6>
                                <p>
                                    {HomePage.faceProplemContent[lang]}
                                </p>
                            </div>
                            <div style={{ textAlign, marginTop: "50px" }}>
                                <h6>{HomePage.updateContent[lang]}</h6>
                                <p>
                                    {HomePage.updateContentData[lang]}
                                </p>
                            </div>
                            <div style={{ textAlign, marginTop: "50px" }}>
                                <h6>{HomePage.shareWithTogether[lang]}</h6>
                                <p>
                                    {HomePage.shareWithTogetherContent[lang]}
                                </p>
                            </div>
                            <div style={{ textAlign, marginTop: "50px" }}>
                                <h6>{HomePage.updateInfo[lang]}</h6>
                                <p>
                                    {HomePage.updateInfoContent[lang]}
                                </p>
                            </div>
                        </div>
                    </div>

                    <SharedStatic hint="common" />

                </main>
            </PageContainer>
        </div>
    );
};

export default CommponQue;

