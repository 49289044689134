

import React, { useEffect, useState } from 'react';
import { getLevelById, getUserByID } from '../../utils/API';

const GetUser = ({ studentId }) => {
    const [studentName, setStudentName] = useState('');

    useEffect(() => {
        const fetchStudenName = async () => {
            const std = await getUserByID({ userID: studentId });
            if(std?.data?.case){
                setStudentName(std?.data?.user?.name)
            }
        };

        if (studentId) {
            fetchStudenName();
        }
    }, [studentId]);

    return (
        <span>{studentName}</span>
    );
};

export default GetUser;