import React, { useContext } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { ThemeContext } from '../../../ThemeProvider';
import { HomePage } from '../../../utils/Translation/HomePage';

const SharedStatic = ({ hint }) => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const { theme } = useContext(ThemeContext);
    return (
        <div className='font sharedStatic'>
            <div className={`sharedColor mx-4 mt-5 ${theme === 'light' ? 'infofordetails' : "text-light"} `} style={{ textAlign:`${dir === "rtl"? 'right':'left'}` }}>
                {
                    hint === "support" ? (<div>
                        <p>
                            <Link style={{ textDecoration: "none", color: theme === "light"?'black':'white' , paddingRight:"120px" }} to="/about-us">{HomePage.sharedTwo[lang]}</Link>
                        </p>
                        <p> <Link style={{ textDecoration: "none", color: theme === "light"?'black':'white' , paddingRight:"120px" }} to="/faq">{HomePage.commonQueuestions[lang]}</Link></p>
                        <p> <Link style={{ textDecoration: "none", color: theme === "light"?'black':'white' , paddingRight:"120px" }} to="/contact-us">{HomePage.Contact_us[lang]}</Link></p>
                    </div>) : null
                }
                {
                    hint === "common" ? (<div>
                        <p>  <Link style={{ textDecoration: "none", color: theme === "light"?'black':'white' , paddingRight:"120px" }} to="/about-us">{HomePage.sharedTwo[lang]}</Link></p>
                        <p><Link style={{ textDecoration: "none", color: theme === "light"?'black':'white' , paddingRight:"120px" }} to="/support">{HomePage.sharedThree[lang]}</Link></p>
                        <p><Link style={{ textDecoration: "none", color: theme === "light"?'black':'white' , paddingRight:"120px" }} to="/contact-us">{HomePage.Contact_us[lang]}</Link></p>
                    </div>) : null
                }
                {
                    hint === "contact" ? (<div>
                        <p> <Link style={{ textDecoration: "none", color: theme === "light"?'black':'white' , paddingRight:"120px" }} to="/about-us">{HomePage.sharedTwo[lang]}</Link></p>
                        <p> <Link style={{ textDecoration: "none", color: theme === "light"?'black':'white' , paddingRight:"120px" }} to="/support">{HomePage.sharedThree[lang]}</Link></p>
                        <p> <Link style={{ textDecoration: "none", color: theme === "light"?'black':'white' , paddingRight:"120px" }} to="/contact-us">{HomePage.Contact_us[lang]}</Link></p>
                    </div>) : null
                }
                {
                    hint === "about" ? (<div>
                        <p> <Link style={{ textDecoration: "none", color: theme === "light"?'black':'white' , paddingRight:"120px" }} to="/faq">{HomePage.commonQueuestions[lang]}</Link></p>
                        <p><Link style={{ textDecoration: "none", color: theme === "light"?'black':'white' , paddingRight:"120px" }} to="/support">{HomePage.sharedThree[lang]}</Link></p>
                        <p><Link style={{ textDecoration: "none", color: theme === "light"?'black':'white' , paddingRight:"120px" }} to="/contact-us">{HomePage.Contact_us[lang]}</Link></p>
                    </div>) : null
                }

            </div>
        </div>
    )
}

export default SharedStatic
