import React, { useEffect, useState } from 'react';
import EmptyComponent from './EmptyComponent';
import "../../../styles/dashboard.css";
import SubscripeMaterials from './SubscripeMaterials';
import { useDispatch, useSelector } from 'react-redux';
import { programs_per_user } from '../../../actions/login&register';
import { HomePage } from '../../../utils/Translation/HomePage';
import GetUser from '../../shared/GetUser';

const Subject = () => {
    const dispatch = useDispatch();
    const loggedUser = useSelector((state) => state.loggedUser);

    const [child, setChild] = useState(null);

    const [loading, setLoading] = useState(true); // Add loading state

    const fetchPrograms = async () => {
        setLoading(true); // Set loading to true before fetching
        if (loggedUser?.user?.role === "student") {
            await dispatch(programs_per_user({
                userID: loggedUser?.user?._id
            }));
        }
        setLoading(false); // Set loading to false after fetching
    };


    const fetch_subscriptions_Parent = async () => {
        try {
            if (loggedUser?.user?.role === "parent") {
                if (child !== null) {
                    await dispatch(programs_per_user({
                        userID: child
                    }));
                }
            }
        } catch (e) {
            console.log(e)
        }
    }


    useEffect(() => {
        fetch_subscriptions_Parent();
    }, [child])


    useEffect(() => {
        fetchPrograms();
    }, []);

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <div className='font'>
            <div className='sharedBackground' style={{ width: "85%" }}>
                <p>{HomePage.dashboard_programs[lang]}</p>
            </div>

            {
                loggedUser?.user?.role === "parent" ? (
                    <select className='form-control text-center m-auto w-25' value={child} onChange={(e) => setChild(e.target.value)}>
                        <option hidden>قم باختيار طالب منتسب اليك</option>
                        {
                            loggedUser?.user?.childId?.map((child) => {
                                return (
                                    <option key={child} value={child}>
                                        <GetUser studentId={child} />
                                    </option>
                                )
                            })
                        }

                    </select>
                ) : (null)
            }

            {loading ? (
                <div className="spinner-container d-flex justify-content-center align-items-center">
                    <span className='loader'></span>
                </div>
            ) : (
                loggedUser?.programs && loggedUser?.programs?.length > 0 ? (
                    <div className='container'>
                        <SubscripeMaterials programs={loggedUser?.programs} />
                    </div>
                ) : (
                    <div className='emptyComponent'>
                        <EmptyComponent showFooter={true} title={"انت غير مشترك في برامج دراسيه بعد"} />
                    </div>
                )
            )}
        </div>
    );
};

export default Subject;
