import React from 'react'
import store from '../../assets/store.png'
import { HomePage } from '../../utils/Translation/HomePage'
import { useSelector } from 'react-redux';
const SeshatStore = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div>
            <div className='join background py-4 seshatStore joinData' dir={dir}>
                <div className='container'>
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-lg-6 col-sm-12 col-md-6 handleJoin' style={{ textAlign: `center` }}>
                            <h1>{HomePage.seshat[lang]}</h1>
                            <p className='mt-5 fs-6'>
                                {HomePage.seshatContent[lang]}
                            </p>
                        </div>
                        <div className='col-lg-6  col-sm-12 col-md-6' style={{ textAlign: "center" }}>
                            <img src={store} alt="imageJoin" className='imgJoin w-75' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SeshatStore
