import React, { useEffect, useState } from 'react';
// import { Document, Page } from 'react-pdf';
import { useSelector } from 'react-redux';
import { getContentUrls } from '../../../utils/lessons';
import { Container, Button } from 'react-bootstrap';
import { FaStar } from "react-icons/fa6";

import './pdf.css'
import "./view.css"
import { urlBase } from '../../../utils/API';
import ReactPlayer from "react-player"
import { AiOutlineLike } from "react-icons/ai";
import { FaCommentAlt } from "react-icons/fa";
import VideoSub from '../../../components/modals/VideoSub';
import AiModal from '../../../components/modals/AiModal';
import { useLocation } from 'react-router-dom';

// import PdfViewer from './pdfViewer';
const MultimediaViewer = () => {



  const [pdfNumPages, setPdfNumPages] = useState(null);
  const [pdfPageNumber, setPdfPageNumber] = useState(1);
  const [content, setContent] = useState([]);

  const { lesson } = useSelector(({ view }) => {
    return { lesson: view?.lesson };
  });

  const getURLS = async () => {
    try {
      const res = await getContentUrls(lesson.content);
      setContent(res.data.newContent);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (lesson && lesson.content.length) {
      getURLS();
    }
  }, [lesson]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPdfNumPages(numPages);
  };

  const preventContextMenu = (e) => {
    e.preventDefault();
  };
  const location = useLocation();
  const material = location.state?.materialData; // استخدم البيانات المرسلة عبر navigate
  console.log('lesson', material)
  return (
    <Container className='view-area-cont' onContextMenu={preventContextMenu}>
      <h2>{lesson?.name}</h2>

      {lesson?.content ? (
        <>
          {lesson?.content?.map((c, i) => {
            if (c.type.split('/').includes('image')) {
              return (
                <div key={i} onContextMenu={preventContextMenu}>
                  <hr />
                  <img
                    src={`${urlBase}/${c.name}`}
                    alt="lesson cover"
                    style={{ width: '100%', height: 'auto' }}
                  />
                </div>
              );
            } else if (c.type.split('/').includes('video')) {
              if (c.source === "LOCAL") {

                return (
                  <div key={i} style={{ marginBottom: '20px' }} onContextMenu={preventContextMenu} className='w-75'>
                    <hr />
                    <video controls controlsList="nodownload" width="100%" height="auto">

                      <source src={`${urlBase}/${c.name}`} type={c.type} />
                      Your browser does not support the video tag.
                    </video>

                    <div className='py-3 row justify-content-between align-items-center'>
                      <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                        <FaStar style={{ marginRight: "20px", color: "#E3A13F" }} />
                        <FaStar style={{ marginRight: "20px", color: "#E3A13F" }} />
                        <FaStar style={{ marginRight: "20px", color: "#E3A13F" }} />
                        <FaStar style={{ marginRight: "20px", color: "#E3A13F" }} />
                      </div>


                      <div className='col-lg-8 col-md-6 col-sm-12 col-12'>
                        <div className='d-flex justify-content-between align-items-center'>
                          <AiModal lesson={lesson} materialData={material} />
                          <VideoSub nameVideo={c?.name} />
                          <button className='sharedButton btn'><FaCommentAlt /> التعليق</button>
                          <button className='sharedButton btn'><AiOutlineLike /> اعجبني</button>
                        </div>
                      </div>
                    </div>

                  </div>
                );
              }
            } else if (c.type.split('/').includes('pdf')) {
              return (
                <div key={i} style={{ marginBottom: '20px', marginTop: "80px" }} onContextMenu={preventContextMenu}>
                  <div>
                    <iframe src={`${urlBase}/${c.name}`} width="100%" height="500px" />
                  </div>
                </div>
              );
            } else if (c?.type === "link") {
              return (
                <>
                  <br /> <br />
                  {/* <span>link</span> */}

                  <div className='w-100 m-auto'>
                    <ReactPlayer
                      url={c?.name}
                      controls={true}
                      width="100%"
                      height="500px"
                      config={{
                        youtube: {
                          playerVars: {
                            modestbranding: 1,
                            rel: 0,
                            showinfo: 0,
                            iv_load_policy: 3,
                          }
                        },
                        dailymotion: {
                          params: {
                            controls: true,
                            autoplay: false,
                            mute: false,
                            'queue-enable': false,
                            'sharing-enable': false,
                            'ui-start-screen-info': false, // Hide video title and uploader info
                            'ui-logo': false, // Hide Dailymotion logo
                          }
                        }
                      }}
                    />
                    <div className='py-3 row justify-content-between align-items-center'>
                      <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                        <FaStar style={{ marginRight: "20px", color: "#E3A13F" }} />
                        <FaStar style={{ marginRight: "20px", color: "#E3A13F" }} />
                        <FaStar style={{ marginRight: "20px", color: "#E3A13F" }} />
                        <FaStar style={{ marginRight: "20px", color: "#E3A13F" }} />
                      </div>


                      <div className='col-lg-8 col-md-6 col-sm-12 col-12'>
                        <div className='d-flex justify-content-between align-items-center'>
                          <AiModal lesson={lesson} materialData={material} />
                          <VideoSub />
                          <button className='sharedButton btn'><FaCommentAlt /> التعليق</button>
                          <button className='sharedButton btn'><AiOutlineLike /> اعجبني</button>
                        </div>
                      </div>
                    </div>
                  </div>


                </>
              )
            }
          })}
        </>
      ) : (
        <>
          <h1>لا يوجد محتوى متاح لهذا الدرس.</h1>
        </>
      )}

      {/* Static PDF rendering for testing */}

      {/* <PdfViewer pdfUrl={TestFile}/>
      <MyDocument/> */}
    </Container>
  );
};

export default MultimediaViewer;
