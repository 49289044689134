import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Create_Post } from '../../actions/community';
import { useDispatch, useSelector } from 'react-redux';

function AddQuestion({ chosenClass, postsToShow, setPostsToShow }) {
  const [show, setShow] = useState(false);
  const [question, setQuestion] = useState('');
  const { user } = useSelector(({ loggedUser }) => {
    return {
      user: loggedUser?.user
    }
  })
  const dispatch = useDispatch();

  // console.log(user)

  const { dir, toast } = useSelector(({ language, notify }) => {
    return {
      dir: language?.dir,
      toast: notify.toast
    }
  })

  // -------submit post----- Ability add post from Teacher Account
  const submitPost = async () => {
    try {
      if (user?.role === "student") {
        // Assuming you have some data to send along with the post
        const postData = {
          content: question,// assuming your post data has a 'question' field
          author: user?._id,
          classID: chosenClass._id
        };

        // Dispatch the action to create a post
        const res = await dispatch(Create_Post(postData));
        setPostsToShow(prev => [res.data.post, ...prev])
        toast().success(res.data.message, {
          position: 'top-center'
        })
        // Reset the question input and close the modal after post creation
        setQuestion('');
        setShow(false);
      } else if (user?.role === "teacher") {
        const postData = {
          content: question,// assuming your post data has a 'question' field
          author: user?._id,
          classID: chosenClass._id,
          postType: "teacher"
        };

        // Dispatch the action to create a post
        const res = await dispatch(Create_Post(postData));
        setPostsToShow(prev => [res.data.post, ...prev])
        toast().success(res.data.message, {
          position: 'top-center'
        })
        // Reset the question input and close the modal after post creation
        setQuestion('');
        setShow(false);
      }
    } catch (err) {
      toast().error(err.message, { position: 'top-center' })
      console.warn(err);
    }
  };

  return (
    <div>
      <div className='add-qu-cont'>
        <button
          // className='add-qu-btn'
          style={{
            color: '#fff',
            borderRadius: '5px',
            padding: '8px',
            fontWeight: '600',
            border: '0px',
            background: '#0F2A51', // Background color
            transition: 'background 0.3s', // Transition effect for smooth hover
          }}
          onClick={() => setShow(true)}
          className='font'
        >
          اضف سؤالك
        </button>
      </div>
      <Modal dir={dir} show={show} onHide={() => setShow(false)} centered className='font' style={{color:"black"}}>
        <Modal.Header>
          <Modal.Title>اضف سؤالك</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Add your form with text input for question */}
          <form>
            <div className="form-group">
              <label htmlFor="question">السؤال:</label>
              <input
                type="text"
                className="form-control"
                id="question"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn lightData' onClick={() => setShow(false)}>
            إلغاء
          </button>
          <button  className='btn lightDataMore' onClick={submitPost}>
            نشر
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddQuestion;
