


import React from 'react';
import "../../../styles/dashboard.css";
import message from "../../../assets/message.png";
import { useSelector } from 'react-redux';
import { HomePage } from '../../../utils/Translation/HomePage';

const Message = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <div dir={dir}>
            <div className='sharedBackground' style={{width:"85%"}}>
                <p>{HomePage.messages[lang]}</p>
            </div>
            <div style={{ textAlign: dir === "rtl" ? "right" : "left", width: "52%", margin: "auto" }}>
                <button className='btn btnGroub' style={{ textAlign: dir === "rtl" ? 'right' : 'left' }}>
                    {HomePage.send_message_now[lang]}
                </button>
            </div>
            <div className="message-card">
                <div className='row justify-content-between align-items-center'>
                    <div className='col-lg-8 col-sm-6 col-md-6 col-7'>
                        <div className="message-content-dashed font">
                            <img src={message} alt="User" className="message-img" />
                            <div className="message-info">
                                <h4>Mohamed Ahmed</h4>
                                <p className='mt-3'>مرحبا أود أن أرسل رسالة لك</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-sm-6 col-md-4 col-5' style={{ textAlign: dir === "rtl" ? "right" : "left" }}>
                        <div className="message-time font px-5">3:30 AM</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Message;
