import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import "../../styles/aitest.css";

import Form from 'react-bootstrap/Form';
import { createTestAi } from '../../utils/tests';
import { ErrorMsg, success } from '../shared/modules/Notifications';
import { HomePage } from '../../utils/Translation/HomePage';
import { useNavigate, useParams } from 'react-router-dom';
import { Set_Test_For } from '../../actions/curriculums/view';

const AiModal = ({ onOpen, materialData, lesson, type }) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false); // Add loading state

    const handleClose = () => setShow(false);

    const [sentences, setSentences] = useState([]);
    const [branchId, setBranchId] = useState(null);
    const [selectedLev, setSetSelectedLev] = useState([]);
    const [levelId, setLevelId] = useState(null);
    const [brLevels, setBrLevels] = useState([]);
    const [levelMaterials, setLevelMaterials] = useState([]);
    const [written, setWeitten] = useState(null);
    const [trueandFalse, setTrueAndFalse] = useState(null);
    const [choices, setChoices] = useState(null);
    const [exam, setExam] = useState([]);

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const { branchs, levels, toast, user } = useSelector(({ branchs, levels, notify, loggedUser }) => {
        return { branchs, levels, toast: notify.toast, user: loggedUser?.user };
    });



    const handleShow = () => {
        setShow(true);
    };

    const [lessonContentValue, setLessonContentValue] = useState(1);

    const handleCheckboxChange = (event) => {
        if (event.target.checked) {
            setLessonContentValue(2);
        } else {
            setLessonContentValue(1);
        }
    };

    const params = useParams();

    const onChangeBranch = (branchID) => {
        console.log(branchID);
        try {
            setBranchId(branchID);
            const branch = branchs.find(b => b._id === branchID);
            const levelsIDS = branch.levelsIDS ? branch.levelsIDS : [];
            const branchLevels = levels.filter(l => levelsIDS.includes(l._id));
            setBrLevels(branchLevels);
            setLevelMaterials([]);
            setSetSelectedLev([]);
            setLevelId(null);
        } catch (err) {
            console.log(err.message);
        }
    };

    const onChangeLevel = async (levelID) => {
        try {
            setLevelId(levelID);
        } catch (err) {
            console.log(err.message);
        }
    };

    const handleSentenceInput = (event) => {
        if (event.key === 'Enter' && event.target.value.trim()) {
            setSentences([...sentences, event.target.value.trim()]);
            event.target.value = "";
        }
    };


    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Set Test For Lessson
    const setTest = () => {
        dispatch(Set_Test_For({ type, testFor: lesson?._id }))
    }

    // Go To Navigate Url To SHow Exam 
    const navigateUrlShowExam = () => {
        const pathname = window.location.pathname.split('/');
        pathname.pop();
        const prevPath = pathname.join('/')
        navigate(`${prevPath}/show-ai-test`, { state: { exam } })
    }

    const handleCreateTest = async () => {
        setLoading(true); // Set loading to true
        try {
            const formData = new FormData();
            formData.append('materialId', materialData?._id);
            formData.append('lessonId', lesson?._id);
            formData.append('trueFalse', trueandFalse);
            formData.append('written', written);
            formData.append('choose', choices);
            formData.append('levelId', levelId);
            formData.append('branchId', branchId);
            formData.append('keywords', sentences);
            formData.append('lessonType', lessonContentValue);

            const resTestAi = await createTestAi(formData);

            if (resTestAi?.data?.case) {
                // Make sure the exam data is received and set correctly
                const examData = resTestAi.data?.test;
                console.log(examData)
                if (examData) {
                    setExam(examData);
                    success(resTestAi?.data?.message);
                    setTest();
                    handleClose();
                    const pathname = window.location.pathname.split('/');
                    pathname.pop();
                    const prevPath = pathname.join('/')
                    navigate(`${prevPath}/show-ai-test`, { state: { examData } })
                    
                    // navigateUrlShowExam();
                } else {
                    console.error("No exam data received");
                }
            }
        } catch (e) {
            console.log(e);
            if (e?.response) {
                ErrorMsg(e?.response?.data);
            }
        } finally {
            setLoading(false); // Set loading to false
        }
    };


    return (
        <div>
            <button className='sharedButton btn' onClick={handleShow}>
                {HomePage.ai[lang]}
            </button>

            <Modal show={show} onHide={handleClose} className='font' centered style={{ color: "black" }}>
                <Modal.Body>
                    <div className="ai-test-form font">
                        <h2 className='headerAi mb-4'>{HomePage.ai[lang]}</h2>
                        <div className="w-100">
                            <div className='row justify-content-between align-items-center'>
                                <div className="col-lg-6 col-sm-12 col-md-6 ">
                                    <Form.Group className="w-100 ">
                                        <Form.Control
                                            as="select"
                                            onChange={(e) => onChangeBranch(e.target.value)}
                                            style={{ backgroundColor: "#4169E2" }}
                                            className='forSelect'
                                        >
                                            <option>{HomePage.selectSystem[lang]}</option>
                                            {branchs.map((b) => (
                                                <option key={b._id} value={b._id}>
                                                    {b.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                                <div className="col-lg-6 col-sm-12 col-md-6 " style={{ background: "#4169E2", color: "white", padding: "10px", borderRadius: "10px" }}>
                                    {HomePage.lesson[lang]} : {materialData?.name}
                                </div>
                            </div>
                            <div className='row justify-content-between align-items-center mt-3 mb-3'>
                                <div className="col-lg-6 col-sm-12 col-md-6 ">
                                    <Form.Group className="w-100 ">
                                        <Form.Control
                                            as="select"
                                            onChange={(e) => onChangeLevel(e.target.value)}
                                            style={{ backgroundColor: "#4169E2" }}
                                            className='forSelect'
                                        >
                                            <option>{HomePage.selectLevel[lang]}</option>
                                            {brLevels.map((level) => (
                                                <option key={level._id} value={level._id}>
                                                    {level.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                                <div className="col-lg-6 col-sm-12 col-md-6 ">
                                    <div className='form-field'>
                                        <p> {HomePage.lesson[lang]} : {lesson?.name}</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p className='headerAi'>{HomePage.keyWord[lang]}</p>
                                <textarea className='textAreaAI' onKeyDown={handleSentenceInput} placeholder={`${HomePage.writeHere[lang]}`} rows={"4"}></textarea>
                                <div>
                                    <ul>
                                        {sentences.map((sentence, index) => (
                                            <li key={index}>{sentence}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <p className='headerAi'>{HomePage.summarizeYoutube[lang]}</p>
                                <textarea className='textAreaAI' placeholder={`${HomePage.writeHere[lang]}`} rows={"4"}></textarea>
                            </div>
                            <div>
                                <p className='headerAi mb-4'>{HomePage.contentAdd[lang]}  <input type='checkbox' name="lessonContent" id="lessonContent" onChange={handleCheckboxChange} /></p>
                            </div>
                            <div>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='mx-2'>
                                        <button className='sharedButton btn mb-2 w-100'>{HomePage.writtenQuestion[lang]} </button>
                                        <input type="text" name="choose" className='form-control' value={written} onChange={(e) => setWeitten(e.target.value)} />
                                    </div>
                                    <div className='mx-2'>
                                        <button className='sharedButton btn mb-2 w-100'>{HomePage.trueAndFalse[lang]}</button>
                                        <input type="text" name="choose" className='form-control' value={trueandFalse} onChange={(e) => setTrueAndFalse(e.target.value)} />
                                    </div>
                                    <div className='mx-2'>
                                        <button className='sharedButton btn mb-2 w-100'>{HomePage.Multiplechoice[lang]}</button>
                                        <input type="text" name="choose" className='form-control' value={choices} onChange={(e) => setChoices(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-3">
                            <button className='w-75 btn btnGroub' onClick={handleCreateTest} disabled={loading}>
                                {loading ? 'Loading...' : HomePage.addTest[lang]}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AiModal;
