import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { getLessonByCode } from '../../utils/codes';
import {  useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ErrorMsg, success } from '../shared/modules/Notifications';

const CodeModal = ({ show, setShow, code, packId }) => {
    const handleClose = () => {
        setShow(false);
    };


    const { user } = useSelector(({ loggedUser }) => {
        return { user: loggedUser };
    });
    const navigate = useNavigate()

    const [codeValue, setCodeValue] = useState();


    const GetLesson = async () => {
        try {
            const resLesson = await getLessonByCode({
                code: codeValue,
                packID: packId,
                materialCode: code
            })

            console.log(resLesson)
            if(resLesson?.data?.case){
                success(resLesson?.data?.message)
            }else{
                ErrorMsg(resLesson?.data?.message)
            }

            if (resLesson?.data?.case) {
                setCodeValue("")
                navigate(`/dashboard/${user?.user?.name}/${user?.user?._id}/education-board/${"codeingProgram"}/${resLesson?.data?.lesson?.lessonId}/${resLesson?.data?.sheetId}/${resLesson?.data?.lesson?._id}`)
            }
        } catch (e) {
            console.log(e);
            ErrorMsg(`${e?.response?.data?.message}`)
            setCodeValue("")
        }
    }

    return (
        <Modal show={show} onHide={handleClose} style={{ direction: 'rtl' , color:"black" }} centered className='font' >
            <div className='text-center'>
                <p className='color fs-5'> قم بكتابة الكود</p>
            </div>
            <Modal.Body>
                <input type="text" className='form-control' name="code" id="code" onChange={(e) => setCodeValue(e.target.value)} value={codeValue} />
            </Modal.Body>
            <div className='text-center'>
                <button onClick={GetLesson} className='btn btnGroub mb-3' style={{}}>احصل علي الدرس</button>
            </div>
        </Modal>
    );
};

export default CodeModal;
