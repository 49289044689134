

import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "../../styles/aitest.css"
import { HomePage } from '../../utils/Translation/HomePage';
import { useSelector } from 'react-redux';
import { getSummarizedVideo } from '../../utils/branchs';
import { ErrorMsg } from '../shared/modules/Notifications';

const VideoSub = ({ onOpen ,nameVideo}) => {
    const [show, setShow] = useState(false);
    const [isChecked, setIsChecked] = useState(false); // حالة لتتبع الـ checkbox

    const handleClose = () => setShow(false);

    const handleShow = () => {
        // onOpen();
        setShow(true); // Show the AiModal
    };

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked); // تحديث الحالة بناءً على تحديد الـ checkbox
    };

    const handleSummarizedideo = async()=>{
        try{
            const handleSummarize = await getSummarizedVideo({
                name:nameVideo
            })
            console.log(handleSummarize)
        }catch(e){
            console.log(e)
            if(e?.response){
                ErrorMsg(e?.response?.data)
            }
        }
    }

    return (
        <div>
            <button className='sharedButton btn' onClick={handleShow}>
                {HomePage.summerVideo[lang]}
            </button>

            <Modal show={show} onHide={handleClose} className='font' centered style={{ color: "black" }}>
                <Modal.Body>
                    <div className="ai-test-form font">
                        <h2 className='headerAi mb-4'>{HomePage.summerVideo[lang]}</h2>
                        <div className="w-100">
                            <div>
                                <p className='headerAi mb-4'>
                                    {HomePage.includeVideo[lang]}
                                    <input type='checkbox' name="lessonContent" id="lessonContent" onChange={handleCheckboxChange} />
                                </p>
                            </div>

                            {/* إخفاء أو إظهار textarea بناءً على قيمة isChecked */}
                            {!isChecked && (
                                <div>
                                    <p className='headerAi'>{HomePage.summarizeYoutube[lang]}</p>
                                    <textarea className='textAreaAI' placeholder={`${HomePage.writeHere[lang]}`} rows={"4"}></textarea>
                                </div>
                            )}
                        </div>
                        <div className="text-center mt-3">
                            <button className='w-75 btn btnGroub' onClick={handleSummarizedideo}>{HomePage.showResult[lang]}</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default VideoSub;
