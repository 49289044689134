import React from 'react'
import imageJoin from '../../assets/images/TT.png'
import reverseJoin from '../../assets/images/joinReverse.png'
import { useSelector } from 'react-redux';
import { HomePage } from '../../utils/Translation/HomePage';
const JoinUs = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    return (
        <div className='join  py-4' dir={`${dir}`} style={{ width: "84%", color: "white", margin: "200px auto", borderRadius: "15px", background: "#4169E2" }}>
            <div className='container'>
                <div className='row justify-content-between align-items-center joinData'>

                    <div className='col-lg-6 col-sm-12 col-md-6 handleJoin' style={{ textAlign: `${dir === "rtl" ? 'right' : 'left'}` }}>
                        <h4>{HomePage.joinUs[lang]}</h4>
                        <p className='mt-5'>
                            {HomePage.joinUsContent[lang]}
                        </p>
                    </div>
                    <div className='col-lg-6 col-sm-12 col-md-6 responsiveImage'>
                        {/* <img src={dir === "rtl" ? reverseJoin:imageJoin} alt="imageJoin" className='imgJoin w-100' /> */}
                        <img
                            src={imageJoin}
                            alt="imageJoin"
                            className='imgJoin w-100'
                            style={{ transform: `${dir === 'rtl' ? 'scaleX(-1)' : 'scaleX(1)'}` }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JoinUs
