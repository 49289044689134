import React, { useContext } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../../styles/teach.css"
import { ThemeContext } from '../../ThemeProvider';
import TeacherCard from '../shared/modules/TeacherCard';
import { useSelector } from 'react-redux';
import { HomePage } from '../../utils/Translation/HomePage';
const Teachers = () => {
    const teachers = useSelector(state => state.users.teachers);
    const { theme } = useContext(ThemeContext);
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024, // العرض بالبيكسل
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div style={{ margin: "120px auto", textAlign: "center", width: "90%", padding: "20px" }}>
            <h4 style={{ color: theme === 'light' ? '#2B6CCD' : 'white' }}>المعلمون</h4>
            <div className='container programsCards'>

                <Slider {...settings}>

                    {
                        teachers ? (
                            teachers.map((teacher) => {
                                return (
                                    <div className='col-lg-3 col-sm-6 col-md-4' key={teacher._id}>
                                        <TeacherCard teacher={teacher} />
                                    </div>
                                )
                            })
                        ) : null
                    }

                </Slider>
            </div>
        </div>
    )
}

export default Teachers
