import React, { useState, useEffect } from 'react';
import { loginTrans } from '../../utils/Translation/loginAndRegister';
import { MdEmail } from "react-icons/md";
import { useSelector } from 'react-redux';
import PageContainer from '../layout/PagecContainer';
import { forgetPass } from '../../utils/forgetPassApi';

const ForgetPass = () => {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const handleForgetPass = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const res = await forgetPass({ email });
            if (res?.data?.case) {
                setMessage(res?.data?.message);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                setMessage("");
            }, 10000);
            return () => clearTimeout(timer);
        }
    }, [message]);

    const { lang } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <PageContainer>
            <div className="loginpage font">
                <div className="container spacePage">
                    {!loading ? (
                        <div className="loginForm py-4">
                            {message && (
                                <div className={`mt-3 text-center alert  alert-success`} role="alert">
                                    {message}
                                </div>
                            )}

                            <div className="formStyle m-auto" style={{background:"white",color:"black"}}>
                                <div className="">
                                    <div className="formPart text-center">
                                        <form className="w-100" action="">
                                            <div className="font">
                                                <p className="color"> OLP مرحبا بكم في</p>
                                                <p>من فضلك ادخل الايميل</p>
                                            </div>

                                            <div className="mb-3 mt-4 positionInput">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    className="form-control"
                                                    placeholder={loginTrans.email[lang]}
                                                    id="loginId1"
                                                    onChange={(event) => {
                                                        setEmail(event.target.value);
                                                    }}
                                                />
                                                {/* <div className="email"> <MdEmail /></div> */}
                                            </div>

                                            <div className="text-center mt-5">
                                                <input
                                                    type="submit"
                                                    className=" w-100 font btn w-50 lightData"
                                                    id="loginId3"
                                                    onClick={handleForgetPass}
                                                    value={loginTrans.completed[lang]}
                                                />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="loaderIcon">
                            <span className="loader"></span>
                        </div>
                    )}
                </div>
            </div>
        </PageContainer>
    );
}

export default ForgetPass;
