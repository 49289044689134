import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Get_Buy_Requests, Handel_Request } from '../../../actions/buyRequests';
import MenuSelection from './menuSelection';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import { getStudents, urlBase } from '../../../utils/API';
import Dropdown from 'react-bootstrap/Dropdown';

import { getValidRequests, getNotValidRequests, changeRequestState } from '../../../utils/packagesRequests';
import { Get_All_Packages } from '../../../actions/packages';
import { ThemeContext } from '../../../ThemeProvider';
const origin = window.origin;

function formatDateString(dateString) {
  const options = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  };

  const date = new Date(dateString);
  return date.toLocaleString('en-US', options);
}

function BuyRequests({ role , teacherId }) {
  const [status, setStatus] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [actionType, setActionType] = useState('');
  const [selectedRequestId, setSelectedRequestId] = useState('');
  const [Response, setResponse] = useState('');
  const [filterOption, setFilterOption] = useState('All'); // New state for filter option
  const [filterPackage, setFilterPackage] = useState('All'); // New state for package filter

  const dispatch = useDispatch();

  const [allUsers, setAllUsers] = useState([]);

  const fetchStudents = async () => {
    try {
      const res = await getStudents();
      setAllUsers(res.data.users);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    dispatch(Get_Buy_Requests()).then((res) => console.log(res));
    fetchStudents();
  }, []);

  const { active_Requests, un_Active_Requests } = useSelector(mapStateToProps);
  const { branchs, levels } = useSelector(({ branchs, levels }) => {
    return {
      branchs, levels,
    };
  });

  const getUserName = (request) => {
    try {
      const user = allUsers.find((u) => u._id === request.userID);
      return user;
    } catch (e) {
      console.log(e.message);
      return 'NAN';
    }
  };

  const getBranchLevel = (packData) => {
    const branchId = packData?.branchId;
    const levelId = packData?.levelId;
    const branch = branchs.find((b) => b._id === branchId);
    const level = levels.find((level) => level._id === levelId);
    return { branch, level };
  };

  const handleViewImage = (imageLink) => {
    setSelectedImage(imageLink);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenConfirmationModal = (actionType, requestId) => {
    setActionType(actionType);
    setSelectedRequestId(requestId);
    setConfirmationModal(true);
  };

  const handleCloseConfirmationModal = () => {
    setActionType('');
    setSelectedRequestId('');
    setResponse('');
    setConfirmationModal(false);
  };

  const handleConfirmAction = async () => {
    try {
      await dispatch(Handel_Request(
        { id: selectedRequestId, valid: actionType === 'Accept' ? true : false, note: Response }
      ));

      handleCloseConfirmationModal();
    } catch (err) {
      console.warn(err);
      setResponse(err.message);
    }
  };

  const handleFilterChange = (option) => {
    setFilterOption(option);
  };

  const handlePackageFilterChange = (packageName) => {
    setFilterPackage(packageName);
  };

  let packagesList = useSelector((state) => state.packages);

  // console.log(packagesList);

  const requests = status ? active_Requests : un_Active_Requests;
  const filteredRequests = requests.filter((request) => {
    if (filterOption !== 'All' && request.requestType !== filterOption) {
      return false;
    }
    if (filterPackage !== 'All' && request.packData?.name !== filterPackage) {
      return false;
    }
    if (role === 'teacher' && request.packData?.teacher?._id !== teacherId) {
      return false;
    }
    return true;
  });

  console.log(filteredRequests);
  const {theme} = useContext(ThemeContext)

  return (
    <div>
      <br />
      <MenuSelection setStatus={setStatus} status={status} />
      <br />
      <br />
      <div className="mt-5 font">
        <h1 style={{ color: theme === "light" ?  "#0F2A51":"white" }}>{!status ? 'طلبات فى الانتظار' : 'طلبات تم الرد عليها'}</h1>
        {
          role === "teacher" ? (null) : (
            <div>
              {status ? (
                <div className="d-flex justify-content-between align-items-center w-75" style={{ margin: '20px auto' }}>
                  <div className='mb-5'>
                    <Dropdown>
                      <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        الفلتره بنوع الحجز
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleFilterChange('All')}>All</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleFilterChange('normal')}>Normal</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleFilterChange('CODE')}>Code</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className='mb-5'>
                    <Dropdown>
                      <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        الفلتره بنوع البرنامج
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {packagesList ? (
                          packagesList.map((item) => (
                            <Dropdown.Item key={item._id} onClick={() => handlePackageFilterChange(item.name)}>
                              {item.name}
                            </Dropdown.Item>
                          ))
                        ) : null}
                        <Dropdown.Item onClick={() => handlePackageFilterChange('All')}>All</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              ) : null}
              {!status ? (
                <div className='mb-5 mt-5'>
                  <Dropdown >
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      الفلتره بنوع البرنامج
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {packagesList ? (
                        packagesList.map((item) => (
                          <Dropdown.Item key={item._id} onClick={() => handlePackageFilterChange(item.name)}>
                            {item.name}
                          </Dropdown.Item>
                        ))
                      ) : null}
                      <Dropdown.Item onClick={() => handlePackageFilterChange('All')}>All</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : null}
            </div>
          )
        }
        <Table responsive>
          <thead>
            <tr>
              <th>الفئة</th>
              <th>المستوى</th>
              <th>الباقة</th>
              <th>الطالب</th>
              <th>البريد الالكترونى</th>
              <th>الهاتف</th>
              <th> السعر - ج.م</th>
              <th>حجوزات بالكود</th>
              <th>تاريخ الطلب</th>
              {status && <th>الحالة</th>}
              {status && <th>الطلب</th>}
              {!status && <th colSpan={"3"} style={{ textAlign: "center" }}>Action</th>}
            </tr>
          </thead>
          <tbody>
            {filteredRequests
              .sort((b, a) => new Date(a.date) - new Date(b.date))
              .map((request) => (
                <tr key={request._id}>
                  <td>{getBranchLevel(request.packData)?.branch?.name}</td>
                  <td>{getBranchLevel(request.packData)?.level?.name}</td>
                  <td>{request.packData?.name}</td>
                  <td>{getUserName(request)?.name}</td>
                  <td>{getUserName(request)?.email}</td>
                  <td>{getUserName(request)?.phone}</td>
                  <td>{request.packData?.price}</td>
                  <td>{request?.requestType}</td>
                  <td>{formatDateString(request?.date)}</td>
                  {status && <td>{request?.response}</td>}
                  <td>
                    <button className='btn sharedButton' onClick={() => handleViewImage(`${urlBase}/${request?.receipt?.file}`)}>
                      View
                    </button>
                  </td>
                  <td>
                    {!status && (
                      <>
                        <button className='btn sharedButton' onClick={() => handleOpenConfirmationModal('Reject', request._id)}>
                          Reject
                        </button>
                      </>
                    )}
                  </td>
                  <td>
                    {!status && (
                      <button className='btn sharedButton' onClick={() => handleOpenConfirmationModal('Accept', request?._id)}>
                        Accept
                      </button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>

        <Modal show={showModal} onHide={handleCloseModal} centered className='font'>
          <Modal.Header closeButton>
            <Modal.Title>View Receipt</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={selectedImage} alt="Receipt" style={{ width: '100%' }} />
          </Modal.Body>
        </Modal>

        <Modal show={confirmationModal} onHide={handleCloseConfirmationModal} centered className="font">
          <Modal.Header closeButton>
            <Modal.Title>تاكيد طلب الاشتراك</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formResponse">
              <Form.Label>اضف الرد على الطلب:</Form.Label>
              <Form.Control
                type="text"
                placeholder="ردك على الطلب"
                value={Response}
                onChange={(e) => setResponse(e.target.value)}
              />
            </Form.Group>
            <p>Are you sure you want to {actionType.toLowerCase()} this request?</p>
          </Modal.Body>
          <Modal.Footer>
            <button className='btn btnGroub' onClick={handleCloseConfirmationModal}>
              الغاء
            </button>
            <button className={actionType === 'Accept' ? 'btn btnGroub' : 'btn special color'} onClick={handleConfirmAction}>
              تاكيد
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

function mapStateToProps({ buyRequests }) {
  console.log('buyRequests', buyRequests);
  const active_Requests = buyRequests ? buyRequests.filter((b) => b.response !== 'PROCESSING') : [];
  const un_Active_Requests = buyRequests ? buyRequests.filter((b) => b.response === 'PROCESSING') : [];
  return { active_Requests, un_Active_Requests };
}

export default BuyRequests;
