
import axios from "axios"
import { urlBase } from "./API"
import Cookies from 'js-cookie';

// 
// get materials 
export const getMaterials = () => {
    return axios({
        method: "get",
        url: `${urlBase}/api/get-materials`,
        withCredentials: true
    });
}

// create material

export const createMaterial = (data) => {
    const token = Cookies.get('connect.sid');
    console.log(token)
    return axios({
        method: 'post',
        url: `${urlBase}/api/new-material`,
        data: data,
        withCredentials: true,

        headers: {
            'Cookie': `connect.sid=s%3A5LwBB0pFMmr4ICDzKID5XRCONOm2VlIY.gOVRFa2yrM83NLuzqlAIZzDmFv1fyJkS6VdSCekBOeg` // Add the token to the header
        }
    })
}
// update material 

export const updateMaterial = (data) => {
    return axios({
        method: "post",
        url: `${urlBase}/api/update-material`,
        data: data, // contain updated properties with material _id in one object
        withCredentials: true
    })
}

// Delete Material

export const deleteMaterial = (id) => {
    return axios({
        method: "DELETE",
        url: `${urlBase}/api/delete-material`,
        data: id,// object {_id,id},
        withCredentials: true
    })
}

// GET SPECIFIC MATERIAL BY IDS ARRAY
//codes:[]
export const GetMaterialsByIds = (IdsArray) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/get-materials-by-id`,
        data: IdsArray,
        withCredentials: true
    })
}

// GET MATERIAL BY CODE 

export const getMaterialByCode = (Code) => {
    return axios({
        method: "POST",
        url: `${urlBase}/api/get-material-by-code`,
        data: Code, //{ code object}
        withCredentials: true
    })
}

