import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { MdDeleteForever } from "react-icons/md";
import { deleteLesson } from '../../../utils/lessons'; // Adjust the path if needed
import { HomePage } from '../../../utils/Translation/HomePage';
import { useSelector } from 'react-redux';

function DeleteLesson({ lessonId,updateLessons }) {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const confirmDelete = async () => {
        try {
            await deleteLesson({_id:lessonId});
            await updateLessons()
            alert('تم حذف الدرس');
        } catch (error) {
            console.error('Error deleting lesson:', error);
            alert('حدث خطأ اثناء الحذف');
        } finally {
            closeModal();
        }
    };

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
      });

    return (
        <div>
            <button className=' btn' style={{backgroundColor:"#E24144",color:"white"}} onClick={openModal}>
                {HomePage.del[lang]}
            </button>
            <Modal show={modalIsOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{HomePage.confirmDelete[lang]}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{HomePage.confirmDeleteQue[lang]}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        {HomePage.cancel[lang]}
                    </Button>
                    <Button variant="danger" onClick={confirmDelete}>
                        {HomePage.confirm[lang]} 
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default DeleteLesson;
