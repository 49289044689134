import { useContext, useEffect, useState } from 'react';
import LogoRhaya from '../../assets/images/logo.png'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, NavLink } from 'react-router-dom';
import { ThemeContext } from '../../ThemeProvider';
import { CiLight } from "react-icons/ci";
import { MdDarkMode } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { Log_Out } from '../../actions/login&register';
import { useNavigate } from "react-router-dom"
import { setLang } from "../../actions/language";
import { HomePage } from '../../utils/Translation/HomePage';
import NavDropdown from 'react-bootstrap/NavDropdown';
import arabic from "../../assets/images/arabic.png"
import en from "../../assets/images/en.png"

function HeaderDefault() {
    const [scrolled, setScrolled] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 0.2) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const loggedUser = useSelector((state) => state.loggedUser);

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const logout = () => {
        try {
            dispatch(Log_Out());
            navigate(`/login`)
            window.location.reload();
        } catch (e) {
            console.log(e);
        }
    }

    const { theme, toggleTheme } = useContext(ThemeContext);

    return (
        <Navbar expand="lg" className={`font headerNavbar ${scrolled ? `scrolled` : ''} ${theme}`} style={{ backgroundColor: theme === 'light' ? '#4169E2' : '#001C45' }} dir={`${dir}`} fixed="top" >
            <Container>
                <Link to="/">
                    <img src={LogoRhaya} alt="OLP LOGO" style={{ width: "100%", height: "90px" }} />
                </Link>
                <Navbar.Toggle aria-controls="navbarScroll" className={`custom-toggle ${theme}`}/>
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="m-auto my-2 my-lg-0">
                        <NavLink to="/" style={{ textDecoration: "none", marginRight: "10px", marginTop: "5px", padding: "5px" }} className={`${theme === "light" ? ("puplicBlack nav") : ("puplicWhite")}`}>{HomePage.Home[lang]}</NavLink>
                        <NavLink to="/subjects" style={{ textDecoration: "none", marginRight: "10px", marginTop: "5px", padding: "5px" }} className={`${theme === "light" ? ("puplicBlack nav") : ("puplicWhite")}`}>{HomePage.Subjects[lang]}</NavLink>
                        <NavLink to="/teachers" style={{ textDecoration: "none", marginRight: "10px", marginTop: "5px", padding: "5px" }} className={`${theme === "light" ? ("puplicBlack nav") : ("puplicWhite")}`}>{HomePage.Teachers[lang]}</NavLink>
                        <NavLink to="/bardiat-community" style={{ textDecoration: "none", marginRight: "10px", marginTop: "5px", padding: "5px" }} className={`${theme === "light" ? ("puplicBlack nav") : ("puplicWhite")}`}>
                            {HomePage.society[lang]}
                        </NavLink>
                    </Nav>

                    {
                        loggedUser && loggedUser.auth ? (
                            <div className='d-flex text-center m-auto'>
                                {
                                    loggedUser?.user?.role === "Admin" ? (
                                        <Link to={`/user/${loggedUser?.user?._id}`} className={`btn mx-1 me-2 ${theme === 'dark' ? 'buttonsGroub' : 'lightDataMore'}`}>{HomePage.dashboard[lang]}</Link>
                                    ) : (
                                        <Link to={`/dashboard/${loggedUser?.user?.name.split(' ').join('')}/${loggedUser?.user?._id}/profile`} className={`btn mx-1 me-2 ${theme === 'dark' ? 'buttonsGroub' : 'lightDataMore'}`}>{HomePage.dashboard[lang]}</Link>
                                    )
                                }

                                <button  className={`btn me-1 mx-1 ${theme === 'dark' ? 'lightDataMore' : 'lightData'}`} onClick={logout}>{HomePage.log_out[lang]}</button>
                            </div>
                        ) : (
                            <div className='d-flex text-center m-auto'>
                                <Link to="/login" className={`btn me-1 mx-1 ${theme === 'dark' ? 'buttonsGroub' : 'lightDataMore'}`}>{HomePage.login[lang]} </Link>
                                <Link to="/register" className={`btn me-1 mx-1 ${theme === 'dark' ? 'lightDataMore' : 'lightData'}`}>{HomePage.register[lang]}</Link></div>
                        )
                    }

                    <div className='switcher '>
                        <NavDropdown title={lang === 'AR' ? 'اللغه' : 'Language'} id="collapsible-nav-dropdown">
                            <NavDropdown.Item onClick={() => dispatch(setLang('AR'))}>
                                <img
                                    src={arabic}
                                    width="30"
                                    className="d-inline-block align-top"
                                    alt="Arabic"
                                />
                                العربية
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={() => dispatch(setLang('EN'))}>
                                <img
                                    src={en}
                                    width="30"
                                    className="d-inline-block align-top"
                                    alt="English"
                                />
                                English
                            </NavDropdown.Item>
                        </NavDropdown>

                        <button onClick={toggleTheme} className='fs-3 mx-5' style={{ background: "transparent", outline: "none", border: "none" }}>
                            {
                                theme === 'light' ? (<CiLight />) : (<MdDarkMode />)
                            }
                        </button>
                    </div>

                </Navbar.Collapse>
            </Container >
        </Navbar >
    );
}

export default HeaderDefault;