import axios from 'axios';
import {urlBase} from './API';

export const createTest = (data)=>{
    return axios({
        method:"POST",
        url:`${urlBase}/api/new-test`,
        data:data,
        withCredentials:true
    })
}
export const getTestsByIds = (IDS)=>{
    return axios({
        method:'POST',
        url:`${urlBase}/api/get-tests-by-ids`,
        data:IDS,//{testIDS:[]},
        withCredentials:true
    })
}
export const getAllTests = ()=>{
    return axios({
        method:"GET",
        url:`${urlBase}/api/get-all-tests`,
        withCredentials:true
    })
}
export const updateTest = (testObj)=>{
    return axios({
        method:'POST',
        url:`{${urlBase}/api/update-test}`,
        withCredentials:true,
        data:testObj
    })
}
// delete test
export const deleteTest = (idObj)=>{
    return axios({
        method:'DELETE',
        url:`${urlBase}/api/delete-test`,
        data:idObj,
        withCredentials:true
    })
}


export const createTestAi = (data)=>{
    // console.log(data)
    return axios({
        method:"POST",
        url:`${urlBase}/api/gemini/exam`,
        data:data,
        withCredentials:true
    })
}