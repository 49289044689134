import React, { useContext } from 'react'
import PageContainer from '../layout/PagecContainer'
import SharedImg from '../shared/modules/SharedImg'
import about from "../../assets/aboutUs.png"
import SharedStatic from './modules/SharedStatic'
import { useSelector } from 'react-redux'
import { HomePage } from '../../utils/Translation/HomePage'
import { ThemeContext } from '../../ThemeProvider'
const System = () => {

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const { theme } = useContext(ThemeContext);
    return (
        <div className='font'>
            <PageContainer>
                <main id="contact-us" dir={dir}>
                    <SharedImg img={about} />
                    <div className='container'>
                        <div className='infoContact mx-3 w-100'>
                            <div className='' style={{ width: "380px" }}>{HomePage.internationalSystemsTitle[lang]}</div>
                        </div>
                        <p className={`sharedColor mx-4 mt-5 ${theme === 'light' ? 'infofordetails' : "text-light"} `}>
                            <p className='fs-5'>

                                {HomePage.globalStandards[lang]}
                                <br />
                                {HomePage.britishSystem[lang]}
                                <br />
                                {HomePage.britishSystemDescription[lang]}
                                <br />

                                {HomePage.ibSystem[lang]}
                                <br />

                                {HomePage.americanSystem[lang]}
                            </p>

                        </p>
                    </div>


                    <SharedStatic hint="about" />

                </main>
            </PageContainer>
        </div>
    )
}

export default System
