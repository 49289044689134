import React, { useContext, useState } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../../styles/teach.css"
import { ThemeContext } from '../../ThemeProvider';
import TeacherCard from '../shared/modules/TeacherCard';
import { useSelector } from 'react-redux';
import { HomePage } from '../../utils/Translation/HomePage';
import SocietyCard from '../shared/modules/SocietyCard';
import socOne from "../../assets/images/socone.png"
import socTwo from "../../assets/images/soctwo.png"
import socThree from "../../assets/images/socthree.png"
import socFour from "../../assets/images/socfour.png"
const OurSociety = () => {

    const { theme } = useContext(ThemeContext);
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const teachers = [
        { img: socOne, name: `${HomePage.Asia[lang]}`, desc: `${HomePage.AsiaDesc[lang]}` },
        { img: socTwo, name: `${HomePage.africa[lang]}`, desc: `${HomePage.africaDesc[lang]}` },
        { img: socThree, name: `${HomePage.europe[lang]}`, desc: `${HomePage.europeDesc[lang]}` },
        { img: socFour, name: `${HomePage.northAmerica[lang]}`, desc: `${HomePage.northAmericaDesc[lang]}` }
    ];
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024, // العرض بالبيكسل
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div style={{ margin: "120px auto", textAlign: "center", width: "90%", padding: "20px" }}>
            <h4 style={{ color: theme === 'light' ? '#2B6CCD' : 'white' }}>{HomePage.societyTwo[lang]}</h4>
            <div className='container programsCards'>

                <Slider {...settings}>

                    {
                        teachers ? (
                            teachers.map((teacher) => {
                                return (
                                    <div className='col-lg-3 col-sm-6 col-md-4' key={teacher.desc} >
                                        <div className='teacher text-center' >
                                            <div className={`card ${theme} font`} style={{ minHeight: "70vh" }}>
                                                <div className='header-card mb-5'>
                                                    <p>★★★★★</p>
                                                </div>
                                                <div className='img-card'>
                                                    <img
                                                        src={teacher?.img}
                                                        alt="Teacher"
                                                        className="teacherImage"
                                                        style={{
                                                            width: "200px",
                                                            height: "200px",
                                                            borderRadius: "50%"
                                                        }}
                                                    />
                                                </div>
                                                <div className="mt-3 font text-center" style={{ minHeight: "250px" }}>
                                                    <h4 className="text-center" style={{ color: theme === "light" ? "#001C45" : "white" }}> {teacher?.name}</h4>
                                                    <p style={{ color: `${theme === "light" ? "#001C45" : "white"}` }}>
                                                        {teacher?.desc}
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                )
                            })
                        ) : null
                    }

                </Slider>
            </div>
        </div>
    )
}

export default OurSociety
