import React, { useContext, useState } from 'react';
import PageContainer from '../layout/PagecContainer';
import "../../styles/contact.css";
import { MdLocalPhone } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import SharedImg from '../shared/modules/SharedImg';
import SharedStatic from './modules/SharedStatic';
import { InfoMsg, success } from '../shared/modules/Notifications';
import { contactUsForm } from '../../utils/contact';
import { useSelector } from 'react-redux';
import { HomePage } from '../../utils/Translation/HomePage';
import { loginTrans } from '../../utils/Translation/loginAndRegister';
import { TbWorld } from "react-icons/tb";
import about from "../../assets/aboutUs.png"
import { ThemeContext } from '../../ThemeProvider';

const ContactPage = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [comment, setComment] = useState("");
    const [phone, setPhone] = useState("");
    const [response, setResponse] = useState("");

    let handelSubmit = async (event) => {
        event.preventDefault();
        let formData = {
            name,
            email,
            phone,
            address,
            comment,
        };
        try {
            const res = await contactUsForm(formData);
            console.log(res);
            if (res?.data?.case) {
                setResponse(res?.data?.message);
                success(res?.data?.message);
                setName("");
                setEmail("");
                setAddress("");
                setComment("");
                setPhone("");
            } else {
                setResponse("Something went wrong, please try again.");
            }
        } catch (e) {
            setResponse("Something went wrong, please try again.");
        }
        console.log("formData", formData);
    };

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const { theme } = useContext(ThemeContext);

    return (
        <div className='font'>
            <PageContainer>
                <main id="contact-u">
                    <SharedImg img={about} />
                    <div className='container' dir={dir}>
                        <div className='infoContact'>
                            <div className='mx-4'>{HomePage.nav_5[lang]}</div>
                        </div>
                        <div className={`contactForm mt-5 mx-1 `}>
                            <div className='row justify-content-between handleRow mb-5'>
                                <div className='col-lg-5 col-md-12 col-sm-12 col-12 rightSide mx-4' style={{ textAlign: dir === "ltr" ? 'left' : 'right' }}>
                                    <h4 className={`w-100 ${theme === 'light' ? 'infofordetails' : ""} `} style={{ textAlign: dir === "ltr" ? 'left' : 'right' }}>
                                        {HomePage.form_contact[lang]}
                                    </h4>
                                    <p className={`w-100 mt-4 ${theme === 'light' ? 'infofordetails' : ""}`} style={{ textAlign: dir === "ltr" ? 'left' : 'right' }}>
                                        {HomePage.form_contact_info[lang]}
                                    </p>
                                    <div className={`w-100 mt-5 ${theme === 'light' ? 'infofordetails' : ""}`}>
                                        <ul className={`${theme === 'light' ? 'infofordetails' : ""}`} style={dir === 'rtl' ? { marginRight: "-37px" } : { marginLeft: "-37px" }}>
                                            <li className={`${theme === 'light' ? 'infofordetails' : "text-light"} fs-6 gen`}>
                                                <span><TbWorld className='fs-4' /></span> remotely service
                                            </li>
                                            <li className={`${theme === 'light' ? 'infofordetails' : "text-light"} fs-6 gen`}>
                                                <span><MdLocalPhone className='fs-4' /></span>
                                                <a href="tel:+201007476077" className={`${theme === 'light' ? 'infofordetails' : "text-light"}`} 
                                                
                                                >
                                                    {dir === "ltr" ? "+20 100 747 6077":" 6077 747 100 20+"}
                                                </a>
                                            </li>
                                            <li className={`${theme === 'light' ? 'infofordetails' : "text-light"} fs-6 gen`}>
                                                <span><MdEmail className='fs-4' /></span>
                                                <a href='mailto:info@seshat.world' className={`${theme === 'light' ? 'infofordetails' : "text-light"}`}>info@seshat.world</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <p className={` w-100 mt-5 ${theme === 'light' ? 'infofordetails' : ""}`}>

                                        {HomePage.followUs[lang]}

                                    </p>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12 col-12 text-right'>
                                    <div className='m-auto  formContactData shadow' >
                                        <form>
                                            <div className='row justify-content-between align-items-center'>
                                                <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                    <input
                                                        type="text"
                                                        onChange={(e) => setName(e.target.value)}
                                                        value={name}
                                                        placeholder={HomePage?.firstName[lang]}
                                                        className='w-100 form-control py-3'
                                                        style={{ textAlign: dir === "ltr" ? "left" : "right" }}
                                                    />
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                    <input
                                                        type="text"
                                                        onChange={(e) => setName(e.target.value)}
                                                        value={name}
                                                        placeholder={HomePage?.firstName[lang]}
                                                        className='w-100 form-control py-3'
                                                        style={{ textAlign: dir === "ltr" ? "left" : "right" }}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <input
                                                    type="email"
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    value={email}
                                                    placeholder={loginTrans.email[lang]}
                                                    className='w-100 form-control py-3'
                                                    style={{ textAlign: dir === "ltr" ? "left" : "right" }}
                                                />
                                            </div>
                                            <div>
                                                <input
                                                    type="text"
                                                    onChange={(e) => setPhone(e.target.value)}
                                                    value={phone}
                                                    placeholder={loginTrans.phone[lang]}
                                                    className='w-100 form-control py-3'
                                                    style={{ textAlign: dir === "ltr" ? "left" : "right" }}
                                                />
                                            </div>
                                            <div>
                                                <textarea
                                                    onChange={(e) => setComment(e.target.value)}
                                                    value={comment}
                                                    style={{ width: "100%", resize: "none", textAlign: dir === "ltr" ? "left" : "right" }}
                                                    rows={"5"}
                                                    className='form-control textarea'
                                                    placeholder={HomePage.message[lang]}
                                                >
                                                </textarea>
                                            </div>

                                            <div className='divBtn'>
                                                <button
                                                    className='btn btnGroub mt-3 w-100'
                                                    onClick={(e) => { handelSubmit(e) }}
                                                >
                                                    {HomePage.send_message_now[lang]}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <SharedStatic hint="contact" />
                </main>
            </PageContainer>
        </div>
    );
};

export default ContactPage;
