// import React, { useEffect, useState } from 'react';
// import MaterialModal from '../Material/modules/MaterialModal';
// import { getRequestPerUser } from '../../../utils/packagesRequests';
// import { useSelector } from 'react-redux';
// import Level from './Level';
// import EmptyComponent from '../Material/EmptyComponent';

// const DetailsSubscription = () => {
//     const loggedUser = useSelector((state) => state.loggedUser);
//     const [subscriptions, setSubscriptions] = useState([]);

//     const fetch_subscriptions = async () => {
//         if(loggedUser?.user?.role === "student"){
//             const res = await getRequestPerUser({
//                 userID: loggedUser?.user?._id,
//             });

//             if (res?.data?.case) {
//                 setSubscriptions(res.data.requests);
//             }
//         }else if(loggedUser?.user?.role === "parent"){
//             const res = await getRequestPerUser({
//                 userID: loggedUser?.user?.childId,
//             });

//             if (res?.data?.case) {
//                 setSubscriptions(res.data.requests);
//             }
//         }
//     };

//     useEffect(() => {
//         fetch_subscriptions();
//     }, []);


//     return (
//         <div>
//             <div className='sharedBackground'>
//                 <p>طلبات الاشتراك</p>
//             </div>
//             <div className='row' dir='rtl'>
//                 {subscriptions && subscriptions?.length > 0 ? (
//                     subscriptions.map((sub) => (
//                         <div className='col-lg-3 col-md-6 col-sm-6 col-6 mt-5' key={sub._id}>
//                             <div className='m-auto text-center'>
//                                 <p className='mt-3'>برنامج <Level levelId={sub?.packData?.levelId} /></p>
//                                 <p className='mt-3'>{sub?.packData?.name}</p>
//                                 <MaterialModal dynamic={"request"} title={"التفاصيل"} subject={sub} />
//                             </div>
//                         </div>
//                     ))
//                 ) :
//                     <div className='emptyComponent'>
//                         {
//                             loggedUser?.user?.role === "student" ? (
//                                 <EmptyComponent showFooter={true} title={"ليس لديك اي طلبات اشتراك"} />
//                             ):(<EmptyComponent showFooter={true} title={"ليس لدي الطالب المنتسب اليكم اشتراك"} />)
//                         }
//                     </div>
//                 }
//             </div>
//         </div>
//     );
// };

// export default DetailsSubscription;


import React, { useEffect, useState } from 'react';
import MaterialModal from '../Material/modules/MaterialModal';
import { getRequestPerUser } from '../../../utils/packagesRequests';
import { useSelector } from 'react-redux';
import Level from './Level';
import EmptyComponent from '../Material/EmptyComponent';
import { HomePage } from '../../../utils/Translation/HomePage';
import GetUser from '../../shared/GetUser';

const DetailsSubscription = () => {
    const loggedUser = useSelector((state) => state.loggedUser);
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    const [child, setChild] = useState(null);

    console.log(loggedUser?.user?.childId)

    const fetch_subscriptions = async () => {
        setLoading(true); // Start loading
        if (loggedUser?.user?.role === "student") {
            const res = await getRequestPerUser({
                userID: child,
            });

            if (res?.data?.case) {
                setSubscriptions(res.data.requests);
            }
        }
        setLoading(false); // End loading
    };

    const fetch_subscriptions_Parent = async()=>{
        try{
            if(loggedUser?.user?.role === "parent") {
                if (child !== null) {
                    const res = await getRequestPerUser({
                        userID: loggedUser?.user?.childId,
                    });
    
                    if (res?.data?.case) {
                        setSubscriptions(res.data.requests);
                    }
                }
            }
        }catch(e){
            console.log(e)
        }
    }


    useEffect(()=>{
        fetch_subscriptions_Parent();
    },[child])

    useEffect(() => {
        fetch_subscriptions();
    }, []);

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <div>
            <div className='sharedBackground' style={{ width: "85%" }}>
                <p>{HomePage.dashboard_subscription_req[lang]}</p>
            </div>
            <div>

                {
                    loggedUser?.user?.role === "parent" ? (
                        <select className='form-control text-center m-auto w-25' value={child} onChange={(e) => setChild(e.target.value)}>
                            <option hidden>قم باختيار طالب منتسب اليك</option>
                            {
                                loggedUser?.user?.childId?.map((child) => {
                                    return (
                                        <option key={child} value={child}>
                                            <GetUser studentId={child}/>
                                        </option>
                                    )
                                })
                            }

                        </select>
                    ) : (null)
                }
            </div>
            <div className='container'>
                <div className='' dir='rtl'>
                    {loading ? ( // Show loading indicator if loading is true
                        <div className="spinner-container d-flex justify-content-center align-items-center">
                            <span className='loader'></span>
                        </div>
                    ) : (
                        subscriptions && subscriptions.length > 0 ? (
                            subscriptions.map((sub) => (
                                <div className='subsData m-auto' key={sub._id} >
                                    <div className='m-auto mt-3 text-center  p-3' >
                                        <p className='mt-3'>برنامج <Level levelId={sub?.packData?.levelId} /></p>
                                        <p className='mt-3'>{sub?.packData?.name}</p>
                                        <MaterialModal dynamic={"request"} title={`${HomePage.details[lang]}`} subject={sub} />
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className='emptyComponent mt-4'>
                                {loggedUser?.user?.role === "student" ? (
                                    <EmptyComponent showFooter={true} title={"ليس لديك اي طلبات اشتراك"} />
                                ) : (
                                    <EmptyComponent showFooter={true} title={"ليس لدي الطالب المنتسب اليكم اشتراك"} />
                                )}
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default DetailsSubscription;
