import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

function Menu({ setStatus, status }) {
  return (
    <ButtonGroup style={{ width: '100%', height: '50px' }}>
      <button
        className={status ? 'btn special color' : 'btn btnGroub'}
        onClick={() => setStatus('products')}
        style={{ width: '40%', borderRadius: '15px' }}
      >
        <span className='font'>المنتجات</span>
      </button>
      <Button
        className={!status ? 'btn special color' : 'btn btnGroub'}
        onClick={() => setStatus('orders')}
        style={{ width: '40%', borderRadius: '15px' }}
      >
        <span className='font'>الطلبات</span>
      </Button>

    </ButtonGroup>
  );
}

export default Menu;
