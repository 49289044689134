
export const logo = "./assets/logo.png";
export const OLP_White_Logo = './assets/logo.png';
export const cities = [
    {name:'	الإسكندرية',code:2},
    {name:"أسوان",code:27},
    {name:'	أسيوط',code:22},
    {name:'البحيرة',code:3},
    {name:"	بني سويف",code:19},
    {name:"	القاهرة",code:16},
    {name:'الدقهلية',code:5},
    {name:'	دمياط',code:6},
    {name:'	الفيوم',code:15},
    {name:'	الغربية',code:9},
    {name:'الجيزة',code:14},
    {name:'	الإسماعيلية',code:13},
    {name:'	كفر الشيخ',code:4},
    {name:'	الأقصر',code:26},
    {name:"	مطروح",code:1},
    {name:'	المنيا',code:20},
    {name:"	المنوفية",code:10},
    {name:'الوادي الجديد',code:21},
    {name:'	شمال سيناء',code:8},
    {name:'بور سعيد',code:7},
    {name:"	القليوبية",code:11},
    {name:'قنا',code:'25'},
    {name:'البحر الاحمر',code:23},
    {name:'الشرقية',code:12},
    {name:'سوهاج',code:24},
    {name:'جنوب سيناء',code:18},
    {name:'السويس',code:17}

]

export const meeting_btn={color: "rgba(92, 17, 11, 1)"};
export const add_class_btn={color:'white !important',background : "rgba(92, 17, 11, 1) !important"};
export const loginout_btn={
    color:'rgba(223, 167, 58, 1) !important',
    "background-color" :"white !important",
}

export const whatsapp = "+201007476077"