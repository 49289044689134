import React, { useContext } from 'react'
// import homeImage from "../../assets/home_image.png"
import homeImage from "../../assets/images/loginArabic.png"
import englishDir from "../../assets/images/englishLogo.png"
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { HomePage } from '../../utils/Translation/HomePage';
import { ThemeContext } from '../../ThemeProvider';
const HomeHeader = () => {

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const { theme } = useContext(ThemeContext);


    return (
        <div className='homeHeader' style={{ backgroundColor: theme === 'light' ? '#4169E2' : '#001C45'}}>
            <div className='container' dir={`${dir}`}>
                <div className='row justify-content-between align-items-center homeHeaderContent py-5'>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-12 p-4 textHeader'>
                        <div className='textHeader' style={{ textAlign: `${dir === "rtl" ? 'right' : 'left'}` }}>
                            <h2 className={`color mb-5 ${theme}`}>{HomePage.info_header[lang]}</h2>
                            <p style={{color:"white"}}>{HomePage.info_header2[lang]}</p>
                        </div>
                        <div className='mt-5 textHeader ' style={{ textAlign: `${dir === "rtl" ? 'right' : 'left'}` }}>
                            <Link to="/login" className='btn mx-2 me-1 specialMoreAnather '>{HomePage.explore[lang]}</Link>
                            <Link to="/seshat-video" className='btn mx-2 me-1 specialMore '>{HomePage.watch[lang]}</Link>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-12  handleImage' >
                        {
                            dir === "rtl" ?(<img src={homeImage} alt="HomeImage" style={{ width: "80%"}} />):(<img src={englishDir} alt="HomeImage" style={{ width: "80%"}} />)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeHeader
