import React from 'react'
import PageContainer from '../layout/PagecContainer'
import ReactPlayer from "react-player"
// import video from "../../assets/seshatvideo.mp4"
import { HomePage } from '../../utils/Translation/HomePage'
import { useSelector } from 'react-redux'
const Video = () => {
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });
    const videoUrl = lang === 'AR'
        ? "https://seshat.world/seshat-intro-ar.mp4"
        : "https://seshat.world/seshat-intro-en.mp4";
    return (
        <div>
            <PageContainer>
                <div className='sharedBackground  font' style={{ marginTop: "120px" }}>
                    {HomePage.knowledgeVideo[lang]}
                </div>
                <div className="w-75 m-auto">
                    <ReactPlayer
                        url={videoUrl}
                        controls={true}
                        width="100%"
                        height="500px"
                        config={{
                            youtube: {
                                playerVars: {
                                    modestbranding: 1,
                                    rel: 0,
                                    showinfo: 0,
                                    iv_load_policy: 3,
                                }
                            },
                            dailymotion: {
                                params: {
                                    controls: true,
                                    autoplay: false,
                                    mute: false,
                                    'queue-enable': false,
                                    'sharing-enable': false,
                                    'ui-start-screen-info': false, // Hide video title and uploader info
                                    'ui-logo': false, // Hide Dailymotion logo
                                }
                            }
                        }}
                    />
                </div>
                <div className='mt-5'></div>
            </PageContainer>
        </div>
    )
}

export default Video
